import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStore from "../../store/sponsor_data";
import {
  addJob,
  getJobByID,
  updateJob,
} from "../../api/job_advertisement_related_service";
import userSess from "../../store/user_session_control";
import { duration } from "moment";
import { fetchSkills } from "../../api/skill_catalog";
import useProfile from "../../store/profile_data";
import { useNavigate, useParams } from "react-router";
import Confirmation from "../Common_Components/Confirmation";
import Cookies from 'js-cookie';

const Jobinfo = (props) => {
  const { advertisementId } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState({});
  // const { user_token, updateSess } = userSess()
  const user_token = Cookies.get("connectedMe")
  const [isEdit, setIsEdit] = React.useState(false);

  const [alert, setAlert] = React.useState(false);

  const handleAlert = () => {
    setAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const [confirm, setConfirm] = React.useState(false);

  const handleConfirm = () => {
    setConfirm(true);
  };

  const handleConfirmClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setConfirm(false);
    // props.open
  };

  React.useEffect(() => {
    const handleJob = async () => {
      const obj = {
        advertisement_id: advertisementId,
      };
      // console.log(obj);
      const reponse = await getJobByID({ ...obj, token: user_token });
      setInfo(reponse[0]);
    };
    handleJob();
  }, []);

  const handleSubmit = async () => {
    console.log("info", info);
    const reponse = await updateJob({ ...info, token: user_token });
    setConfirm(false);
    handleAlert();
  };

  // };

  return (
    <>
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Details Update successfully!!
        </Alert>
      </Snackbar>
      <Stack maxWidth="md" sx={{ height: "100vh" }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "medium",
            paddingLeft: "15px",
            marginTop: "15px",
            color: "#2f3348",
          }}
        >
          Job Id -{advertisementId}
        </Typography>
        <Divider />
        <Stack sx={{ width: "800px" }} marginTop={4}>
          <Container>
            <Stack>
              <Stack>
                <Typography
                  variant="body1"
                  sx={{ margin: "0 0 10px 0", fontWeight: "bold" }}
                >
                  Date:
                </Typography>
                <Stack direction={"row"} gap={10}>
                  <TextField
                    type="date"
                    variant="standard"
                    size={"small"}
                    label="StartDate"
                    name="startDate"
                    value={info.advertisement_date}
                    disabled={!isEdit}
                    // //   onChange={}
                    // // value={Jobevents.advertisement_date}
                    onChange={(e) => {
                      const updatedInfo = { ...info };
                      updatedInfo.advertisement_date = e.target.value;
                      setInfo(updatedInfo);
                    }}
                    sx={{ marginBottom: "10px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      placeholder: "Start Date",
                    }}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ margin: "20px 0 0 0" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Role:
                </Typography>
                <Stack direction={"row"}>
                  <TextField
                    variant="standard"
                    disabled={!isEdit}
                    size={"small"}
                    name="role"
                    value={info.advertisement_role}
                    onChange={(e) => {
                      const updatedInfo = { ...info };
                      updatedInfo.advertisement_role = e.target.value;
                      setInfo(updatedInfo);
                    }}
                    // onChange={handleChange}
                    //   value={}
                    //   onChange={}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ margin: "20px 0 0 0" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Mode:
                </Typography>
                <Stack direction={"row"}>
                  <TextField
                    variant="standard"
                    size={"small"}
                    disabled={!isEdit}
                    name="mode"
                    value={info.advertisement_mode}
                    onChange={(e) => {
                      const updatedInfo = { ...info };
                      updatedInfo.advertisement_mode = e.target.value;
                      setInfo(updatedInfo);
                    }}
                    //   value={}
                    //   onChange={}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ margin: "20px 0 0 0" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Description:
                </Typography>
                <Box
                  direction={"row"}
                  sx={{ backgroundColor: "white", borderRadius: 4 }}
                >
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    disabled={!isEdit}
                    variant="standard"
                    name="description"
                    value={info.advertisement_description}
                    onChange={(e) => {
                      const updatedInfo = { ...info };
                      updatedInfo.advertisement_description = e.target.value;
                      setInfo(updatedInfo);
                    }}
                    //   value={}
                    //   onChange={}
                  />
                </Box>
              </Stack>
              <Stack sx={{ margin: "20px 0 0 0" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Skill Match:
                </Typography>
                <Stack direction={"row"} sx={{ width: "42%" }}>
                  <TextField
                    variant="standard"
                    name="skillMatch"
                    disabled={!isEdit}
                    value={info.advertisement_skill_match}
                    onChange={(e) => {
                      const updatedInfo = { ...info };
                      updatedInfo.advertisement_skill_match = e.target.value;
                      setInfo(updatedInfo);
                    }}
                    size={"small"}
                    //   value={}
                    //   onChange={}
                  />
                </Stack>

                <Stack>
                  {!isEdit ? (
                    <Stack
                      sx={{
                        margin: "20px 0 10px 0",
                        padding: "10px 20px 10px 20px",
                      }}
                      direction={"row"}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        // onClick={handleCancelClick}
                        sx={{
                          padding: " 10px 35px",
                        }}
                        onClick={() => setIsEdit(true)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        // onClick={handleCancelClick}
                        sx={{
                          padding: " 10px 35px",
                        }}
                        // onClick={props.onclose}
                        onClick={() => navigate(-1)}
                      >
                        Close
                      </Button>
                    </Stack>
                  ) : (
                    <Stack
                      sx={{
                        margin: "20px 0 10px 0",
                        padding: "10px 20px 10px 20px",
                      }}
                      direction={"row"}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        // onClick={handleCancelClick}
                        sx={{
                          padding: " 10px 35px",
                        }}
                        onClick={handleConfirm}
                      >
                        Update
                      </Button>

                      <Button
                        variant="contained"
                        color="secondary"
                        // onClick={handleCancelClick}
                        sx={{
                          padding: " 10px 35px",
                        }}
                        // onClick={props.onclose}
                        onClick={() =>
                          // navigate(-1)
                          setIsEdit(false)
                        }
                      >
                        Cancel
                      </Button>
                    </Stack>
                  )}

                  {confirm ? (
                    <Confirmation
                      open={confirm}
                      onClose={handleConfirmClose}
                      submit={handleSubmit}
                    />
                  ) : (
                    <></>
                  )}
                </Stack>
              </Stack>
            </Stack>
            {/* </Stack> */}
          </Container>
        </Stack>
      </Stack>
    </>
  );
};

export default Jobinfo;
