import axios from "axios";
import network_conn from "../utils/network_config";

export const fetchSponsor = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}sponsor_service/fetch_sponsor`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMentee = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/fetch_mentee`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const insertSponsor = async (profile) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/insert_sponsor`,
      profile,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const insertMenteeMatches = async (profile) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/insert_mentee_matches`,
      profile,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMenteeMacthes = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/fetch_mentee_matches`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeMenteeMacthes = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/remove_mentee_matches`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addMentee = async (Mentee) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/insert_job_application`,
      Mentee,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
