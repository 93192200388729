import axios from "axios";
import network_conn from "../utils/network_config";

export const addJob = async (job) => {
  console.log(job);
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/post_job`,
      job,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Data sent successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error.message);
    throw error;
  }
};

export const addMentee = async (Mentee) => {
  console.log(Mentee);
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/insert_job_application`,
      Mentee,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Mentee sent successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding Mentee:", error.message);
    throw error;
  }
};

export const getJob = async (user_token) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}sponsor_service/fetch_sponsor`,
      { user_token },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Data fetched successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};

export const update_status = async (status) => {
  console.log(status);
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/update_status`,
      status,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Status updated successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating status:", error.message);
    throw error;
  }
};

export const getMenteDetails = async (user_token) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/fetch_job_application`,
      { user_token },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Mentee fetched successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching Mentee data:", error.message);
    throw error;
  }
};

export const getSponsor = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/fetch_sponsor_data`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Sponsor fetched successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching sponsor:", error.message);
    throw error;
  }
};

export const updateJob = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/update_jobdetails`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Job updated successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating Job details:", error.message);
    throw error;
  }
};

export const getJobByID = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}job_advertisement/fetch_job_byid`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Job fetched successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching Job:", error.message);
    throw error;
  }
};
