import { create } from 'zustand';

const useEventDataStore = create((set) => ({
  appointments: [],
  setAppointments: (newAppointments) =>
    set({ appointments: Array.isArray(newAppointments) ? newAppointments : [] }),

  mentorSessions: [],
  setMentorSessions: (newMentorSessions) =>
    set({ mentorSessions: Array.isArray(newMentorSessions) ? newMentorSessions : [] }),

  bookedMentorSes:[],
  setBookedMentorSes: (newBookedMentorSes) =>
    set({bookedMentorSes:Array.isArray(newBookedMentorSes) ? newBookedMentorSes: []}),
}));


export default useEventDataStore;