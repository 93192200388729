import { React, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  IconButton,
  Button,
  Box,
  TextField,
  Autocomplete,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import CommentIcon from "@mui/icons-material/Comment";
import {
  fetch_manager,
  request_level_upgrade,
} from "../../api/progress_tracker";
import { fetchProfile } from "../../api/profile_related_service";
import SkillTracker from "./skill_progress";
import AlertPopup from "../Common_Components/AlertPopup";
import Cookies from 'js-cookie';

// MenteePost component for displaying each mentee's details
function MenteePosts() {
  const [managerData, setManagerData] = useState([]);
  const [desired_skill, setDesired_skill] = useState([]);
  const [current_skill, setCurrent_skill] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const user_token = Cookies.get("connectedMe")

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch_manager({ user_token });
        setManagerData(response);

        const profile = await fetchProfile({ user_token });
        setDesired_skill(profile.professional_ambitions || []);
        setCurrent_skill(profile.skills || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user_token]);

  // Merge desired and current skills into a single array for SkillTracker
  const mergedSkills = desired_skill.map((desired) => {
    const current = current_skill.find(
      (skill) => skill.skill_name === desired.skill_name
    );
    return {
      name: desired.skill_name,
      currentLevel: current ? current.skill_level : 0,
      desiredLevel: desired.skill_level,
    };
  });

  const handleSkillChange = (selected) => {
    if (selected) {
      setSelectedSkill(selected);
    } else {
      setSelectedSkill(null);
    }
  };

  const handleRequest = async () => {
    try {
      if (selectedSkill) {
        await request_level_upgrade({
          user_token: user_token,
          desired_skill: selectedSkill.skill_name,
          desired_level: selectedSkill.skill_level,
        });
        setSnackbarOpen(true); // Show success message
      }
    } catch (error) {
      console.error("Error requesting skill upgrade:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        height: isSmallScreen ? "auto" : "100vh",
      }}
    >
      <Box
        sx={{
          flex: 1,
          borderLeft: isSmallScreen ? "none" : "1px solid #ccc",
          order: isSmallScreen ? 0 : 1,
        }}
      >
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <SkillTracker skills={mergedSkills} />
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          p: 2,
          overflowY: isSmallScreen ? "visible" : "auto",
          order: isSmallScreen ? 1 : 2,
        }}
      >
        <Card
          sx={{
            maxWidth: 500,
            mx: "auto",
            p: 2,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <CardHeader
            avatar={
              <Avatar alt={managerData?.first_name} src={managerData?.image} />
            }
            action={
              <IconButton aria-label="settings">
                <MoreHorizIcon />
              </IconButton>
            }
            title={`${managerData?.first_name || "N/A"} ${
              managerData?.last_name || ""
            }`}
            subheader={managerData?.professional_info || "No Info Available"}
          />
          <CardContent>
            <Typography variant="subtitle1" gutterBottom>
              Select a Skill to upgrade the level:
            </Typography>
            <Autocomplete
              options={desired_skill}
              getOptionLabel={(option) => option.skill_name || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Desirable skill"
                  variant="standard"
                  size="small"
                />
              )}
              value={selectedSkill}
              onChange={(event, newValue) => handleSkillChange(newValue)}
              isOptionEqualToValue={(option, value) =>
                option.skill_name === value?.skill_name
              }
            />
            {selectedSkill && (
              <Typography sx={{ mt: 2 }}>
                Desired Level: <strong>{selectedSkill.skill_level}</strong>
              </Typography>
            )}
          </CardContent>

          <Box sx={{ display: "flex", alignItems: "center", px: 2, py: 1 }}>
            <CommentIcon
              fontSize="small"
              sx={{ color: "text.secondary", mr: 1 }}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Add a comment…"
              size="small"
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: 2 } }}
            />
          </Box>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<UpgradeIcon />}
              onClick={handleRequest}
            >
              Request Upgrade
            </Button>
          </CardActions>
        </Card>
      </Box>
      <AlertPopup
        timer={3000}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        type="success"
        message={"Request for level upgrade successfully submitted!!"}
      />
    </Box>
  );
}

export default MenteePosts;
