/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  useTheme,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  Tooltip,
  Menu,
  MenuItem,
  Badge,
  CircularProgress,
  Avatar,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
//Search
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import "../Menu/User_left_panel_drawer.css";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import MergeIcon from "@mui/icons-material/Merge";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import WorkIcon from "@mui/icons-material/Work";
import { useNavigate } from "react-router";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { AccountCircle, AdminPanelSettings } from "@mui/icons-material";
import userSess from "../../store/user_session_control";
import { userLogout } from "../../api/user_related_service";
import { fetchAppointments } from "../../api/scheduler_related_services";
import useEventDataStore from "../../store/SchedulerData";
import { fetchDashSession } from "../../api/session_service";
import useUserPrefSess from "../../store/dash_session";
import { fetchUserMentSession } from "../../api/session_service";
import useUserMentorSess from "../../store/user_mentor_session";
import { fetch_plan_path } from "../../api/planPath_related_services";
import usePlanpath from "../../store/PlanPath_data";
import { fetchSponsor } from "../../api/sponsor_related_service";
import useStore from "../../store/sponsor_data";
import useAdminStore from "../../store/Admin_session";
import { fetchProfile } from "../../api/profile_related_service";
import useProfile from "../../store/profile_data";
import Notification from "../../components/Notification/notification";
import {
  fetchNotification,
  fetchAchievementNotification,
} from "../../api/notification_related_service";
import DeleteAccount from "../delete_account/delete";
import { delete_account } from "../../api/delete_account";
import FeedbackIcon from "@mui/icons-material/Feedback";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { fetchSessionNotification } from "../../api/notification_related_service";
import Dialogue from "../Notification/Dialogue";
import AlertPopup from "../Common_Components/AlertPopup";
import { fetchProfileImage } from "../../api/profile_related_service";
import Cookies from 'js-cookie';

const drawerWidth = 240;

function GlobalNav({ render_user_ana, render_user_cal }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "80vh",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(3),
      width: "20vh",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = (toggle) => () => {
    setOpen(toggle);
  };

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));
  const settings = ["Profile", "Account", "Dashboard"];
  const navigate = useNavigate();
  const handleClick_plan = async () => {
    // const plan_path = await fetch_plan_path({ token: user_token });
    // setUser(plan_path);
    // plan_path.forEach((path) => {
    //   const { details, course, date } = path;
    //   usePlanpath.getState().add_row(details, course, date);
    // });

    navigate("course");
  };
  // const { user_token, updateSess } = userSess()
  const { setAppointments } = useEventDataStore();
  const { setuseUserPrefSess } = useUserPrefSess();
  const { setuseUserMentorSess } = useUserMentorSess();
  // if (user_token == ''){
  //   const user_token = localStorage.getItem("user_tk")
  //   updateSess(user_token)
  // }
  const user_token = Cookies.get("connectedMe")
  const [profilePicture, setProfilePicture] = useState(null);
  const handleClick_cal = async () => {
    const app_events = await fetchAppointments({ token: user_token });
    setAppointments(app_events);
    navigate("calendar");
  };
  const handleClick_ana = async () => {
    // const app_events = await fetchDashSession({ token: user_token });
    // setuseUserPrefSess(app_events);
    navigate("dashboard");
  };

  const handleClick_ses = async () => {
    // const app_events = await fetchAppointments({ token: user_token });
    // setAppointments(app_events);
    //User booked sessions
    // const ment_events = await fetchUserMentSession({ "token": user_token })
    // setuseUserMentorSess(ment_events)
    navigate("UserSess");
  };

  const handleClick_c = () => {
    navigate("UserCourse");
  };

  const handleClick_recc = () => {
    navigate("UserRecom");
  };

  const { setUser } = useProfile();
  const handleClick_m = async () => {
    navigate("MyProfile");
    handleMenuClose();
  };

  const { setSponsorData } = useStore();
  const handleClick_s = async () => {
    const jobData = await fetchSponsor({ user_token: user_token });
    setSponsorData(jobData);
    navigate("Sponsor");
  };

  const handleClick_KPI = () => {
    navigate("KPI");
  };
  const handleClick_trc = () => {
    navigate("TRC");
  };

  const handleClick_j = () => {
    navigate("Job");
  };

  const handleClick_ac = () => {
    navigate("Admincourse");
  };

  const handleClick_feedback = () => {
    navigate("create-feedback-form");
  };
  const handleClick_achievement = () => {
    navigate("achievement");
  };
  const { isAdmin, setIsAdmin } = useAdminStore();
  console.log(isAdmin, "isAdmin");
  const handleClick_A = () => {
    navigate("Admin");
  };
  const handlelogout = () => {
    localStorage.removeItem("user_tk");
    localStorage.removeItem("isAdmin");
    userLogout();
    navigate("/");
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteloading, setDeleteLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedButton, setSelectedButton] = useState("one");

  const handleClick = (buttonKey, onClickHandler) => {
    setSelectedButton(buttonKey);
    onClickHandler();
  };

  const handleOpenDelete = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };
  const handleConfirmDelete = async () => {
    handleCloseDelete();
    setDeleteLoading(true);
    try {
      const fetchreq = { token: user_token };
      const response = await delete_account(fetchreq);

      setTimeout(() => {
        setDeleteLoading(false);
      }, 500);

      if (response.data && response.data.status === "success") {
        setSnackbarMessage("Account successfully deleted!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        setTimeout(() => {
          handlelogout();
        }, 2000);
      } else {
        setSnackbarMessage("Failed to delete the account. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setTimeout(() => {
        setDeleteLoading(false);
      }, 500);
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const [anchorE2, setAnchorE2] = useState(null);
  const handleOpenNotification = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseNotification = (event) => {
    setAnchorE2(null);
  };

  const [notifications, setNotifications] = useState([]); // Store notification data
  const [unreadCount, setUnreadCount] = useState(0); // State for unread notifications count
  const [loading, setLoading] = useState(true); // State for loading notifications
  const [childData, setChildData] = useState(false);
  const [meeting_id, setMeetingId] = useState();
  const handleChildData = (data, id) => {
    // console.log("llllllllllllllllllllll",id)
    {
      id && setChildData(data);
    }
    setMeetingId(id);
  };
  const handleClose = () => {
    setChildData(false);
  };
  let count = 0;
  // Fetch notifications when the application loads
  const sortNotifications = (notifications) => {
    return notifications.sort((a, b) => {
      if (a.read_status === b.read_status) {
        // If both have the same read_status, sort by created_on (most recent first)
        return new Date(b.created_on) - new Date(a.created_on);
      }
      // Unread (false) should come before read (true)
      return a.read_status - b.read_status;
    });
  };

  React.useEffect(() => {
    const fetchAllNotifications = async () => {
      try {
        const fetchReq = { token: user_token };

        // Fetch both types of notifications in parallel
        const [
          notificationResponse,
          achievementNotificationResponse,
          response2,
        ] = await Promise.all([
          fetchNotification(fetchReq),
          fetchAchievementNotification(fetchReq),
          fetchSessionNotification(fetchReq),
        ]);
        // const response2 = await fetchSessionNotification(fetchReq)

        const notificationData = notificationResponse.data || [];
        const achievementNotificationData =
          achievementNotificationResponse.data || [];
        const notificationData2 = Array.isArray(response2) ? response2 : [];
        // console.log("response2", typeof (notificationData2))
        // Filter out invalid notifications
        const validNotifications = [
          ...(Array.isArray(notificationData) ? notificationData : []),
          ...(Array.isArray(achievementNotificationData)
            ? achievementNotificationData
            : []),
        ].filter(
          (notification) =>
            notification.id &&
            notification.notification &&
            notification.created_on &&
            typeof notification.read_status === "boolean"
        );

        // Sort notifications
        const combinedNotifications = sortNotifications(validNotifications);

        // Update notifications and unread count
        // setNotifications(combinedNotifications);

        const unreadCount = combinedNotifications.filter(
          (notification) => !notification.read_status
        ).length;
        // const response = await fetchNotification(fetchReq);
        // const notificationData = response.data || [];

        setNotifications([
          ...(Array.isArray(combinedNotifications)
            ? combinedNotifications
            : []),
          ...(Array.isArray(notificationData2) ? notificationData2 : []),
        ]);
        // let unreadCount = 0;

        // Ensure notificationData is an array
        if (Array.isArray(notificationData)) {
          unreadCount += notificationData.filter(
            (notification) => !notification.read_status
          ).length;
          console.log("Unread notifications in notificationData:", unreadCount);
        }
        let count = Object.keys(notificationData2).length;
        setUnreadCount(unreadCount + count);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
        setLoading(false);
      }
    };

    fetchAllNotifications();

    // Optional: Set up polling to regularly update the notifications
    const intervalId = setInterval(fetchAllNotifications, 60000); // Update every 60 seconds
    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []);
  console.log("notificattionsss", unreadCount);
  const [drawView, setDrawView] = React.useState(true);
  const handleDrawer_view = (toggle) => {
    setDrawView(toggle);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const fetchImage = async () => {
    try {
      const imageUrl = await fetchProfileImage({ user_token });
      setProfilePicture(imageUrl);
    } catch (error) {
      console.error("Error fetching profile image:", error);
    }
  };
  React.useEffect(() => {
    fetchImage();
  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ marginTop: "3.125rem" }}
    >
      {settings.map((setting) => (
        <MenuItem
          key={setting}
          onClick={setting === "Profile" ? handleClick_m : null}
        >
          <Typography textAlign="center">{setting}</Typography>
        </MenuItem>
      ))}
      <MenuItem onClick={handlelogout}>Logout</MenuItem>
      {/* <MenuItem onClick={handleOpenDelete}>Delete Account </MenuItem> */}
      <DeleteAccount
        open={openDeleteDialog}
        onClose={handleCloseDelete}
        onConfirm={handleConfirmDelete}
      />
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right", // Align with the right edge
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right", // Ensure it opens from the right side
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={{
        "& .MuiPaper-root": {
          marginTop: "43px", // Adjust the top margin
          right: 0, // Align to the right edge of the screen
          left: "auto", // Prevent left alignment pushing it away
          position: "fixed", // Fix the position to the viewport
          width: "150px",
          marginRight: "0px",
        },
      }}
    >
      <MenuItem onClick={handleClick_m} sx={{ padding: "8px" }}>
        <IconButton
          size="medium"
          aria-label="account of current user"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          color="inherit"
          sx={{ padding: "4px" }}
        >
          <AccountCircleOutlinedIcon sx={{ p: 0, mr: 1, fontSize: "20px" }} />{" "}
          {/* Reduce icon size */}
        </IconButton>
        <Typography sx={{ fontSize: "14px" }}>Profile</Typography>{" "}
        {/* Reduce font size */}
      </MenuItem>

      <MenuItem sx={{ padding: "8px" }}>
        <IconButton
          onClick={handleOpenNotification}
          color="inherit"
          size="medium" // Reduce icon button size for mobile
          sx={{ padding: "4px" }} // Reduce padding around icon
        >
          <Badge color="error" badgeContent={unreadCount}>
            <NotificationsNoneIcon sx={{ mr: 2, fontSize: "20px" }} />{" "}
            {/* Reduce icon size */}
          </Badge>
        </IconButton>
        <Typography sx={{ fontSize: "14px" }}>Notifications</Typography>{" "}
        {/* Reduce font size */}
      </MenuItem>

      <MenuItem sx={{ padding: "8px" }}>
        <IconButton
          size="medium" // Reduce icon button size for mobile
          aria-label="show 4 new mails"
          color="inherit"
          sx={{ padding: "4px" }} // Reduce padding around icon
        >
          <Badge>
            <ForumOutlinedIcon sx={{ mr: 1, fontSize: "20px" }} />{" "}
            {/* Reduce icon size */}
          </Badge>
        </IconButton>
        <Typography sx={{ fontSize: "14px" }}>Messages</Typography>{" "}
        {/* Reduce font size */}
      </MenuItem>

      <MenuItem onClick={handlelogout} sx={{ padding: "8px" }}>
        <IconButton
          size="medium" // Reduce icon button size for mobile
          aria-label="logout"
          color="inherit"
          sx={{ padding: "4px" }} // Reduce padding around icon
        >
          <Badge>
            <LogoutIcon sx={{ mr: 1, fontSize: "20px" }} />{" "}
            {/* Reduce icon size */}
          </Badge>
        </IconButton>
        <Typography sx={{ fontSize: "14px" }}>Logout</Typography>{" "}
        {/* Reduce font size */}
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {isSmallScreen ? (
        <AppBar
          position="fixed"
          xs={12}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Stack direction={"row"}>
              <IconButton onClick={handleDrawerOpen(!open)}>
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search> */}
              <img
                src="/image/LOGO.png"
                alt="Advertisement"
                style={{
                  width: 162,
                  height: 40,
                  objectFit: "cover",
                  filter: "brightness(0) invert(1)",
                }}
              />
            </Stack>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreVertIcon />
              </IconButton>
              {renderMobileMenu}
              {renderMenu}
            </Box>
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar
          position="fixed"
          sx={{
            width: drawView
              ? `calc(100% - 49px)`
              : `calc(100% - ${drawerWidth}px)`,
            ml: drawView ? `49px` : `${drawerWidth}px`,
            backgroundColor: theme.palette.primary.main,
            transition: "all 0s ease-out",
          }}
        >
          <Toolbar>
            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search> */}
            {/* </Toolbar> */}
            {/* <Box sx={{ flexGrow: 1 }} /> */}
            <img
              src="/image/LOGO.png"
              alt="Advertisement"
              style={{
                width: 162,
                height: 40,
                objectFit: "cover",
                filter: "brightness(0) invert(1)",
              }}
            />

            <Box
              sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
            >
              <ForumOutlinedIcon sx={{ mr: 1 }} />
              {/* <InfoOutlinedIcon sx={{ mr: 1 }} /> */}
              <IconButton onClick={handleOpenNotification} color="inherit">
                <Badge color="error" badgeContent={unreadCount}>
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
              <Notification
                anchorEl={anchorE2}
                handleClose={handleCloseNotification}
                notifications={notifications}
                loading={loading}
                setNotifications={setNotifications}
                setUnreadCount={setUnreadCount}
                user_token={user_token}
                onClick={handleChildData}
              />
              <Tooltip title="Open settings" sx={{ mr: 1 }}>
                <Avatar
                  src={profilePicture}
                  onClick={handleProfileMenuOpen}
                  sx={{
                    width: 32,
                    height: 32,
                    ml: 2,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                // anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                // open={Boolean(anchorElUser)}
                // onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>

              {renderMenu}
            </Box>
          </Toolbar>
        </AppBar>
      )}
      {/* ---DRAWER--- */}
      {isSmallScreen ? (
        <Drawer
          className="drawerwrapper"
          variant="tempopary"
          anchor="left"
          open={open}
          // onClose={handleDrawerOpen(false)}
          onClose={handleDrawerOpen(false)}
          sx={{
            width: "190px",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "190px",
              boxSizing: "border-box",
              marginTop: "56px",
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <Stack direction={"column"} mt={1} onClick={handleDrawerOpen(false)}>
            <Button
              key="one"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor:
                  selectedButton === "one"
                    ? "rgba(128,128,128,0.3)"
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "one"
                      ? "rgba(128,128,128,0.3)"
                      : "transparent",
                },
              }}
              onClick={() => handleClick("one", handleClick_ana)}
              // onClick={handleClick_ana}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <GridViewOutlinedIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Dashboard
                </Typography>
              </Stack>
            </Button>
            <Button
              key="two"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor:
                  selectedButton === "two"
                    ? "rgba(128,128,128,0.3)"
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "two"
                      ? "rgba(128,128,128,0.3)"
                      : "transparent",
                },
              }}
              onClick={() => handleClick("two", handleClick_cal)}
              // onClick={handleClick_cal}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <CalendarMonthIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Calendar
                </Typography>
              </Stack>
            </Button>

            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor:
                  selectedButton === "three"
                    ? "rgba(128,128,128,0.3)"
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "three"
                      ? "rgba(128,128,128,0.3)"
                      : "transparent",
                },
              }}
              onClick={() => handleClick("three", handleClick_plan)}
              // onClick={handleClick_plan}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <MergeIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Career Plan
                </Typography>
              </Stack>
            </Button>
            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor:
                  selectedButton === "four"
                    ? "rgba(128,128,128,0.3)"
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "four"
                      ? "rgba(128,128,128,0.3)"
                      : "transparent",
                },
              }}
              onClick={() => handleClick("four", handleClick_ses)}
              // onClick={handleClick_ses}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <LaptopMacIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  My Bookings
                </Typography>
              </Stack>
            </Button>
            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor:
                  selectedButton === "five"
                    ? "rgba(128,128,128,0.3)"
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "five"
                      ? "rgba(128,128,128,0.3)"
                      : "transparent",
                },
              }}
              onClick={() => handleClick("five", handleClick_recc)}
              // onClick={handleClick_recc}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <StarBorderIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Recommendation
                </Typography>
              </Stack>
            </Button>
            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor:
                  selectedButton === "six"
                    ? "rgba(128,128,128,0.3)"
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "six"
                      ? "rgba(128,128,128,0.3)"
                      : "transparent",
                },
              }}
              onClick={() => handleClick("six", handleClick_c)}
              // onClick={handleClick_c}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <AutoStoriesIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Courses
                </Typography>
              </Stack>
            </Button>

            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor:
                  selectedButton === "seven"
                    ? "rgba(128,128,128,0.3)"
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "seven"
                      ? "rgba(128,128,128,0.3)"
                      : "transparent",
                },
              }}
              onClick={() => handleClick("seven", handleClick_s)}
              // onClick={handleClick_s}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <MonetizationOnIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Sponsor
                </Typography>
              </Stack>
            </Button>

            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor:
                  selectedButton === "eight"
                    ? "rgba(128,128,128,0.3)"
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "eight"
                      ? "rgba(128,128,128,0.3)"
                      : "transparent",
                },
              }}
              onClick={() => handleClick("eight", handleClick_KPI)}
              // onClick={handleClick_KPI}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <AutoGraphIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  KPI
                </Typography>
              </Stack>
            </Button>
            <Button
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor:
                  selectedButton === "nine"
                    ? "rgba(128,128,128,0.3)"
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "nine"
                      ? "rgba(128,128,128,0.3)"
                      : "transparent",
                },
              }}
              onClick={() => handleClick("nine", handleClick_trc)}
              // onClick={handleClick_trc}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <QueryStatsIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Progress Tracer
                </Typography>
              </Stack>
            </Button>
            <Button
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor:
                  selectedButton === "ten"
                    ? "rgba(128,128,128,0.3)"
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "ten"
                      ? "rgba(128,128,128,0.3)"
                      : "transparent",
                },
              }}
              onClick={() => handleClick("ten", handleClick_achievement)}
              // onClick={handleClick_trc}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <EmojiEventsIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Admiration
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Drawer>
      ) : (
        <Drawer
          className="drawerwrapper"
          sx={{
            width: drawView ? "65px" : drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawView ? "65px" : drawerWidth,
              boxSizing: "border-box",
              backgroundColor: theme.palette.primary.main,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {/* Drawer Header */}
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: drawView ? "10px" : "0px",
              paddingRight: 0,
            }}
          >
            <IconButton
              sx={{
                color: "white",
                transform: "translateX(-20px)",
                marginLeft: drawView ? "auto" : "0px",
              }}
              onClick={() => handleDrawer_view(!drawView)}
            >
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
          </Toolbar>
          {drawView ? (
            <Stack direction={"column"}>
              {isAdmin ? (
                <>
                  <Button
                    key="Seven"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "Seven"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "Seven"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("Seven", handleClick_A)}
                  >
                    <Tooltip title="Admin Dashboard" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <AdminPanelSettings
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Admin
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="Eight"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "Eight"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "Eight"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("Eight", handleClick_j)}
                  >
                    <Tooltip title="Jobs" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <WorkIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Jobs
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="Nine"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "Nine"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "Nine"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("Nine", handleClick_ac)}
                  >
                    <Tooltip title="Courses" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <AutoStoriesIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Courses
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="Ten"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "Ten"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "Ten"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("Ten", handleClick_feedback)}
                  >
                    <Tooltip title="Create Feedback form" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <FeedbackIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Feedback Form
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    key="one"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "one"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "one"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("one", handleClick_ana)}
                  >
                    <Tooltip title="Dashboard" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <GridViewOutlinedIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Dashboard
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>

                  <Button
                    key="two"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "two"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "two"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("two", handleClick_cal)}
                  >
                    <Tooltip title="Calendar" placement="right">
                      <Stack
                        direction="column"
                        alignItems="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <CalendarMonthIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption" // Use a smaller font size
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Calendar
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>

                  <Button
                    key="four"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "three"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "three"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("three", handleClick_plan)}
                  >
                    <Tooltip title="Career Plan" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <MergeIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption" // Use a smaller font size
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Career Plan
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "four"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "four"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("four", handleClick_ses)}
                    // onClick={handleClick_ses}
                  >
                    <Tooltip title="My Bookings" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <LaptopMacIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption" // Use a smaller font size
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          My Bookings
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "five"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "five"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("five", handleClick_recc)}
                    // onClick={handleClick_recc}
                  >
                    <Tooltip title="Recommendations" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <StarBorderIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption" // Use a smaller font size
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Recommend...
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "six"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "six"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("six", handleClick_c)}
                    // onClick={handleClick_c}
                  >
                    <Tooltip title="Courses" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <AutoStoriesIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption" // Use a smaller font size
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Courses
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "seven"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "seven"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("seven", handleClick_s)}
                    // onClick={handleClick_s}
                  >
                    <Tooltip title="Sponsor" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <MonetizationOnIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption" // Use a smaller font size
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Sponsor
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "eight"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "eight"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("eight", handleClick_KPI)}
                    // onClick={handleClick_KPI}
                  >
                    <Tooltip title="KPI" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <AutoGraphIcon
                          sx={{
                            fontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption" // Use a smaller font size
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          KPI
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "nine"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "nine"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("nine", handleClick_trc)}
                    // onClick={handleClick_trc}
                  >
                    <Tooltip title="Progress Tracer" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <QueryStatsIcon
                          sx={{
                            ontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption" // Use a smaller font size
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Progress
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "ten"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedButton === "ten"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("ten", handleClick_achievement)}
                    // onClick={handleClick_trc}
                  >
                    <Tooltip title="Achievement" placement="right">
                      <Stack
                        spacing={0.5}
                        direction="column"
                        alignItems="center"
                        sx={{
                          width: "100%",
                          color: "white",
                          transition: "transform 0.2s",
                        }}
                      >
                        <EmojiEventsIcon
                          sx={{
                            ontSize: "20px",
                            "&:hover": { transform: "scale(1.1)" },
                          }}
                        />
                        <Typography
                          variant="caption" // Use a smaller font size
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "0.5rem",
                          }}
                        >
                          Admiration
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                </>
              )}
            </Stack>
          ) : (
            <Stack direction={"column"}>
              {isAdmin ? (
                <>
                  <Button
                    key="Seven"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "Seven"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "Seven"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                      color: "white",
                    }}
                    onClick={() => handleClick("Seven", handleClick_A)}
                  // onClick={handleClick_trc}
                  >
                    <Tooltip title="Admin" placement="right">
                      <Stack
                        spacing={1}
                        direction={"row"}
                      >
                        <AdminPanelSettings
                        
                        />
                        <Typography
                          sx={{ textTransform: "capitalize" }}
                          
                        >
                          Admin
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="Eight"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "Eight"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "Eight"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                      color: "white",
                    }}
                    onClick={() => handleClick("Eight", handleClick_j)}
                  // onClick={handleClick_trc}
                  >
                    <Tooltip title="Jobs" placement="right">
                      <Stack
                       spacing={1}
                       direction={"row"}
                      >
                        <WorkIcon
                        
                        />
                        <Typography
                          sx={{ textTransform: "capitalize" }}
                        >
                          Jobs
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="Nine"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "Nine"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "Nine"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                      color: "white",
                    }}
                    onClick={() => handleClick("Nine", handleClick_ac)}
                  // onClick={handleClick_trc}
                  >
                    <Tooltip title="Courses" placement="right">
                      <Stack
                        spacing={1}
                        direction={"row"}
                      >
                        <AutoStoriesIcon/>
                        <Typography
                         sx={{ textTransform: "capitalize" }}
                        >
                          Courses
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                  <Button
                    key="Ten"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "Ten"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "Ten"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                      color: "white",
                    }}
                    onClick={() => handleClick("Ten", handleClick_feedback)}
                  // onClick={handleClick_trc}
                  >
                    <Tooltip title="Create Feedback Form" placement="right">
                      <Stack
                        spacing={1}
                        direction={"row"}
                      >
                        <FeedbackIcon/>
                        <Typography
                          sx={{ textTransform: "capitalize" }}
                        >
                        Create Feedback Form
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Button>
                </>
              ) : (
                <Stack onClick={handleDrawerOpen(!open)}>
                  <Button
                    key="one"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "one"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "one"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                      color: "white",
                    }}
                    onClick={() => handleClick("one", handleClick_ana)}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      // sx={{ "&:hover": { transform: "scale(1.1)" } }}
                    >
                      <GridViewOutlinedIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Dashboard
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="two"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "two"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "two"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                      color: "white",
                    }}
                    onClick={() => handleClick("two", handleClick_cal)}
                  >
                    {/* onClick={handleClick_cal}> */}
                    <Stack spacing={1} direction={"row"}>
                      <CalendarMonthIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Calendar
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "three"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "three"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                      color: "white",
                      // "&:hover": { transform: "scale(1.1)" },
                    }}
                    onClick={() => handleClick("three", handleClick_plan)}
                  >
                    {/* onClick={handleClick_plan}> */}
                    <Stack spacing={1} direction={"row"} sx={{}}>
                      <MergeIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Career Plan
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "four"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "four"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("four", handleClick_ses)}
                  >
                    {/* onClick={handleClick_ses}> */}
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{
                        color: "white",
                      }}
                    >
                      <LaptopMacIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        My Bookings
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "five"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "five"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("five", handleClick_recc)}
                  >
                    {/* onClick={handleClick_recc}> */}
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{
                        color: "white",
                      }}
                    >
                      <StarBorderIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Recommendation
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "six"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "six"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("six", handleClick_c)}
                  >
                    {/* onClick={handleClick_c}> */}
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{
                        color: "white",
                      }}
                    >
                      <AutoStoriesIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Courses
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "seven"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "seven"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("seven", handleClick_s)}
                  >
                    {/* onClick={handleClick_s}> */}
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{
                        color: "white",
                      }}
                    >
                      <MonetizationOnIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Sponsor
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "eight"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "eight"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("eight", handleClick_KPI)}
                  >
                    {/* onClick={handleClick_KPI}> */}
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{
                        color: "white",
                        "&:hover": { transform: "scale(1.1)" },
                      }}
                    >
                      <AutoGraphIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        KPI
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "nine"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "nine"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("nine", handleClick_trc)}
                  >
                    {/* onClick={handleClick_trc}> */}
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{
                        color: "white",
                      }}
                    >
                      <QueryStatsIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Progress Tracer
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor:
                        selectedButton === "ten"
                          ? "rgba(128,128,128,0.3)"
                          : "transparent",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor:
                          selectedButton === "ten"
                            ? "rgba(128,128,128,0.3)"
                            : "transparent",
                      },
                    }}
                    onClick={() => handleClick("ten", handleClick_achievement)}
                  >
                    {/* onClick={handleClick_trc}> */}
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{
                        color: "white",
                      }}
                    >
                      <EmojiEventsIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Admiration
                      </Typography>
                    </Stack>
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
        </Drawer>
      )}
      {deleteloading && (
        <Box
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <CircularProgress color="secondary" size={60} />
        </Box>
      )}
      {/* Snackbar for success message */}
      <AlertPopup
        timer={4000}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        type="success"
        message={"Account successfully Deleted!!"}
      />

      <Dialogue
        open={childData}
        onClose={handleClose}
        meeting_id={meeting_id}
        setUnreadCount={setUnreadCount}
        unreadCount={unreadCount}
      />
    </>
  );
}

export default GlobalNav;
