import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { fetchProfile } from "../../api/profile_related_service";
import {
  insertCareerPath,
  fetchCareerPath,
} from "../../api/planPath_related_services";
import Cookies from 'js-cookie';

const SkillTaskTable = () => {
  const [desirableSkills, setDesirableSkills] = useState({});
  const [tasks, setTasks] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [newTask, setNewTask] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const user_token = Cookies.get("connectedMe")

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const profile = await fetchProfile({ user_token: user_token });
        const careerPathResponse = await fetchCareerPath({ token: user_token });

        setDesirableSkills(profile);

        // Access tasks from the correct path in the Axios response
        if (
          careerPathResponse?.data?.status === 200 &&
          careerPathResponse?.data?.tasks
        ) {
          setTasks(careerPathResponse.data.tasks);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [user_token]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleTaskChange = (skill, month, value) => {
    setTasks((prev) => ({
      ...prev,
      [skill]: {
        ...prev[skill],
        [month]: value,
      },
    }));
  };

  const handleAddTask = () => {
    setIsEditing(true);
    setNewTask((prev) => {
      const updatedNewTask = {};
      desirableSkills.professional_ambitions?.forEach((skill) => {
        updatedNewTask[skill.skill_name] = {};
        months.forEach((month) => {
          updatedNewTask[skill.skill_name][month] =
            tasks[skill.skill_name]?.[month] || "";
        });
      });
      return updatedNewTask;
    });
  };

  const handleSaveTask = async () => {
    setIsEditing(false);
    const updatedTasks = {
      ...tasks,
      ...newTask,
    };
    setTasks(updatedTasks);

    try {
      const payload = {
        token: user_token,
        tasks: updatedTasks,
      };
      const response = await insertCareerPath(payload);
      if (response?.data?.status === 200) {
        console.log("Save successful:", response);
      }
    } catch (error) {
      console.error("Error saving tasks:", error.message);
    }
  };

  const handleCancelTask = () => {
    setIsEditing(false);
    setNewTask({});
  };

  const handleNewTaskChange = (skill, month, value) => {
    setNewTask((prev) => ({
      ...prev,
      [skill]: {
        ...prev[skill],
        [month]: value,
      },
    }));
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh" // Ensures it's centered on the entire viewport
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ margin: "8px" }}>
      {!isEditing && (
        <Box sx={{ margin: "16px" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddTask}
            style={{ marginBottom: "16px" }}
          >
            Add Task
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{ margin: "16px" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancelTask}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveTask}
          >
            Save
          </Button>
        </Box>
      )}

      <TableContainer
        style={{ overflowX: "auto", margin: "30px", borderRadius: "8px" }}
      >
        <Table
          sx={{
            borderCollapse: "separate",
            borderSpacing: 0,
            "& td, & th": {
              border: "1px solid rgba(224, 224, 224, 1)",
              padding: "8px",
            },
          }}
        >
          <TableHead >
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  backgroundColor: "white",
                  left: 0,
                  zIndex: 1,
                  width: 200,
                }}
              >
                Desirable Skill
              </TableCell>
              {months.map((month) => (
                <TableCell
                  key={month}
                  style={{
                    minWidth: 150,
                    maxWidth: 250,
                    fontWeight: "bold",
                  }}
                >
                  {month}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {desirableSkills.professional_ambitions?.map((skill) => (
              <TableRow key={skill.skill_name}>
                <TableCell
                  style={{
                    position: "sticky",
                    left: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  {skill.skill_name}
                </TableCell>
                {months.map((month) => (
                  <TableCell key={month}>
                    {isEditing ? (
                      <TextField
                        value={
                          (isEditing
                            ? newTask[skill.skill_name]?.[month]
                            : undefined) ||
                          tasks[skill.skill_name]?.[month] ||
                          ""
                        }
                        variant="standard"
                        onChange={(e) =>
                          handleNewTaskChange(
                            skill.skill_name,
                            month,
                            e.target.value
                          )
                        }
                        placeholder="Add Task"
                        size="small"
                      />
                    ) : (
                      <Typography>
                        {tasks[skill.skill_name]?.[month] || ""}
                      </Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SkillTaskTable;
