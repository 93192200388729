import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStore from "../../store/sponsor_data";
import { addJob } from "../../api/job_advertisement_related_service";
import userSess from "../../store/user_session_control";
import { duration } from "moment";
import { fetchSkills } from "../../api/skill_catalog";
import useProfile from "../../store/profile_data";
import Confirmation from "../Common_Components/Confirmation";
import Cookies from 'js-cookie';

const Job = (props) => {
  // const { user_token } = userSess();
  const user_token = Cookies.get("connectedMe")
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);

  const handleAlert = () => {
    setAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const [confirm, setConfirm] = React.useState(false);

  const handleConfirm = () => {
    setConfirm(true);
  };

  const handleConfirmClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setConfirm(false);
    // props.open
  };

  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const { handleJobSubmit } = useStore();
  const [formData, setFormData] = useState({
    startDate: "",
    startTime: "",
    role: "",
    mode: "",
    description: "",
    skillMatch: "",
  });

  const handleChange = (event) => {
    // console.log("updating")

    const { name, value } = event.target; // Destructure event object
    // console.log(name, value)
    setFormData((prevData) => {
      // console.log(prevData)

      return { ...prevData, [name]: value };
    }); // Update state with new value
  };

  const handleSubmit = async () => {
    // console.log("hit button")
    console.log(formData);
    // formData.status ='Open'
    // formData.body ="abc"

    handleJobSubmit(formData);

    try {
      // Send profile data to the backend
      await addJob({ ...formData, token: user_token });
      console.log("job data saved successfully");
      // Setsucess(true)
      // console.log(success)

      props.submit();
      setOpen(true);
      setConfirm(false);
      // handleClose();
      props.onClose();
    } catch (error) {
      console.error("Failed to save job data:", error);
    }

    setFormData({
      startDate: "",
      startTime: "",
      role: "",
      mode: "",
      description: "",
      skillMatch: "",
    });
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Job Publish Successfully!
        </Alert>
      </Snackbar>
      <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
        <DialogTitle>
          Add Jobs
          <Typography
            variant="subtitle2"
            sx={{ margin: "1px 20px 20px 15px", color: "grey" }}
          >
            find your jobs
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ width: "800px" }}>
          <Container>
            <Stack>
              {/* <Typography variant="h4" sx={{ margin: "20px 0 0 0px" }}>
                                Job
                            </Typography> */}
            </Stack>

            <Stack>
              <Stack>
                <Typography variant="body1" sx={{ margin: "0 0 10px 0" }}>
                  Date*:
                </Typography>
                <Stack direction={"row"} gap={10}>
                  <TextField
                    type="date"
                    variant="standard"
                    size={"small"}
                    label="Start Date"
                    name="startDate"
                    //   value={}
                    //   onChange={}
                    value={formData.startDate}
                    onChange={handleChange}
                    sx={{ marginBottom: "10px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      placeholder: "Start Date",
                    }}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ margin: "20px 0 0 0" }}>
                <Typography variant="body1">Role*:</Typography>
                <Stack direction={"row"}>
                  <TextField
                    variant="standard"
                    size={"small"}
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    //   value={}
                    //   onChange={}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ margin: "20px 0 0 0" }}>
                <Typography variant="body1">Mode*:</Typography>
                <Stack direction={"row"}>
                  <TextField
                    variant="standard"
                    size={"small"}
                    name="mode"
                    value={formData.mode}
                    onChange={handleChange}
                    //   value={}
                    //   onChange={}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ margin: "20px 0 0 0" }}>
                <Typography variant="body1">Description*:</Typography>
                <Box
                  direction={"row"}
                  sx={{ backgroundColor: "white", borderRadius: 4 }}
                >
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    variant="standard"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    //   value={}
                    //   onChange={}
                  />
                </Box>
              </Stack>
              <Stack sx={{ margin: "20px 0 0 0" }}>
                <Typography variant="body1">Skill Match*:</Typography>
                <Stack direction={"row"} sx={{ width: "42%" }}>
                  <TextField
                    variant="standard"
                    name="skillMatch"
                    value={formData.skillMatch}
                    onChange={handleChange}
                    size={"small"}
                    //   value={}
                    //   onChange={}
                  />
                </Stack>
                <Stack
                  sx={{ margin: "20px 0 20px 0" }}
                  direction={"row"}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (
                        formData.startDate &&
                        formData.skillMatch &&
                        formData.description &&
                        formData.mode &&
                        formData.role
                      ) {
                        handleConfirm();
                      } else {
                        handleAlert();
                        //
                      }
                    }}
                  >
                    Publish
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={props.onClose}
                  >
                    Close
                  </Button>
                </Stack>
                {confirm ? (
                  <Confirmation
                    open={confirm}
                    onClose={handleConfirmClose}
                    submit={handleSubmit}
                  />
                ) : (
                  <></>
                )}

                <Snackbar
                  open={alert}
                  autoHideDuration={2000}
                  onClose={handleAlertClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    onClose={handleAlertClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    Please fill the details!
                  </Alert>
                </Snackbar>
              </Stack>
            </Stack>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default Job;
