import { Outlet, Navigate } from 'react-router-dom';
import { userValidate } from '../api/user_related_service';

const PrivateRoutes = () => {
    let user_token = ""


    // if (localStorage.getItem("user_tk")) {
    //     user_token = userValidate(localStorage.getItem("user_tk"));
    // }

    const token = localStorage.getItem("user_tk");

    setInterval(() => {
        if (token) {
            userValidate(token);
        } else {
            console.log("No token found for validation.");
        }
    }, 30 * 60 * 1000); 
    
    
    return (
        <>

            {token != "" ? <Outlet /> : <Navigate to="/" />}
        </>




    )
}

export default PrivateRoutes
