import React from "react";
import { Card, Stack, styled, useMediaQuery } from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  border: "1px solid #8a91b0",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  position: "relative",
  boxShadow: "0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)",
}));

const CustomCard = ({ children, customStyles }) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const cardWidth = isMobileScreen
    ? "200px"
    : isSmallScreen
    ? "250px"
    : isMediumScreen
    ? "300px"
    : isLargeScreen
    ? "350px"
    : "100%";

  const cardHeight = "350px";

  return (
    <StyledCard
      sx={{
        height: cardHeight,
        width: cardWidth,
        margin: isMobileScreen ? "0 10px" : "0 20px",
        ...customStyles,
      }}
    >
      {children}
    </StyledCard>
  );
};

export default CustomCard;