import * as React from "react";
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import DrawIcon from "@mui/icons-material/Draw";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Horizontal_slider.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Book_courses,
  Edit_course_status,
  fetchRecommendedCourses,
  Get_course_status,
} from "../../api/recommendation_related_service";
import {
  fetchCoursePublish,
  DeleteBookedCourse,
} from "../../api/course_publish_related_service";
import AlertPopup from "../Common_Components/AlertPopup";
import EmptyCard from "../Common_Components/emptyCard";
import CourseCard from "../Common_Components/courseCard"
import Cookies from 'js-cookie';

function HoriCourseSlide({ searchTerm }) {
  const [recommendedCourses, setRecommendedCourses] = React.useState([]);
  const [publishedCourses, setPublishedCourses] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const user_token = Cookies.get("connectedMe")
  const [counter, setCounter] = React.useState(0);
  const [courseId, setCourseId] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = async (courseData) => {
    if (courseData.courseStatus === "Start Course") {
      courseData.courseStatus = "In Progress";
    } else if (courseData.courseStatus === "In Progress") {
      courseData.courseStatus = "Mark as Complete";
    } else if (courseData.courseStatus === "Mark as Complete") {
      courseData.courseStatus = "Completed";
    }
    if (user_token) {
      try {
        setCourseId(courseData.course_id);
        console.log(courseData.course_id, "courseId");

        // setStats()
        await Edit_course_status({
          token: user_token,
          course_id: courseData.course_id,
          status: courseData.courseStatus,
        });
        setCounter((prev) => prev + 1);
      } catch (error) {
        console.error("Error updating the status:", error);
      }
    } else {
      console.error("User token is missing when booking the course.");
    }
  };

  const get_course_status = async () => {
    try {
      const response = await Get_course_status({ token: user_token });

      if (!Array.isArray(response?.status)) {
        console.error("Invalid response format: 'status' is not an array.");
        return;
      }

      setRecommendedCourses((prevCourses) => {
        const new_courses = [];
        prevCourses?.forEach((course) => {
          const matchingStatus = response?.status?.find(
            (StatusObj) => StatusObj.course_id === course.course_id
          );
          const data = { ...course, courseStatus: "Start Course" };
          if (matchingStatus) {
            data["courseStatus"] = matchingStatus.status;
          }
          new_courses.push(data);
        });
        return new_courses;
      });
    } catch (error) {
      console.error("Error getting the status:", error);
    }
  };

  React.useEffect(() => {
    fetchCoursesData().then(get_course_status);
  }, [counter]);
  const fetchCoursesData = async () => {
    try {
      // Fetch recommended courses and published courses separately
      const [recommended, published] = await Promise.all([
        fetchRecommendedCourses({ token: user_token }),
        fetchCoursePublish(),
      ]);

      setRecommendedCourses(recommended || []);
      setPublishedCourses(published || []);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const handelBook = async (courseId) => {
    if (user_token) {
      try {
        // Optimistically update the UI
        setRecommendedCourses((prevCourses) =>
          prevCourses?.map((course) =>
            course.course_id === courseId
              ? { ...course, status: "booked", courseStatus: "Start Course" }
              : course
          )
        );

        await Book_courses({ course_id: courseId, token: user_token });
        setOpenSnackbar(true);
      } catch (error) {
        console.error("Error booking the course:", error);
      }
    } else {
      console.error("User token is missing when booking the course.");
    }
  };

  const handleDelete = async (courseId) => {
    if (user_token) {
      try {
        // Optimistically update the UI
        setRecommendedCourses((prevCourses) =>
          prevCourses?.map((course) =>
            course.course_id === courseId
              ? { ...course, status: "unbooked", courseStatus: "Start Course" }
              : course
          )
        );

        await DeleteBookedCourse({ course_id: courseId, token: user_token });

        setSnackbarMessage("Course successfully removed");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error deleting booked course:", error);
        setSnackbarMessage("Failed to remove course");
        setSnackbarOpen(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
    setSnackbarOpen(!snackbarMessage);
  };

  const PreviousBtn = ({ className, onClick, disabled }) => {
    return (
      <div
        className={className}
        onClick={!disabled ? onClick : null}
        style={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? "none" : "auto" }}
      >
        <ArrowBackIosNewIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };
  
  const NextBtn = ({ className, onClick, disabled }) => {
    return (
      <div
        className={className}
        onClick={!disabled ? onClick : null}
        style={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? "none" : "auto" }}
      >
        <ArrowForwardIosIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };
  
  // Determine which courses to show based on the search term
  const coursesToShow = searchTerm
    ? publishedCourses?.filter((courseData) =>
        courseData.course_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : recommendedCourses;
  const isXLargeScreen = useMediaQuery("(min-width:1500px)");
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery("(max-width:1199px)");
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const slideshow = isMobileScreen
    ? 1
    : isSmallScreen
    ? 2
    : isMediumScreen
    ? 3
    : isLargeScreen
    ? 4
    : 5;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideshow,
    slidesToScroll: 1,
    prevArrow: (
      <PreviousBtn
        disabled={coursesToShow.length === 0 || coursesToShow.length <= slideshow}
      />
    ),
    nextArrow: (
      <NextBtn
        disabled={coursesToShow.length === 0 || coursesToShow.length <= slideshow}
      />
    ),
    centerMode: isMobileScreen,
    centerPadding: "40px",
  };



  return (
    <Box
      sx={{
        width: "85vw",
        marginLeft: isMobileScreen ? "0px" : "60px",
      }}
    >
      <Slider {...settings}>
        {coursesToShow.length === 0 ? (
          <EmptyCard
          message="No Courses Available"
          icon={
            <DrawIcon
              sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
            />
          }
        />
        ) : (
          coursesToShow?.map((courseData, index) => (
            <CourseCard
            key={index}
            courseData={courseData}
            handleDelete ={()=>{
              handleDelete(courseData.course_id)
            }}
            handelBook = {()=>{
              handelBook(courseData.course_id)
            }}
            handleClick = {()=>{
              handleClick(courseData)
            }}
            />
          ))
        )}
      </Slider>
      <AlertPopup
        timer={3000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        type="success"
        message={"Course successfully booked!!"}
      />
      <AlertPopup
        timer={3000}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        type="info"
        message={snackbarMessage}
      />
    </Box>
  );
}

export default HoriCourseSlide;
