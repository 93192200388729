import axios from "axios";
import network_conn from "../utils/network_config";

// Function to fetch skills
export const fetchSkills = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}profile_service/fetch_skills`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching skill_list:", error.message);
    return [];
  }
};

// Function to fetch roles
export const fetchRoles = async () => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}fetch_sfia_role/fetch_role`,
      null, // No body is needed
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data.roles;
  } catch (error) {
    console.error("Error fetching roles:", error.message);
    return [];
  }
};

// Function to fetch current skills for a role
export const fetchCurrentSkills = async (roleId) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}fetch_sfia_role/fetch_skills`,
      { role_id: roleId },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data.skills || [];
  } catch (error) {
    console.error("Error fetching skill_list:", error.message);
    return [];
  }
};

// Function to fetch desirable skills for a role
export const fetchDesirableSkills = async (roleId) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}fetch_sfia_role/fetch_desirable_skill`,
      { role_id: roleId },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data.skills || [];
  } catch (error) {
    console.error("Error fetching skill_list:", error.message);
    return [];
  }
};

// Function to fetch countries
export const fetchcountry = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}profile_service/fetch_country`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching country_list:", error.message);
    return [];
  }
};

// Function to fetch skill gap details
export const fetchSkillGap = async ({ user_token, skill }) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}flash_card/skillcard`,
      { user_token, skill },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching skill gap:", error.message);
    return { skill_level_gap: 0, total_desirable_skills: 0 };
  }
};

export const getDesiredSkillsCount = async (count) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}mentee_progress/same_role_count`,
      count,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count:", error.message);
    return null;
  }
};

export const get_monthly_Course_Count = async (count) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}course_service/get_monthly_course_count`,
      count,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count:", error.message);
    return null;
  }
};
