import React from "react";
import { Menu, MenuItem, Typography, Box, Button } from "@mui/material";
import {
    markNotificationAsRead,
    clearNotification,
} from "../../api/notification_related_service";
import Cookies from 'js-cookie';
const Notification = ({
    anchorEl,
    handleClose,
    notifications = [],
    loading,
    setNotifications,
    setUnreadCount,
    onClick
}) => {
    const open = Boolean(anchorEl);
    const user_token = Cookies.get("connectedMe")
    // console.log("my notificatons", notifications)
    // Mark notification as read when clicked
    const handleNotificationClick = async (notification, index, meeting_id) => {
        if (!notification.read_status) {
            try {
                onClick(true, meeting_id);
                await markNotificationAsRead({
                    notification_id: notification.id,
                });

                // Update local state to mark notification as read
                const updatedNotifications = [...notifications];
                updatedNotifications[index].read_status = true;
                setNotifications(updatedNotifications);

                // Update unread count
                const unreadCount = updatedNotifications.filter(
                    (n) => !n.read_status
                ).length;
                setUnreadCount(unreadCount);
            } catch (error) {
                console.error("Failed to mark notification as read:", error);
            }
        }

        handleClose();
    };

    // Clear all notifications
    const handleClearNotifications = async () => {
        try {
            await clearNotification({ token: user_token });
            setNotifications([]);
            setUnreadCount(0);
            handleClose();
        } catch (error) {
            console.error("Failed to clear notifications:", error);
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: 80, left: window.innerWidth - 350 }}
            PaperProps={{
                elevation: 1,
                sx: {
                    width: "350px",
                    // height: "70vh",
                    padding: "0px", // Set padding to 0 for outer container
                    margin: 0,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "5px"
                },
            }}
        >
            {/* Wrapper Box for Notifications and Title */}
            <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
                <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}
                >
                    Notifications
                </Typography>

                {loading ? (
                    <Typography variant="body2" sx={{ padding: "10px" }}>
                        Loading notifications...
                    </Typography>
                ) : Array.isArray(notifications) && notifications?.length === 0 ? (
                    <Typography variant="body2" sx={{ padding: "10px" }}>
                        No notifications
                    </Typography>
                ) : Array.isArray(notifications) ? (
                    notifications.map((notification, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => handleNotificationClick(notification, index, notification.meeting_id)}
                            sx={{
                                backgroundColor: notification.read_status
                                    ? "lightgray"
                                    : "white",
                                border: "1px solid rgb(201, 201, 206)",
                                borderBottom: "none",
                                margin: 0,
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                height: "70px",
                                overflow: "hidden", // Prevents content from overflowing
                                textOverflow: "ellipsis", // Adds "..." for overflowing text
                                whiteSpace: "nowrap",
                            }}
                        >
                            <Typography variant="body2" sx={{
                                whiteSpace: "normal", // Allows text to wrap
                                wordWrap: "break-word", // Breaks long words into the next line
                                overflowWrap: "break-word", // Ensures consistent wrapping
                                width: "100%", // Ensures it respects the container's width,
                                fontWeight: 'italic'
                            }}>
                                {notification.notification}
                            </Typography>
                        </MenuItem>
                    ))
                ) : (
                    <Typography variant="body2" sx={{ padding: "10px" }}>
                        Invalid notifications data
                    </Typography>
                )}
            </Box>

            {/* Box for the Clear Button at the Bottom */}
            <Box sx={{ padding: "4px", borderTop: "1px solid lightgray", display: "flex", justifyContent: "flex-end", }} >
                <Button
                    variant="contained"
                    onClick={handleClearNotifications}
                    disabled={notifications.length === 0}
                    color="secondary"

                >
                    Clear All
                </Button>
            </Box>
        </Menu>
    );
};

export default Notification;
