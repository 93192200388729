import { Group, Stars } from "@mui/icons-material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchAchievement } from "../../api/achievement_service";
import { fetchProfileImage } from "../../api/profile_related_service";
import AchievementCard from "./achievement_card";
import AchievementModal from "./post_achv";
import Cookies from 'js-cookie';

const Achievement = () => {
  const [achievements, setAchievements] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [profilePicture, setProfilePicture] = useState(null);
  const user_token = Cookies.get("connectedMe")

  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        setIsLoading(true);
        const achievementsData = await fetchAchievement(page);

        if (achievementsData && Array.isArray(achievementsData)) {
          if (achievementsData.length === 0) {
            setHasMore(false);
          } else {
            setAchievements((prevAchievements) => {
              const existingKeys = new Set(
                prevAchievements.map(
                  (achievement) =>
                    `${achievement.user_name}-${achievement.title}-${achievement.created_on}`
                )
              );

              const uniqueAchievements = achievementsData.filter(
                (achievement) =>
                  !existingKeys.has(
                    `${achievement.user_name}-${achievement.title}-${achievement.created_on}`
                  )
              );

              return [...prevAchievements, ...uniqueAchievements];
            });
          }
        }
      } catch (error) {
        console.error("Error fetching achievements:", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (hasMore) {
      fetchAchievements();
    }
  }, [page, hasMore]);

  const handleAddAchievement = (newAchievement) => {
    setAchievements((prevAchievements) => {
      const newKey = `${newAchievement.user_name}-${newAchievement.title}-${newAchievement.created_on}`;
      const existingKeys = new Set(
        prevAchievements.map(
          (achievement) =>
            `${achievement.user_name}-${achievement.title}-${achievement.created_on}`
        )
      );
      return existingKeys.has(newKey)
        ? prevAchievements
        : [newAchievement, ...prevAchievements];
    });
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageUrl = await fetchProfileImage({ user_token });
        setProfilePicture(imageUrl);
      } catch (error) {
        console.error("Error fetching profile image:", error);
      }
    };

    fetchImage();
  }, [user_token]);

  return (
    <Stack>
      <Stack>
        <Stack sx={{ display: "flex", flexDirection: "row", margin: '20px 0 0 20px' }}>
          <Typography variant="h4">Admiration Feeds</Typography>
          <Tooltip sx={{ ml: "10px" }} title="Share and celebrate your achievements throughout your journey, showcasing milestones and successes along the way."><InfoOutlinedIcon fontSize="small" /></Tooltip>
        </Stack>


        <Typography variant="subtitle2"  sx={{ margin: '0 0 20px 20px', color: 'grey' }}>
          Showcase your milestones and celebrate success together.
        </Typography>
      </Stack>
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ width: "100%" }}>
        <Card
          sx={{
            width: { xs: "350px", sm: "400px", md: "600px" },
            margin: 2,
            border: "1px solid #8a91b0",
            boxShadow:
              "0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Avatar
                src={profilePicture}
                sx={{ width: 50, height: 50, marginRight: { sm: 2 }, marginBottom: { xs: 2, sm: 0 } }}
              />
              <Button
                variant="contained"
                fullWidth
                onClick={() => setIsModalOpen(true)}
                sx={{
                  borderRadius: "50px",
                  backgroundColor: "#FFFFFF",
                  color: "secondary.main",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "10px 20px",
                  border: "1px solid #2f3348",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                Post Admiration
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: 2,
                paddingTop: 2,
                borderTop: "1px solid #e0e0e0",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Stars
                  sx={{
                    width: 24,
                    height: 24,
                    marginRight: 1,
                    color: "primary.main",
                  }}
                />
                <Typography variant="body2" color="textSecondary">
                  Share your success
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Group
                  sx={{
                    width: 24,
                    height: 24,
                    marginRight: 1,
                    color: "primary.main",
                  }}
                />
                <Typography variant="body2" color="textSecondary">
                  Inspire others
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ marginTop: 2 }}
      >
        {achievements.map((achievement) => (
          <div
            key={`${achievement.user_name}-${achievement.title}-${achievement.created_on}`}
          >
            <AchievementCard {...achievement} />
          </div>
        ))}
      </Box>

      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100px"
        >
          <CircularProgress />
        </Box>
      )}

      <AchievementModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddAchievement}
      />
    </Stack>
  );
};

export default Achievement;
