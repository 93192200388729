import axios from "axios";
import network_conn from "../utils/network_config";

export const insertCoursePublish = async (profile) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}course_publish_service/insert_course`,
      profile,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Data inserted successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error inserting course:", error.message);
    throw error;
  }
};

export const updateCourseStatus = async (profile) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}course_publish_service/update_status`,
      profile,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Data updated successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating course status:", error.message);
    throw error;
  }
};

export const fetchCoursePublish = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}course_publish_service/fetch_course`,
      fetchreq,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching course publish:", error.message);
    throw error;
  }
};

export const fetchBookedCourse = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}course_service/fetch_booked_courses`,
      fetchreq,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching booked courses:", error.message);
    throw error;
  }
};

export const DeleteBookedCourse = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}course_service/delete_booked_courses`,
      fetchreq,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error deleting booked course:", error.message);
    throw error;
  }
};
