import React from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Avatar,
  Tooltip,
  ButtonBase,
  Chip,
} from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Link } from "react-router-dom";
import CustomCard from "../Common_Components/Card";

const CourseCard = ({ courseData, handleDelete, handelBook, handleClick }) => {
  const {
    course_id,
    course_name,
    course_details,
    course_link,
    status,
    courseStatus,
  } = courseData;
  return (
    <CustomCard>
      <Box
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          borderBottom: "1px solid #2f3348",
          flexWrap: "wrap",
          overflow: "hidden",
          position: "relative",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tooltip title={course_name}>
            <ButtonBase
              component={Link}
              to={course_link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "inline-block",
                textAlign: "left",
                width: "auto",
                padding: 0,
                "&:disabled": { opacity: 0.5 },
              }}
            >
              <Typography
                variant="h3"
                component="div"
                noWrap
                sx={{
                  fontSize: "inherit",
                  lineHeight: "inherit",
                  cursor: "pointer",
                  color: "primary.main",
                  textDecoration: "none",
                }}
              >
                {course_name.split(" ").slice(0, 2).join(" ") +
                  (course_name.split(" ").length > 2 ? "..." : "")}
              </Typography>
            </ButtonBase>
          </Tooltip>
          <Tooltip title={"BOOK COURSE"}>
            <Button
              variant="outline"
              color="secondary"
              sx={{
                fontSize: "0.8rem",
              }}
              onClick={() => {
                if (status === "booked") {
                  handleDelete(course_id);
                } else {
                  handelBook(course_id);
                }
              }}
            >
              <BookmarkIcon
                sx={{
                  color: status === "booked" ? "primary.main" : "#D3D3D3",
                }}
              />
            </Button>
          </Tooltip>
        </Box>
        {status === "booked" && (
          <Chip
            sx={{
              marginTop: "10px",
              fontSize: "0.7rem",
              height: "18px",
              minWidth: "35px",
              padding: "0 4px",
              right: 0,
              maxWidth: "6rem",
            }}
            label={
              courseStatus === "Mark as Complete" ? "In Progress" : courseStatus
            }
            size="small"
            color={courseStatus === "Start Course" ? "info" : "success"}
          />
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          p: 2,
          mb: 15,
          "&::-webkit-scrollbar": { width: "8px" },
          "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {course_details.length > 250
            ? `${course_details.substring(0, 250)}...`
            : course_details}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          p: 1.5,
          borderTop: "1px solid #D3D3D3",
          display: "flex",
          justifyContent: "center",
          gap: 1,
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          component={
            courseData.courseStatus === "Mark as Complete" ||
            courseData.courseStatus === "In Progress"
              ? Button
              : Link
          }
          to={courseData.course_link}
          target="_blank"
          rel="noopener noreferrer"
          disabled={courseData.status !== "booked"}
          sx={{
            fontSize: "0.75rem",
            padding: "6px 12px",
            backgroundColor:
              courseData.status !== "booked"
                ? (theme) => theme.palette.secondary.main
                : undefined,
            "&.Mui-disabled": {
              backgroundColor: (theme) => theme.palette.secondary.main,
              color: "#fff",
              opacity: 1, // Ensures the text remains visible
            },
          }}
          onClick={() => handleClick(courseData)}
        >
          {courseData.status === "booked"
            ? courseData.courseStatus
            : "Start Course"}
        </Button>
      </Box>
    </CustomCard>
  );
};
export default CourseCard;
