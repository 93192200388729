import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinkIcon from "@mui/icons-material/Link";
import {
  Chip,
  IconButton,
  Link as MuiLink,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { fetchCoursePublish } from "../api/course_publish_related_service";
function UserCourse() {
  const [openL, setOpenL] = useState(true);
  const [courseData, setCourseData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handlemenuList = (toggle) => () => {
    setOpenL(toggle);
  };

  useEffect(() => {
    fetchCoursePublishData();
  }, []);

  const fetchCoursePublishData = async () => {
    try {
      const response = await fetchCoursePublish();
      setCourseData(response);
    } catch (error) {
      console.error(error);
    }
  };

  // Filter courses based on the search term
  const filteredCourses = courseData.filter((course) =>
    course.course_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Stack>
        <Stack>
          <Stack sx={{ display: "flex", flexDirection: "row", margin: '20px 0 0 20px' }}>
            <Typography variant="h4" >
              Courses
            </Typography>
            <Tooltip sx={{ ml: "10px" }} title="Explore variety of courses designed to help you learn new skills and enhance your expertise."><InfoOutlinedIcon fontSize="small" /></Tooltip>
          </Stack>
          <Typography
            variant="subtitle2"
            sx={{ margin: "0 0 20px 20px", color: "grey" }}
          >
            Explore Our Top Courses: Expertly Crafted Learning Journeys for Your
            Success
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ marginBottom: 2, marginLeft: 3 }}>
          <TextField
            label="Search Courses"
            variant="outlined"
            size="small"
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: "300px" }}
          />
        </Stack>
      </Stack >
      <Stack sx={{ marginTop: 1, width: "97%" }}>
        {filteredCourses.length === 0 ? (
          <Stack
            sx={{
              height: "75vh",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h5">No Courses Available</Typography>
          </Stack>
        ) : (
          <TableContainer
            sx={{ margin: "30px", borderRadius: "8px" }}
            component={Paper}
          >
            <Table>
              <TableHead sx={{ backgroundColor: "primary.main" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      width: "20%",
                      fontWeight: "bold",
                      color: "white",
                      // height: "60px",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >

                    Course Name
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      width: "10%",
                      fontWeight: "bold",
                      color: "white",
                      // height: "60px",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Link
                  </TableCell> */}
                  <TableCell
                    sx={{
                      width: "15%",
                      fontWeight: "bold",
                      color: "white",
                      // height: "60px",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Tag
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "30%",
                      fontWeight: "bold",
                      color: "white",
                      // height: "60px",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Details
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "15%",
                      fontWeight: "bold",
                      color: "white",
                      // height: "60px",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Rating
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCourses.map((course, index) =>
                  course.course_status === "Open" ? (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          height: "60px",
                          borderRight: "1px solid #e0e0e0",
                        }}
                      >
                        <a
                          href={course.course_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#1e88e5", textDecoration: "none", fontWeight: "bold" }}
                        >
                          {course.course_name}
                        </a>
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          height: "60px",
                          borderRight: "1px solid #e0e0e0",
                        }}
                      >
                        <a
                          href={course.course_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <MuiLink
                            component="span"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <IconButton aria-label="link" size="small">
                              <LinkIcon />
                            </IconButton>
                            Link
                          </MuiLink>
                        </a>
                      </TableCell> */}
                      <TableCell
                        sx={{
                          height: "60px",
                          borderRight: "1px solid #e0e0e0",
                        }}
                      >
                        {course.course_tag.map((tag, idx) => (
                          <Chip key={idx} label={tag} sx={{ margin: "2px" }} />
                        ))}
                      </TableCell>
                      <TableCell
                        sx={{
                          height: "60px",
                          borderRight: "1px solid #e0e0e0",
                        }}
                      >
                        {course.course_details.length > 180
                          ? `${course.course_details.substring(0, 180)}...`
                          : course.course_details}
                      </TableCell>
                      <TableCell
                        sx={{
                          height: "60px",
                          borderRight: "1px solid #e0e0e0",
                        }}
                      >
                        N/A
                      </TableCell>
                    </TableRow>
                  ) : null
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
}

export default UserCourse;
