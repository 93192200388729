import { React, useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  Chip,
  Modal,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Horizontal_slider.css";
import PeopleIcon from "@mui/icons-material/People";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { post_notification } from "../../api/notification_related_service";
import { sendMentorBookingMail } from "../../api/mail_related_service";
import { fetchRecommendedMentors, Book_mentor, fetchBookedMentors, unbookMentors } from "../../api/recommendation_related_service";
import EmptyCard from "../Common_Components/emptyCard";
import MentorCard from "../Common_Components/mentorCard";
import Cookies from 'js-cookie';

function HoriMentorSlide() {
  const user_token = Cookies.get("connectedMe")
  const [mentor, setMentor] = useState([]);
  const [bookedMentors, setBookedMentors] = useState([]);
  const [mentorUseruuid, setMentorUseruuid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedMentor, setSelectedMentor] = useState([]);

  useEffect(() => {
    fetchRecommendedMentorData();
    fetchBookedMentorData();
  }, []);

  const fetchRecommendedMentorData = async () => {
    try {
      setIsLoading(true);
      const response = await fetchRecommendedMentors({
        token: user_token,
      });
      setMentor(response || []);
    } catch (error) {
      console.error("Error fetching recommended mentors:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBookedMentorData = async () => {
    try {
      const response = await fetchBookedMentors({ token: user_token });
      setBookedMentors(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error("Error fetching recommended mentors:", error);
      setBookedMentors([]);
    }
  };
  const handleSessionSelect = (session) => {
    if (selectedSession?.session_start_date === session.session_start_date) {
      setSelectedSession(null);
    } else {
      setSelectedSession(session);
    }
  };
  const convertToLocalDate = (utrDateString) => {
    const utcDate = new Date(utrDateString);
    const localDate = utcDate.toLocaleDateString();
    const localTime = utcDate.toLocaleTimeString();
    return `${localDate} ${localTime}`;
  };

  function convertToUTC(localDateTime) {
    // Create a new Date object from the local date-time string
    const localDate = new Date(localDateTime);

    // Check if the date is valid
    if (isNaN(localDate)) {
      throw new Error("Invalid date format");
    }

    // Get the UTC components
    const utcYear = localDate.getUTCFullYear();
    const utcMonth = (localDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const utcDate = localDate.getUTCDate().toString().padStart(2, "0");
    const utcHours = localDate.getUTCHours().toString().padStart(2, "0");
    const utcMinutes = localDate.getUTCMinutes().toString().padStart(2, "0");
    const utcSeconds = localDate.getUTCSeconds().toString().padStart(2, "0");
    const utcMilliseconds = localDate
      .getUTCMilliseconds()
      .toString()
      .padStart(3, "0"); // Fractional seconds

    // Return the UTC date-time in ISO 8601 format with fractional seconds
    return `${utcYear}-${utcMonth}-${utcDate}T${utcHours}:${utcMinutes}:${utcSeconds}.${utcMilliseconds}Z`;
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleCloseConfirm = () => {
    const payload = {
      mentor_uuid: mentorUseruuid,
      start_date: selectedSession.session_start_date,
      end_date: selectedSession.session_end_date,
    };
    sendMentorBookingMail({ ...payload, user_token: user_token });
    Book_mentor({
      mentor_uuid: mentorUseruuid,
      token: user_token,
      session_date: convertToUTC(selectedSession.session_start_date),
    })
      .then(() => {
        setBookedMentors((prevBookedMentors) => [
          ...prevBookedMentors,
          { mentor_user_uuid: mentorUseruuid },
        ]);
      })
      .catch((error) => {
        console.error("Error booking mentor:", error);
      });
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleBook = (mentorData) => {
    setSelectedMentor(mentorData);
    setMentorUseruuid(mentorData.mentor_user_uuid);
    setOpen(true);
  };

  const handleUnbook = async (mentor_uuid) => {
    try {
      await unbookMentors({ token: user_token, mentor_uuid: mentor_uuid });
      // Update both the recommended mentors and booked mentors
      fetchRecommendedMentorData();
      // Remove the unbooked mentor from bookedMentors state
      setBookedMentors((prevBookedMentors) =>
        prevBookedMentors.filter(
          (mentor) => mentor.mentor_user_uuid !== mentor_uuid
        )
      );
    } catch (error) {
      console.error("Error unbooking mentor:", error);
    }
  };
  const isMentorBooked = (mentorUuid) => {
    return bookedMentors.some(
      (bookedMentor) => bookedMentor.mentor_user_uuid === mentorUuid
    );
  };
  const PreviousBtn = (props) => {
    const { className, onClick } = props;
    const isDisabled = mentor.length === 0; // Disable when no mentors are available
    return (
      <div
        className={className}
        onClick={!isDisabled ? onClick : undefined} // Prevent click when disabled
        style={{
          pointerEvents: isDisabled ? "none" : "auto",
          opacity: isDisabled ? 0.5 : 1, // Apply blur effect when disabled
        }}
      >
        <ArrowBackIosNewIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };

  const NextBtn = (props) => {
    const { className, onClick } = props;
    const isDisabled = mentor.length === 0; // Disable when no mentors are available
    return (
      <div
        className={className}
        onClick={!isDisabled ? onClick : undefined} // Prevent click when disabled
        style={{
          pointerEvents: isDisabled ? "none" : "auto",
          opacity: isDisabled ? 0.5 : 1, // Apply blur effect when disabled
        }}
      >
        <ArrowForwardIosIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };

  const isXLargeScreen = useMediaQuery("(min-width:1500px)");
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery("(max-width:1199px)");
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const slideshow = isMobileScreen
    ? 1
    : isSmallScreen
    ? 2
    : isMediumScreen
    ? 3
    : isLargeScreen
    ? 4
    : 5;

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideshow,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    centerMode: isMobileScreen,
    centerPadding: "40px",
  };
  return (
    <Box
      sx={{
        width: "85vw",
        marginLeft: isMobileScreen ? "0px" : "60px",
      }}
    >
      <Slider {...settings}>
        {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100px"
                  >
                    <CircularProgress />
                  </Box>
        ): mentor.length === 0 ? (
          <EmptyCard
            message="No Mentor Available"
            icon={
              <PeopleIcon
                sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
              />
            }
          />
          ):(
          mentor
            .filter((mentorData) => mentorData.sessions.length > 0)
            .map((mentorData, index) => (
              <MentorCard
                key={index}
                mentorData={mentorData}
                buttonLabel={
                  isMentorBooked(mentorData.mentor_user_uuid)
                    ? "UNBOOK"
                    : "BOOK"
                }
                handleBook={() => {
                  if (isMentorBooked(mentorData.mentor_user_uuid)) {
                    handleUnbook(mentorData.mentor_user_uuid);
                  } else {
                    handleBook(mentorData);
                  }
                }}
              />
            ))
          )}
      </Slider>
      <Box>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Typography variant="h6">Confirm Your Booking</Typography>
            <Typography sx={{ mt: 2, mb: 2 }}>
              Select the meeting date
            </Typography>
            <Stack
              spacing={1}
              direction="row"
              sx={{ flexWrap: "wrap", overflowX: "auto" }}
            >
              {selectedMentor?.sessions &&
              selectedMentor.sessions.length > 0 ? (
                selectedMentor.sessions.map((session, sessionIndex) => (
                  <Chip
                    key={sessionIndex}
                    label={convertToLocalDate(session.session_start_date)}
                    clickable
                    onClick={() => handleSessionSelect(session)}
                    sx={{
                      backgroundColor:
                        selectedSession?.session_start_date ===
                        session.session_start_date
                          ? "#2f3348"
                          : "default",
                      color:
                        selectedSession?.session_start_date ===
                        session.session_start_date
                          ? "#ffffff"
                          : "default",
                    }}
                  />
                ))
              ) : (
                <Typography>No available sessions</Typography>
              )}
            </Stack>

            <Stack sx={{ marginTop: "20px" }} direction="row" spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseConfirm}
                disabled={!selectedSession}
              >
                Confirm
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default HoriMentorSlide;
