import React, { useRef, useState } from 'react'
import Jobform from './Jobform';
import Optionbar from '../Optionbar/Optionbar';
import { Avatar, AvatarGroup, Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, IconButton, InputLabel, Menu, MenuItem, NativeSelect, Paper, Radio, RadioGroup, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Tooltip, Typography, createTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import useStore from '../../store/sponsor_data';
import { Delete, ModeEdit, MoreVert } from '@mui/icons-material';
import { addJob, getJob, getMenteDetails, getMentee, getSponsor, update_status } from '../../api/job_advertisement_related_service';
import userSess from '../../store/user_session_control';
import ArchiveJobs from './ArchiveJobs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { boolean } from 'zod';



const AppliedJobs = (props) => {
    const Jobevents = props.Jobevents;
    const { user_token, updateSess } = userSess()
    const [mentee, setMentee] = useState()
    const [sponsor, setSponsor] = useState()
    const [selectedMenteeMatch, setSelectedMenteeMatch] = useState([]);


    React.useEffect(() => {
        const handleClick_cal = async () => {

            const app_events = await getMenteDetails(user_token)
            console.log(app_events)
            setMentee(app_events)
        };
        handleClick_cal();
    }, []);

    React.useEffect(() => {
        const handleSponsor = async () => {

            const app_events = await getSponsor(user_token)
            setSponsor(app_events)

        };
        handleSponsor();
    }, []);

    // const fetch_mentorname = (mentee_name) => {
    //     let mentor = ""
    //     sponsor.forEach(s => {
    //         let mentor_name = s.refered_mentee.split(',');

    //         if (mentor_name.includes(mentee_name)) {
    //             mentor = s.mentor_name;

    //         }
    //         console.log(mentee_name)
    //     })
    //     return mentor;
    // };





    const col = ['Job Id', 'Role', 'Skills', 'Applied By', 'Sponsered By'];
    return (
        <>
            <Stack>

                <Table className="fullWidthTable" sx={{ minWidth: 350 }} style={{ fontSize: '3rem' }}>

                    <TableHead sx={{ backgroundColor: '#2f3348' }}>
                        <TableRow>

                            {[...Array(5)].map((_, index) => (
                                <TableCell key={index} style={{ fontWeight: 'bold', width: '10px', height: '50px', borderRight: '1px solid #e0e0e0', color: 'white' }}>{col[index]}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sponsor && mentee && mentee.map(index => {
                                let mentor = ""
                                sponsor.forEach(s => {
                                    let mentor_name = s.refered_mentee.split(',').map(el => el.trim());
                                    // console.log("name", mentor_name)
                                    // mentor_name.forEach(name=>{
                                    //     console.log(name)
                                    //     console.log(index.mentee_name)
                                    //     if(name == index.mentee_name){
                                    //         mentor = s.mentor_name
                                    //     }
                                    // })
                                    if (mentor_name.includes(index.mentee_name.trim())) {
                                        mentor = s.mentor_name;

                                    }

                                })
                                console.log(mentor)
                                return (
                                    <TableRow >
                                        <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.job_id}</TableCell>
                                        <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.role}</TableCell>
                                        <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>
                                            {index.skill && index.skill.split(',').map((skill, index) => (
                                                <Chip key={index} label={skill} style={{ marginRight: 8 }} />
                                            ))}
                                        </TableCell>
                                        <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>
                                        <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <AvatarGroup max={4}  >
                                                    { (
                                                        <Tooltip title={
                                                            <React.Fragment>
                                                                <Typography variant="body2" color="inherit">
                                                                {index.mentee_name}
                                                                </Typography>

                                                            </React.Fragment>
                                                        } arrow>
                                                            <Avatar >{index.mentee_name.substring(0, 1)}</Avatar>
                                                        </Tooltip>
                                                    )}

                                                </AvatarGroup>
                                            </Box>
                                            {/* {index.mentee_name} */}
                                            </TableCell>
                                        <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>
                                            <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <AvatarGroup max={4}  >
                                                    {mentor && (
                                                        <Tooltip title={
                                                            <React.Fragment>
                                                                <Typography variant="body2" color="inherit">
                                                                    {mentor}
                                                                </Typography>

                                                            </React.Fragment>
                                                        } arrow>
                                                            <Avatar >{mentor.substring(0, 1)}</Avatar>
                                                        </Tooltip>
                                                    )}

                                                </AvatarGroup>
                                            </Box>
                                            {/* {fetch_mentorname(index.mentee_name)} */}

                                        </TableCell>

                                        {/* { sponsor.filter(s => s.refered_mentee.split(',').includes(index.mentee_name)).map(s => {
                                                console.log("s", s); // log the message to the console
                                                return s.mentor_name; // return the mentor_name
                                            })} */}
                                        {/* <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>


                                        {sponsor.forEach(s => {
                                            let mentor = s.refered_mentee.split(',');
                                            
                                            if (mentor.includes(index.mentee_name)) {
                                                s.mentor_name
                                            }


                                        }

                                        )};

                                    </TableCell> */}



                                    </TableRow >
                                )
                            }

                            )
                        }


                    </TableBody>
                </Table>
                {/* {sponsor.refered_mentee.includes(index.mentee_name).map((index) => (
    <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.mentor_name}</TableCell>
))} */}
            </Stack>
        </>
    )
}

export default AppliedJobs
