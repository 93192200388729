import React from "react"; 
import { Box, Typography, Button, Stack, Avatar, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import CustomCard from "../Common_Components/Card";
import InfoIcon from "@mui/icons-material/Info"; // Import the InfoIcon

const SessionCard = ({
  title,
  meetingId,
  description,
  startDate,
  handleBook,
  handleCardRemove,
  refreshData,
  author,
  jobTitle,
  image,
  buttonLabel,
  hasNotification, // New prop for optional InfoIcon
  onNotificationClick, // Callback for notification click
}) => {
  const convertToLocalDate = (utrDateString) => {
    const utcDate = new Date(utrDateString);
    const localDate = utcDate.toLocaleDateString();
    const localTime = utcDate.toLocaleTimeString();
    return `${localDate} ${localTime}`;
  };

  return (
    <CustomCard>
      <Box
        sx={{
          border: "1px solid #ccc",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {hasNotification && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <IconButton
              onClick={onNotificationClick}
              style={{ color: "black" }}
            >
              <InfoIcon />
            </IconButton>
          </Box>
        )}

        <Link
          to={`/d/dashboard/${meetingId}`}
          underline="hover"
          color="inherit"
          style={{ textDecoration: "none" }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {title}
          </Typography>
        </Link>
        <Box
          sx={{
            flexGrow: 1,
            minHeight: "80px", 
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 4,
          }}
        >
          <Typography variant="subtitle1">{description}</Typography>
        </Box>

        <Typography
          variant="overline"
          sx={{
            color: "#f65a4c",
            marginBottom: "8px",
          }}
        >
          {convertToLocalDate(startDate)}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginBottom: "8px" }}
          onClick={async () => {
            try {
              await handleBook(meetingId);
              handleCardRemove(meetingId);
              refreshData();
            } catch (error) {
              console.error("Error unbooking session:", error);
            }
          }}
        >
          {buttonLabel}
        </Button>
        <Stack
          direction="row"
          gap={1}
          sx={{
            height: "16%",
            width: "auto",
            mx: -4,
            marginBottom: "8px",
            bgcolor: "primary.main",
            alignItems: "center",
            position: "relative",
            right: "-16px",
            left: "-16px",
            px: 4,
            py: 1,
          }}
        >
          <Avatar sx={{ ml: 2 }} src={image || undefined} />
          <Stack>
            <Typography
              variant="body2"
              sx={{
                color: "#ffffff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {author}
            </Typography>
            <Typography variant="caption" sx={{ color: "#ffffff" }}>
              {jobTitle}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </CustomCard>
  );
};

export default SessionCard;
