import React, { useEffect, useState } from "react";
import {
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
  TextField,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  FormControl,
  NativeSelect,
  Chip,
  useTheme,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Optionbar from "../Optionbar/Optionbar";
import { MoreVert } from "@mui/icons-material";
// import useCourseStore from "../../store/course_data";
import {
  fetchCoursePublish,
  insertCoursePublish,
  updateCourseStatus,
} from "../../api/course_publish_related_service";
import Cookies from 'js-cookie';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Closedcourse = () => {
  // const [course, setCourse] = useState([]);

  const user_token = Cookies.get("connectedMe")
  const [open, setOpen] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [link, setLink] = useState("");
  const [tags, setTags] = useState("");
  const [details, setDetails] = useState("");
  const [status, setStatus] = useState("Active");

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [courseData, setCourseData] = useState([]);

  useEffect(() => {
    fetchCoursePublishData();
  }, []);
  const fetchCoursePublishData = async () => {
    try {
      const response = await fetchCoursePublish();
      // console.log(response);
      setCourseData(response);
    } catch (error) {}
  };

  // const courses = useCourseStore((state) => state.courses);
  // const addCourse = useCourseStore((state) => state.addCourse);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePublish = () => {
    const payload = {
      course_name: courseName,
      course_link: link,
      course_tag: tags,
      course_details: details,
      course_status: status,
    };
    insertCoursePublish({ ...payload, token: user_token });
    handleClose();

    setCourseName("");
    setLink("");
    setTags("");
    setDetails("");
    setTimeout(() => {
      fetchCoursePublishData();
    }, 10);
  };

  const theme = useTheme();
  const handleStatusChange = async (event, courseId) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    const payload = {
      course_id: courseId,
      course_status: newStatus,
      // token: userToken,
    };

    updateCourseStatus({ ...payload, token: user_token });
    console.log(payload);
    setTimeout(() => {
      fetchCoursePublishData();
    }, 10);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Stack>
          {courseData.length === 0 ? (
            <Stack
              sx={{
                height: "75vh",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography variant="h5">No Courses Available</Typography>
            </Stack>
          ) : (
            <>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <TableContainer sx={{ margin: "5px" }} component={Paper}>
                    <Table>
                      <TableHead sx={{ backgroundColor: "#2f3348" }}>
                        <TableRow
                          sx={{
                            display: "flex",
                          }}
                        >
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              // width: "10px",
                              flex: 1,
                              height: "50px",
                              borderRight: "1px solid #e0e0e0",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Course Name
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              // width: "10px",
                              flex: 1,
                              height: "50px",
                              borderRight: "1px solid #e0e0e0",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Course Id
                          </TableCell>
                          <TableCell
                            sx={{
                              flex: 1,
                              fontWeight: "bold",
                              color: "white",
                              borderRight: "1px solid #e0e0e0",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Link
                          </TableCell>
                          <TableCell
                            sx={{
                              flex: 1,
                              fontWeight: "bold",
                              color: "white",
                              borderRight: "1px solid #e0e0e0",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Tag
                          </TableCell>
                          <TableCell
                            sx={{
                              flex: 2,
                              fontWeight: "bold",
                              color: "white",
                              borderRight: "1px solid #e0e0e0",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Details
                          </TableCell>
                          <TableCell
                            sx={{
                              flex: 1,
                              fontWeight: "bold",
                              color: "white",
                              borderRight: "1px solid #e0e0e0",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Rating
                          </TableCell>
                          <TableCell
                            sx={{
                              flex: 1,
                              fontWeight: "bold",
                              color: "white",
                              borderRight: "1px solid #e0e0e0",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {courseData.map(
                          (course, index) =>
                            course.course_status === "Close" && (
                              <TableRow key={index} sx={{ display: "flex" }}>
                                <TableCell
                                  sx={{
                                    flex: 1,
                                    borderRight: "1px solid #e0e0e0",
                                  }}
                                >
                                  {course.course_name}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    flex: 1,
                                    borderRight: "1px solid #e0e0e0",
                                  }}
                                >
                                  {course.course_id}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    flex: 1,
                                    borderRight: "1px solid #e0e0e0",
                                  }}
                                >
                                  <a
                                    href={course.course_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {course.course_link}
                                  </a>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    flex: 1,
                                    borderRight: "1px solid #e0e0e0",
                                  }}
                                >
                                  {course.course_tag.map((tag, index) => (
                                    <Chip
                                      key={index}
                                      label={tag}
                                      sx={{
                                        margin: "2px",
                                        // backgroundColor: "#2f3348",
                                      }}
                                    />
                                  ))}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    flex: 2,
                                    borderRight: "1px solid #e0e0e0",
                                  }}
                                >
                                  {course.course_details}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    flex: 1,
                                    borderRight: "1px solid #e0e0e0",
                                  }}
                                >
                                  NA
                                  {/* {course.course_rating} */}
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: 1,
                                    borderRight: "1px solid #e0e0e0",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <Stack
                                    display={"fex"}
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                  >
                                    <Stack>
                                      <FormControl fullWidth>
                                        {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                                    Status
                                                                </InputLabel> */}
                                        <NativeSelect
                                          defaultValue={course.course_status}
                                          inputProps={{
                                            name: "Status",
                                            id: "status",
                                          }}
                                          onChange={(event) =>
                                            handleStatusChange(
                                              event,
                                              course.course_id
                                            )
                                          } // Add this onChange handler
                                        >
                                          <option value="Open">Open</option>
                                          <option value="Close">Close</option>
                                          <option value="Archive">
                                            Archive
                                          </option>
                                        </NativeSelect>
                                      </FormControl>
                                    </Stack>
                                    <Stack
                                      display={"fex"}
                                      flexDirection={"row"}
                                    >
                                      <IconButton
                                        size="large"
                                        aria-label="display more actions"
                                        edge="end"
                                        color="inherit"
                                      >
                                        <MoreVert
                                        // onClick={(event) => {
                                        //   props.handleClick(event);
                                        // }}
                                        />
                                      </IconButton>
                                    </Stack>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabContext>
              </Box>
            </>
          )}
          <Stack onClick={handleClickOpen}>
            <Optionbar onClick={handleClickOpen} />
          </Stack>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            sx={{
              "& .MuiDialog-paper": {
                minWidth: "800px",
              },
            }}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Add Courses
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 3,
                }}
              >
                <Stack sx={{ width: "300px" }}>
                  <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                    Course Name
                  </Typography>
                  <TextField
                    autoFocus
                    type="text"
                    variant="standard"
                    value={courseName}
                    onChange={(e) => setCourseName(e.target.value)}
                  />
                </Stack>
                <Stack sx={{ width: "300px", marginTop: "20px" }}>
                  <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                    Link
                  </Typography>
                  <TextField
                    type="text"
                    variant="standard"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </Stack>
                <Stack sx={{ width: "300px" }}>
                  <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                    Tags
                  </Typography>
                  <TextField
                    type="text"
                    variant="standard"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                    Details
                  </Typography>
                  <TextField
                    type="text"
                    variant="standard"
                    multiline
                    rows={4}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                  />
                </Stack>
              </Box>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 3,
                }}
              >
                <Button
                  onClick={handlePublish}
                  variant="contained"
                  color="secondary"
                >
                  Publish
                </Button>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                >
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </BootstrapDialog>
        </Stack>
      </ThemeProvider>
    </>
  );
};

export default Closedcourse;
