import { Add } from "@mui/icons-material";
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import React from "react";

const Optionbar = (props) => {
  return (
    <>
      <Box sx={{ position: "fixed", bottom: "20px", right: "40px" }}>
        <SpeedDial
          ariaLabel="Actions"
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "40px",
            "& .MuiFab-primary": {
              backgroundColor: "secondary.main",
              color: "secondary.contrastText",
            },
          }}
          icon={<Add />}
          key="AddCourse"
          tooltipTitle="Add New Job"
          onClick={props.handlePopup2Open}
        >
          {/* <SpeedDialAction
                                
                            /> */}
        </SpeedDial>
      </Box>
    </>
  );
};

export default Optionbar;
