import * as React from 'react';
import { useState } from 'react';
import { Avatar, Box, Button, Card, Stack, Typography, useMediaQuery } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Horizontal_slider.css";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useUserPrefSess from '../../store/dash_session';
import { addSession } from '../../api/session_service';
import userSess from '../../store/user_session_control';
import useUserMentorSess from '../../store/user_mentor_session';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/system';

function HoriSlide_mentor(props) {

    const PreviousBtn = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <ArrowBackIosNewIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
            </div>
        );
    };
    const NextBtn = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <ArrowForwardIosIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
            </div>
        );
    };

    const { sess, setuseUserPrefSess } = useUserPrefSess();
    const { user_token } = userSess()

    const isSmallScreen = useMediaQuery('(max-width:1200px)');
    const slideshow = isSmallScreen ? 3 : 5;
    const [open, setOpen] = React.useState(false);

    const handle_book = (met_id) => {
        addSession({ 'meeting_id': met_id, 'token': user_token })
        setOpen(true);
    }
    const handleCardRemove = (met_id) => {
        const updatedSess = sess.filter(s => s.meeting_id !== met_id);
        setuseUserPrefSess(updatedSess);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const convertToLocalDate = (utrDateString) => {
        const utcDate = new Date(utrDateString);
        const localDate = utcDate.toLocaleDateString();
        const localTime = utcDate.toLocaleTimeString();
        return `${localDate} ${localTime}`;
    };
    const StyledCard = styled(Card)(({ theme }) => ({
        padding: theme.spacing(1),
        border: '1px solid #2f3348',
        borderRadius: theme.spacing(1),
        boxShadow: '0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)',
    }));
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slideshow,
        slidesToScroll: 1,
        prevArrow: <PreviousBtn />,
        nextArrow: <NextBtn />
    };

    return (
        <Box sx={{ 'width': '85vw', 'margin-left': '60px' }}>
            <Slider {...settings}>
                    <StyledCard sx={{ width: '400px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ textAlign: 'center', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                            <CheckCircleIcon sx={{ fontSize: 64, color: 'green', marginBottom: '20px' }} />
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>No cards available</Typography>
                        </Box>
                    </StyledCard>
            </Slider>
        </Box>
    )
}

export default HoriSlide_mentor;
