import React, { useState, useEffect } from "react";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EditIcon from "@mui/icons-material/Edit";
import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  Appointments,
  Toolbar,
  ViewSwitcher,
  MonthView,
  DayView,
  DateNavigator,
  TodayButton,
  CurrentTimeIndicator,
  AppointmentTooltip,
  AppointmentForm,
  ConfirmationDialog,
  DragDropProvider,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  Grid,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  useTheme,
} from "@mui/material";
import { SpeedDial } from "@mui/material";
import { SpeedDialAction } from "@mui/material";
import useEventDataStore from "../../store/SchedulerData";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  addAppointment,
  updateAppointment,
  deleteAppointment,
} from "../../api/scheduler_related_services";
import { fetchProfile } from "../../api/profile_related_service";
import { useNavigate } from "react-router";
import useProfile from "../../store/profile_data";
import { fetchUserMentSession, BookedMentors } from "../../api/session_service";
import Cookies from 'js-cookie';

const actions = [{ icon: <EventNoteIcon />, name: "Add Event" }];

const dragDisableIds = new Set([3, 8, 10, 12]);
const allowDrag = ({ id }) => !dragDisableIds.has(id);

const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
  const onCustomFieldChange = (nextValue) => {
    onFieldChange({ customField: nextValue });
  };

  return (
    <>
      <AppointmentForm.BasicLayout
        appointmentData={appointmentData}
        onFieldChange={onFieldChange}
        {...restProps}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt={2} mb={2} />
          </Grid>
          <Grid item xs={12}>
            <AppointmentForm.Label text="Add Location " type="title" />
            <AppointmentForm.TextEditor
              value={appointmentData.customField}
              onValueChange={onCustomFieldChange}
              placeholder="Add location of your meeting"
            />
          </Grid>
        </Grid>
      </AppointmentForm.BasicLayout>
    </>
  );
};

const SchedulerComponent = () => {
  const {
    appointments = [],
    setAppointments,
    mentorSessions = [],
    setMentorSessions,
    bookedMentorSes = [],
    setBookedMentorSes,
  } = useEventDataStore();

  const user_token = Cookies.get("connectedMe")
  const [editingAppointment, setEditingAppointment] = useState(null);
  const [isOpenAppointmentForm, setIsOpenAppointmentForm] = useState(false);
  const [isNewAppointment, setIsNewAppointment] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    const fetchMentorData = async () => {
      const mentorSessionsData = await fetchUserMentSession({
        token: user_token,
      });
      console.log("mentorSessionsData", mentorSessionsData);
      setMentorSessions(mentorSessionsData);
      const bookedMentorsData = await BookedMentors({
        token: user_token,
      });
      console.log("bookedMentorsData", bookedMentorsData);
      setBookedMentorSes(bookedMentorsData);
    };

    fetchMentorData();
  }, [setMentorSessions, setBookedMentorSes, user_token]);

  const combinedData = [
    ...(appointments || []),
    ...(mentorSessions || []).map((session) => ({
      ...session,
      color: "#b676b1",

      isMentorSession: true,
    })),
    ...(bookedMentorSes || []).map((session) => ({
      ...session,
      color: "#82caaf",
      isBookedMentor: true,
    })),
  ];
  console.log("Combined Data:", combinedData);

  const [currentViewName, setCurrentViewName] = useState("Month");
  const navigate = useNavigate();
  const { setUser } = useProfile();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const commitChanges = async ({ added, changed, deleted }) => {
    console.log("Commit Changes Called:", {
      added,
      changed,
      deleted,
      isNewAppointment,
    });

    let newData = [...(Array.isArray(appointments) ? appointments : [])];
    const currentDateTime = new Date();

    // Handle Added Appointments
    if (added || isNewAppointment) {
      const appointmentToAdd = added || editingAppointment;
      console.log("Adding New Appointment:", appointmentToAdd);

      const addedStartDate = new Date(appointmentToAdd.startDate);

      // Validation checks
      if (!appointmentToAdd.title || appointmentToAdd.title.trim() === "") {
        alert("Title is required.");
        return;
      }
      if (addedStartDate < currentDateTime) {
        alert("Start date cannot be in the past.");
        return;
      }
      if (appointmentToAdd.startDate >= appointmentToAdd.endDate) {
        alert("Start date must be less than end date.");
        return;
      }

      // Generate random ID
      const randomId = Math.floor(Math.random() * 9000) + 1000;
      const newAppointment = {
        id: randomId,
        ...appointmentToAdd,
      };

      // Location validation
      if (!newAppointment.customField || !newAppointment.customField.trim()) {
        alert("Location is required.");
        return;
      }

      // Check professional experience
      const profile = await fetchProfile({ user_token: user_token });
      const hasNonEmptyExperience =
        profile.professional_experience &&
        profile.professional_experience.some(
          (exp) =>
            exp.jobTitle || exp.organization || exp.startYear || exp.endYear
        );
      if (!hasNonEmptyExperience) {
        setIsDialogOpen(true);
        return;
      }

      // Add to local state and backend
      newData = [...newData, newAppointment];
      console.log("New Appointment to Add:", newAppointment);

      try {
        const appointmentToAdd = {
          id: Math.floor(Math.random() * 10000),
          ...added,
        };
        await addAppointment({ ...appointmentToAdd, token: user_token });
        setAppointments(newData);
        setIsOpenAppointmentForm(false);
        setIsNewAppointment(false);
      } catch (error) {
        console.error("Error adding appointment:", error);
        alert("Failed to add appointment");
      }
    }

    // Handle Changed Appointments
    if (changed) {
      console.log("Changing Appointment:", changed);
      newData = newData.map((appointment) => {
        const changedAppointment = changed[appointment.id];
        if (changedAppointment) {
          const updatedAppointment = { ...appointment, ...changedAppointment };

          // Validation checks
          if (updatedAppointment.startDate < currentDateTime) {
            alert("Start date cannot be in the past.");
            return appointment;
          }
          if (updatedAppointment.startDate >= updatedAppointment.endDate) {
            alert("Start date must be less than end date.");
            return appointment;
          }

          return updatedAppointment;
        }
        return appointment;
      });

      const updatedAppointment = newData.find(
        (appointment) => changed[appointment.id]
      );

      try {
        await updateAppointment({ ...updatedAppointment, token: user_token });
        setAppointments(newData);
      } catch (error) {
        console.error("Error updating appointment:", error);
        alert("Failed to update appointment");
      }
    }

    // Handle Deleted Appointments
    if (deleted !== undefined) {
      console.log("Deleting Appointment:", deleted);
      newData = newData.filter((appointment) => appointment.id !== deleted);

      try {
        await deleteAppointment({ id: deleted, token: user_token });
        setAppointments(newData);
      } catch (error) {
        console.error("Error deleting appointment:", error);
        alert("Failed to delete appointment");
      }
    }

    // Reset states
    setEditingAppointment(null);
    setIsOpenAppointmentForm(false);
  };

  const handleDialogConfirm = async () => {
    setIsDialogOpen(false);
    const profile = await fetchProfile({ user_token: user_token });
    setUser(profile);
    navigate("/d/MyProfile");
  };

  const currentViewNameChange = (currentViewName) => {
    setCurrentViewName(currentViewName);
  };

  const handleSpeedDialClick = (actionName) => {
    if (actionName === "Add Event") {
      const newAppointment = {
        startDate: new Date(),
        endDate: new Date(new Date().getTime() + 60 * 60 * 1000),
        title: "",
        customField: "",
      };
      setEditingAppointment(newAppointment);
      setIsOpenAppointmentForm(true);
      setIsNewAppointment(true);
    }
  };

  const Appointment = ({ data, ...restProps }) => {
    const backgroundColor = data.isBookedMentor
      ? "#82caaf"
      : data.isMentorSession
      ? "#b676b1"
      : undefined;

    const cursor =
      data.isBookedMentor || data.isMentorSession ? "not-allowed" : "pointer";

    return (
      <Appointments.Appointment
        {...restProps}
        data={data}
        style={{
          ...restProps.style,
          backgroundColor,
          cursor,
        }}
      />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isDialogOpen}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Update Professional Details"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You should update your professional details.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogConfirm} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "40px",
          "& .MuiFab-primary": {
            backgroundColor: "secondary.main",
            color: "secondary.contrastText",
          },
        }}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
      >
        {actions?.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleSpeedDialClick(action.name)}
          />
        ))}
      </SpeedDial>

      <Box height={"80vh"} sx={{ marginLeft: "15px" }}>
        <Scheduler data={combinedData} height="100%">
          <ViewState
            defaultCurrentDate={new Date().toISOString()}
            currentViewName={currentViewName}
            onCurrentViewNameChange={currentViewNameChange}
          />
          <WeekView startDayHour={7} endDayHour={20} />
          <WeekView
            name="work-week"
            displayName="Work Week"
            excludedDays={[0, 6]}
            startDayHour={8}
            endDayHour={19}
          />
          <MonthView />
          <DayView startDayHour={0} endDayHour={24} />
          <Toolbar />
          <ViewSwitcher />
          <Appointments appointmentComponent={Appointment} />
          <DateNavigator />
          <EditingState
            onCommitChanges={commitChanges}
            editingAppointment={editingAppointment}
            onEditingAppointmentChange={setEditingAppointment}
          />
          <IntegratedEditing />
          <TodayButton />
          <AppointmentTooltip showCloseButton showOpenButton />
          <AllDayPanel />
          <DragDropProvider allowDrag={allowDrag} />
          <AppointmentForm
            basicLayoutComponent={BasicLayout}
            visible={isOpenAppointmentForm}
            onVisibilityChange={(visible) => setIsOpenAppointmentForm(visible)}
          />
          <ConfirmationDialog />
          <CurrentTimeIndicator />
        </Scheduler>
      </Box>
    </ThemeProvider>
  );
};

export default SchedulerComponent;
