import axios from "axios";
import network_conn from "../utils/network_config";

const process_events = async (api_events) => {
  let zus_event = [];
  
  for (let i = 0; i < api_events.data.length; i++) {
    const event = api_events.data[i];

    // Check if session_details is null
    if (!event.session_details) {
      console.warn(`Skipping event with missing session_details:`, event);
      continue; // Skip this iteration if session_details is null
    }

    try {
      const utcDate = new Date(event.session_details.session_start_date);
      const offsetMinutes = utcDate.getTimezoneOffset();
      const localTimestamp = new Date(utcDate.getTime() - offsetMinutes * 60000);
      const localDateString = localTimestamp.toISOString();

      const utcDate1 = new Date(event.session_details.session_end_date);
      const offsetMinutese = utcDate1.getTimezoneOffset();
      const localTimestamp1 = new Date(utcDate1.getTime() - offsetMinutese * 60000);
      const localDateString1 = localTimestamp1.toISOString();

      zus_event.push({
        id: event.session_details.meeting_id,
        title: event.session_details.session_title || "Untitled Event",
        startDate: localDateString,
        endDate: localDateString1,
        AllDay: "",
        notes: event.session_details.session_desc || "No description available",
        customField: event.location_details?.location_name || "Unknown Location",
        InviteMentee: "",
      });
    } catch (error) {
      console.error(`Error processing event at index ${i}:`, error);
    }
  }
  
  return zus_event;
};


// Function to fetch all appointments
export const fetchAppointments = async (fetchreq) => {
  console.log(fetchreq);
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/fetch_events`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    const appointments = response.data;
    const zus_event = await process_events(appointments);
    console.log(zus_event);
    return zus_event;
  } catch (error) {
    console.error("Error fetching appointments:", error.message);
    return [];
  }
};

// Function to add a new appointment
export const addAppointment = async (newAppointment) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/insert_event`,
      newAppointment,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding appointment:", error.message);
    return null;
  }
};

// Function to update an existing appointment
export const updateAppointment = async (updatedAppointment) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/update_events`,
      updatedAppointment,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating appointment:", error.message);
    return null;
  }
};

// Function to delete an appointment
export const deleteAppointment = async (deleteAppointment) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/delete_event`,
      deleteAppointment,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting appointment:", error.message);
    return false;
  }
};
