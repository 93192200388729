import { React, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Badge from "@mui/material/Badge";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import { display, flexbox } from "@mui/system";
import { Stack } from "@mui/material";
import {
  fetch_progress_request,
  update_progress_request,
} from "../../api/progress_tracker";
import Cookies from 'js-cookie';
const menteesData = [
  {
    name: "Irshad Alam",
    image: "/static/images/avatar/1.jpg",
    chips: [
      { label: "consultancy", badgeCount: 3 },
      { label: "Senior Lead", badgeCount: 7 },
      { label: "Management", badgeCount: 4 },
      { label: "Network", badgeCount: 3 },
    ],
    lastSession: "12/07/2024 18:24",
    bookingInfo: {
      totalSessions: 3,
      currentLevel: 4,
      desiredLevel: 6,
      desiredSkill: "management",
      currentRole: "Junior Developer",
      bookingDate: "12/07/2024",
    },
  },
  {
    name: "Vimal",
    image: "/static/images/avatar/1.jpg",
    chips: [
      { label: "consultancy", badgeCount: 3 },
      { label: "Senior Lead", badgeCount: 7 },
      { label: "Management", badgeCount: 4 },
      { label: "Network", badgeCount: 3 },
    ],
    lastSession: "12/07/2024 18:24",
    bookingInfo: {
      totalSessions: 3,
      currentLevel: 4,
      desiredLevel: 6,
      desiredSkill: "management",
      currentRole: "Junior Developer",
      bookingDate: "12/07/2024",
    },
  },
  {
    name: "Aman Gadwal",
    image: "/static/images/avatar/1.jpg",
    chips: [
      { label: "consultancy", badgeCount: 3 },
      { label: "Senior Lead", badgeCount: 7 },
      { label: "Management", badgeCount: 4 },
      { label: "Network", badgeCount: 3 },
    ],
    lastSession: "12/07/2024 18:24",
    bookingInfo: {
      totalSessions: 3,
      currentLevel: 4,
      desiredLevel: 6,
      desiredSkill: "management",
      currentRole: "Junior Developer",
      bookingDate: "12/07/2024",
    },
  },
  // Add two more mentee objects here with different data
];

function MenteeList() {
  const [open, setOpen] = useState(false);
  const [upgrade_request, setUpgrade_request] = useState([]);
  const [selectedMentee, setSelectedMentee] = useState(null);
  const [skillLevel, setSkillLevel] = useState(0);
  const user_token = Cookies.get("connectedMe")

  const handleOpen = (request) => {
    setSelectedMentee(request);
    setSkillLevel(request.desired_level);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const handleUpgrade = async () => {
    setOpen(false);
    try {
      await update_progress_request({
        user_token: user_token,
        status: "approved",
        employee_id: selectedMentee.employee_id,
        desired_skill: selectedMentee.desired_skill,
        desired_level: skillLevel,
      });
      fetch_progress_request_Data();
    } catch (error) {
      console.error("Failed to save profile data:", error);
    }
  };
  const increaseLevel = () => setSkillLevel((prev) => prev + 1);
  const decreaseLevel = () =>
    setSkillLevel((prev) => (prev > 1 ? prev - 1 : prev));

  useEffect(() => {
    fetch_progress_request_Data();
  }, []);

  const fetch_progress_request_Data = async () => {
    try {
      const response = await fetch_progress_request({
        user_token: user_token,
      });
      setUpgrade_request(response.data || []);
      console.log("response", response.data);
    } catch (error) {
      console.error("Error fetching recommended mentors:", error);
    }
  };

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" component="h5" sx={{ fontWeight: "bold" }}>
          Meet the Mentees Who’ve Chosen to Grow with You
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" sx={{ mt: 1 }}>
          Connect and help them achieve their goals
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"row"} gap={4} flexWrap={"wrap"}>
        {upgrade_request.length > 0 ? (
          upgrade_request.map((request, index) => (
            <Card
              key={index}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                width: 600,
                maxWidth: "100%",
                overflow: "hidden",
                textAlign: "center",
                mb: 2,
                border: "1px solid black",
              }}
            >
              <Box
                sx={{
                  bgcolor: "primary.main",
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 280,
                  p: 2,
                }}
              >
                <Card
                  sx={{
                    width: 250,
                    maxWidth: "100%",
                    boxShadow: 3,
                    textAlign: "center",
                  }}
                >
                  <CardContent>
                    <Avatar
                      src={request.image}
                      sx={{ width: 64, height: 64, margin: "0 auto" }}
                    />
                    <Typography variant="h6">
                      {request.first_name} {request.last_name}
                    </Typography>
                    {request.skills.map((skill, i) => (
                      <Badge
                        key={i}
                        badgeContent={skill.skill_level}
                        color="error"
                        sx={{
                          "& .MuiBadge-badge": {
                            right: 7,
                            top: 7,
                            border: `2px solid white`,
                            padding: "0 2px",
                          },
                        }}
                      >
                        <Chip
                          label={skill.skill_name}
                          sx={{
                            margin: 1,
                            fontSize: "0.75rem",
                            height: "24px",
                            "& .MuiChip-label": { padding: "0 8px" },
                          }}
                        />
                      </Badge>
                    ))}
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: 400,
                        opacity: 0.7,
                        mt: 2,
                      }}
                    >
                      {/* Last Session: {mentee.lastSession} */}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      color='secondary'
                      size="small"
                      fullWidth
                      onClick={() => handleOpen(request)}
                    >
                      Upgrade Level
                    </Button>
                  </CardActions>
                </Card>
              </Box>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  minWidth: 250,
                }}
              >
                <CardMedia
                  component="img"
                  image="https://static.vecteezy.com/system/resources/previews/006/409/485/original/people-thinking-to-make-decision-problem-solving-and-find-creative-ideas-with-question-mark-in-flat-cartoon-background-for-poster-illustration-vector.jpg"
                  alt=""
                  sx={{ height: 120, objectFit: "contain" }}
                />
                <Typography variant="h5" gutterBottom>
                  Booking Info:
                </Typography>
                <Box
                  sx={{ mt: 1.5, display: "flex", justifyContent: "center" }}
                >
                  <List>
                    <ListItem>
                      <Typography variant="body2">
                        <strong>Current Level:</strong>{" "}
                        {request.skills.find(
                          (skill) => skill.skill_name === request.desired_skill
                        )?.skill_level || "N/A"}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body2">
                        <strong>Desired Level:</strong> {request.desired_level}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body2">
                        <strong>Desired Skill:</strong> {request.desired_skill}
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              </CardContent>
            </Card>
          ))
        ) : (
          <Card
            sx={{
              width: 600,
              maxWidth: "100%",
              textAlign: "center",
              mb: 2,
              border: "1px solid black",
            }}
          >
            <CardContent>
              <Typography variant="h6">No Upgrade Requests</Typography>
              <Typography variant="body2">
                It seems there are no upgrade requests at the moment.
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
      {/* Modal for upgrading desired skill level */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Centers content horizontally
            textAlign: "center", // Centers text within each element
          }}
        >
          <Avatar src={selectedMentee?.image}></Avatar>
          <Typography gutterBottom>
            {selectedMentee?.first_name} {selectedMentee?.last_name}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Update Skill Level
          </Typography>
          <Typography variant="subtitle1">
            {selectedMentee?.desired_skill}: {skillLevel}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 2 }}>
            <IconButton onClick={decreaseLevel} aria-label="decrease level">
              <ArrowDownwardIcon />
            </IconButton>
            <Typography variant="h6">{skillLevel}</Typography>
            <IconButton onClick={increaseLevel} aria-label="increase level">
              <ArrowUpwardIcon />
            </IconButton>
          </Box>
          <Box
            sx={{ display: "flex", gap: 2, mt: 3, justifyContent: "center" }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUpgrade}
            >
              Upgrade
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default MenteeList;
