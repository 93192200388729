import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  DialogActions,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { purple } from "@mui/material/colors";

const SkillPopup = ({ x, y, skill_name, open, onClose }) => {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#2f3348",
    "&:hover": {
      backgroundColor: "#2f3348",
    },
  }));

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", top: 8, right: 8 }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        {x > 0 ? (
          <>
            <Typography variant="h6" color="textPrimary">
            Complete {x} level{x > 1 ? "s" : ""}  to master "{skill_name}".
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ marginTop: "16px" }}
            >
              We have recommended mentors and courses to level up your skill.
            </Typography>
          </>
        ) : (
          <Typography variant="h6" color="textPrimary">
            Congratulations! 🎉 <br />
            You have completed this skill.
          </Typography>
        )}
      </DialogContent>

      {x > 0 && (
        <DialogActions>
          <Stack
            spacing={2}
            direction="row"
            sx={{ justifyContent: "center", width: "100%" }}
          >
            <Link to={`/d/UserRecom`}>
              <Button variant="contained" color="secondary" size="small">
                View Mentors
              </Button>
            </Link>
            <Link to={`/d/UserRecom`}>
              <Button variant="outlined" color="secondary" size="small">
                View Courses
              </Button>
            </Link>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SkillPopup;
