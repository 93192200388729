import React from 'react';
import { Typography, Stack, Tooltip } from '@mui/material';
import PlanPath from '../components/CareerPath/PlanPath';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function UserPlanPath() {
    return (
        <>
            <Stack>
                <Stack sx={{ display: "flex", flexDirection: "row" ,margin: '20px 0 0 20px' }}>
                    <Typography variant="h4" >
                        Career Plan
                    </Typography>
                    <Tooltip sx={{ml:"10px"}} title="Explore the career path page to track your monthly progress and measure your skill development over time."><InfoOutlinedIcon fontSize="small" /></Tooltip>
                </Stack>
                <Typography variant="subtitle2" sx={{ margin: '0 0 20px 20px', color: 'grey' }}>
                    Forge Your Career Path: Navigate Opportunities and Reach New Heights
                </Typography>
            </Stack>
            <PlanPath />
        </>
    )
}

export default UserPlanPath;