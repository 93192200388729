import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Button, Container, Box, TextField, Paper, Stack, Typography, Divider, Alert, AlertTitle } from '@mui/material'
import React, { Component, useEffect, useState } from 'react'
import { postFeedback, fetchTicket, fetchUrl, checkFeedback } from '../../api/session_service';
import { useNavigate, useParams } from 'react-router';


const Feedback = () => {
  const [attendSession, setAttendSession] = useState(true);
  const [showticket, setShowticket] = useState(false);
  const [showSatisfactionQuestions, setShowSatisfactionQuestions] = useState(false);
  const [showWhyNotAttend, setShowWhyNotAttend] = useState(false);
  const [discription, setDiscription] = useState('');
  const [reason, setReason] = useState('');
  const [rating, setRating] = useState('')
  const [url, setURL] = useState(window.location.href);
  const [meetexpectations, setMeetexpectations] = useState(false);
  const [mentorAvailable, setMentorAvailable] = useState(false);
  const [helpfullsession, setHelpfullsession] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState(false);

  const { meeting_id, email } = useParams();
  useEffect(() => {
    const URLObj = new URL(url);
    console.log(URLObj);

    const meetingId = URLObj.searchParams.get('meeting_id');
    const email = URLObj.searchParams.get('email');
    console.log(meetingId);
    console.log(email);
    const getURL = async () => {
      try {
        const check_status = await checkFeedback({
          meeting_id :meetingId,
          email : email
        });
        if (check_status["message"] == "You have already submitted your feedback.") {
          setFeedbackStatus(true);
        }
        // const response = await fetchUrl();
        // console.log(response);

        // if (response && response.length > 0) {

        //   // window.location.href = `http://localhost:3000/mentee-feedback/${meetingId}/${email}`

        // } else {
        //   console.error('Error fetching URL:', response.statusText);
        // }
      } catch (error) {
        console.error('Error fetching URL:', error);
      }
    };
    if (meetingId && email) {
      getURL();
    }
  }, [meeting_id, email]);

  const handleRadioChange = (value) => {
    if (value === 'Yes') {
      setAttendSession(true);
      setShowSatisfactionQuestions(true);
    } else {
      setAttendSession(false);
      setShowWhyNotAttend(true);
    }
  };

  const handleSatisfactioChange = (event) => {
    const value = event.target.value;
    let newStatus;

    switch (value) {
      case 'Very satisfied':
        newStatus = 'VS';
        break;
      case 'Somewhat satisfied':
        newStatus = 'SS';
        break;
      case 'Neutral':
        newStatus = 'N';
        break;
      case 'Disappointed':
        newStatus = 'D';
        break;
      default:
        newStatus = '';
    }

    setRating(newStatus);
  };




  const getFeedback = async () => {
    // const discrip ={
    //   session_attended: c,
    //   meet_expectations: meetexpectations,
    //   mentor_available: mentorAvailable,
    //   hempful: helpfullsession
    // }
    const feedbackresponse = await postFeedback({
      email: email,
      session_attended: attendSession,
      meeting_id: meeting_id,
      discription: discription,
      meeting_rating: rating,
      ticket_desc: reason,
    })
    console.log(feedbackresponse)
    if (feedbackresponse["message"] == "You have already submitted your feedback.") {
      setFeedbackStatus(true);
    }
    console.log(feedbackresponse)
  };




  return (
    <Container sx={{ height: '100%' }}>
      <Paper sx={{ margin: '50px' }}>
        <Stack sx={{ backgroundColor: '#2f3348' }}>
          <Typography variant="h4" sx={{ margin: "20px 0 0 20px", color: "white" }}>
            Feedback Form
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ margin: "0 0 20px 20px", color: "rgb(184 181 181 / 87%)" }}
          >
            Form to track Mentor & Employee engagement.
          </Typography>
        </Stack>
        <Divider />
        {feedbackStatus ? (
          <>
            <Alert severity="success">
              <AlertTitle>Form Submitted Successully.  </AlertTitle>
              
            </Alert>
          </>
        ) : (
          <>
            <FormControl sx={{ margin: '10px', padding: '50px' }}>
              <FormLabel>1. Did you Attend the Session?</FormLabel>
              <RadioGroup
                onChange={(e) => handleRadioChange(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
              {attendSession && showSatisfactionQuestions && (
                <>
                  <FormControl>
                    <FormLabel margin='10px'>2. How satisfied were you with the overall content and structure of the session?</FormLabel>
                    <RadioGroup>
                      <FormControlLabel value="Very satisfied" control={<Radio onChange={handleSatisfactioChange} />} label="Very satisfied" />
                      <FormControlLabel value="Somewhat satisfied" control={<Radio onChange={handleSatisfactioChange} />} label="Somewhat satisfied" />
                      <FormControlLabel value="Neutral" control={<Radio onChange={handleSatisfactioChange} />} label="Neutral" />
                      <FormControlLabel value="Disappointed" control={<Radio onChange={handleSatisfactioChange} />} label="Disappointed" />

                    </RadioGroup>
                    <FormLabel margin='10px'>3. Did the session meet your expectations?</FormLabel>
                    <RadioGroup onClick={(event) => setMeetexpectations(event.target.value)}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormLabel margin='10px'>4. Were the materials and resources provided helpful?</FormLabel>
                    <RadioGroup onClick={(event) => setMentorAvailable(event.target.value)}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormLabel margin='10px'>5. Was the mentor available and responsive to your questions?</FormLabel>
                    <RadioGroup onClick={(event) => setHelpfullsession(event.target.value)}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormLabel margin='10px'>6. You want to raise a ticket?</FormLabel>
                    <RadioGroup>
                      <FormControlLabel value="Yes" control={<Radio onClick={() => setShowticket(true)} />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio onClick={() => setShowticket(false)} />} label="No" />
                    </RadioGroup>
                    {showticket && (<>
                      <div>
                        <p>7. Reason?</p>
                        <Box direction={"row"} sx={{ backgroundColor: 'white', borderRadius: 4 }}>
                          <TextField
                            multiline
                            rows={4}
                            fullWidth
                            variant="standard"
                            name='Reason'
                            value={reason}
                            onChange={(event) => setReason(event.target.value)}
                          />
                        </Box>

                      </div>
                    </>)}
                  </FormControl>
                </>
              )}
              {!attendSession && showWhyNotAttend && (
                <div>
                  <p>2. Why didn't you attend the session?</p>
                  <Box direction={"row"} sx={{ backgroundColor: 'white', borderRadius: 4 }}>
                    <TextField
                      multiline
                      rows={4}
                      fullWidth
                      variant="standard"
                      name='Reason'
                      value={reason}
                      onChange={(event) => setDiscription(event.target.value)}
                    // value={formData.description}
                    // onChange={handleChange}
                    //   value={}
                    //   onChange={}
                    />
                  </Box>
                </div>
              )}
              <Stack display={'flex'} alignItems={'center'} justifyContent={'center'} margin={'50px'}>
                <Button variant="contained" onClick={getFeedback}>Submit</Button>
              </Stack>

            </FormControl>
          </>
        )}

      </Paper>
    </Container>
  )
}

export default Feedback
