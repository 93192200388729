import {
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  Divider,
  Paper,
  IconButton,
  Avatar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Rating,
  Box,
  Alert,
  Snackbar,
} from "@mui/material";

import * as React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchAppointments } from "../../api/scheduler_related_services";
import userSess from "../../store/user_session_control";
import useProfile from "../../store/profile_data";
import { addProfile } from "../../api/profile_related_service";
import { useContext, useState } from "react";
import {
  AttachMoneyOutlined,
  ConfirmationNumberOutlined,
  EventNoteOutlined,
  GroupsOutlined,
  ModeEdit,
  PermIdentityOutlined,
  PersonAddOutlined,
  TrafficOutlined,
  TrendingUpOutlined,
} from "@mui/icons-material";
import network_conn from "../../utils/network_config.json";
import { useOutletContext } from "react-router-dom";
import { ThemeContext } from "../../index";
import { insertCustomizeTheme } from "../../api/customize_theme";
import AlertPopup from "../Common_Components/AlertPopup";
import Cookies from 'js-cookie';

const Admin = () => {
  const { user, setUser } = useProfile();
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState(user);
  const [originalUser, setOriginalUser] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [alert, setAlert] = useState(false);
  const { setTheme } = useContext(ThemeContext);
  const [themeColor, setLocalThemeColor] = useState("#0080ff");
  const [buttonColor, setButtonColor] = useState("#1976d2");
  const [textColor, setTextColor] = useState("#000000");
  const [errorMessage, setErrorMessage] = useState("");
  const user_token = Cookies.get("connectedMe")
  const handleApplyTheme = async () => {
    if (
      /^#([0-9A-F]{3}){1,2}$/i.test(themeColor) &&
      /^#([0-9A-F]{3}){1,2}$/i.test(buttonColor) &&
      /^#([0-9A-F]{3}){1,2}$/i.test(textColor)
    ) {
      setTheme({
        themeColor,
        buttonColor,
        textColor,
      });
      try {
        await insertCustomizeTheme({
          user_token: user_token,
          theme_color: themeColor,
          button_color: buttonColor,
          text_color: textColor,
        });
        setShowSnackbar({
          open: true,
          success: true,
          message: "Theme applied successfully!",
        });
      } catch (error) {
        setShowSnackbar({
          open: true,
          success: false,
          message: "Failed to apply theme. Please try again.",
        });
      }
    } else {
      setErrorMessage("One or more colors are invalid. Please try again.");
    }
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      // alert("Please select a file first");
      setAlert("true");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await fetch(network_conn.api_auth_endpoint + "upload", {
        method: "POST",
        body: formData,
        headers: {
          // 'Content-Type': 'multipart/form-data' // Fetch API automatically sets the correct headers for FormData
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      setShowSnackbar("true");
      // alert("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file", error);
      // alert("Failed to upload file");
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setOriginalUser({ ...editedUser });
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedUser(originalUser);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    setUser(editedUser);
    setOriginalUser(editedUser);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };
  const handleAlert = () => {
    setAlert(true);
  };

  return (
    <container>
      <Stack marginLeft={5}>
        <Typography variant="h4" sx={{ margin: "20px 0 0 0px" }}>
          Admin Dashboard
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            p: 2,
          }}
        >
          <Typography variant="h6">Customize Theme</Typography>

          <TextField
            label="Theme Color"
            type="color"
            value={themeColor}
            onChange={(e) => setLocalThemeColor(e.target.value)}
            helperText="Choose a color for the theme"
            sx={{ maxWidth: 300, width: "100%" }}
          />

          <TextField
            label="Button Color"
            type="color"
            value={buttonColor}
            onChange={(e) => setButtonColor(e.target.value)}
            helperText="Choose a color for buttons"
            sx={{ maxWidth: 300, width: "100%" }}
          />

          <TextField
            label="Text Color"
            type="color"
            value={textColor}
            onChange={(e) => setTextColor(e.target.value)}
            helperText="Choose a color for text"
            sx={{ maxWidth: 300, width: "100%" }}
          />

          <Button
            variant="contained"
            onClick={handleApplyTheme}
            color="secondary"
          >
            Apply Theme
          </Button>

          {errorMessage && (
            <Snackbar
              open
              autoHideDuration={6000}
              onClose={() => setErrorMessage("")}
              message={errorMessage}
            />
          )}
        </Box>
      </Stack>

      <Stack display={"flex"} flexDirection={"row"} margin={5}>
        <Stack
          display={"flex"}
          flexDirection={"column"}
          spacing={4}
          marginRight={5}
        >
          <Paper
            sx={{
              background: "#FAF9F6",
              width: "500px",
              height: "380px",
              marginRight: "40px",
              marginLeft: "30px",
            }}
          >
            <Stack marginLeft={2} marginTop={2}>
              <Stack item xs={12}>
                <Typography variant="h5" gutterBottom>
                  <PermIdentityOutlined fontSize="medium" /> Personal
                  Information
                </Typography>
              </Stack>
              <Divider />
              <Stack direction={"column"} spacing={2}>
                <Stack direction={"row"}>
                  <Stack marginTop={2}>
                    <Typography>Full Name:</Typography>
                  </Stack>
                  <Stack marginLeft="20px">
                    <TextField
                      label="Admin"
                      // value={firstName}
                      onChange={(e) => e.target.value}
                      variant="standard"
                      size="small"
                      disabled
                    />
                  </Stack>
                </Stack>

                <Stack direction={"row"}>
                  <Stack marginTop={2}>
                    <Typography>Emails:</Typography>
                  </Stack>
                  <Stack marginLeft="45px">
                    <TextField
                      label="Admin@gmail.com"
                      // value={email}
                      onChange={(e) => e.target.value}
                      variant="standard"
                      size="small"
                      disabled
                    />
                  </Stack>
                </Stack>

                <Stack direction={"row"}>
                  <Stack marginTop={2}>
                    <Typography>Contact:</Typography>
                  </Stack>
                  <Stack marginLeft="35px">
                    <TextField
                      label="9999999999"
                      // value={phoneNumber}
                      onChange={(e) => e.target.value}
                      variant="standard"
                      size="small"
                      disabled
                    />
                  </Stack>
                </Stack>

                <Stack direction={"row"}>
                  <Stack marginTop={2}>
                    <Typography>D O B:</Typography>
                  </Stack>
                  <Stack marginLeft="45px" marginTop={1}>
                    <TextField
                      disabled
                      type="Date"
                      onChange={(e) => e.target.value}
                      variant="standard"
                      size="small"
                    />
                  </Stack>
                </Stack>

                <Stack direction={"row"}>
                  <Stack marginTop={2}>
                    <Typography>Location:</Typography>
                  </Stack>
                  <Stack marginLeft="35px">
                    <TextField
                      label="Location"
                      // value={phoneNumber}
                      onChange={(e) => e.target.value}
                      variant="standard"
                      size="small"
                      disabled
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Paper>

          <Paper
            sx={{
              background: "#FAF9F6",
              width: "500px",
              height: "230px",
              marginRight: "40px",
              marginLeft: "30px",
            }}
          >
            <Stack marginLeft={2} marginTop={2}>
              <Typography variant="h5" gutterBottom>
                <TrendingUpOutlined fontSize="medium" /> Sales.
              </Typography>
            </Stack>

            <Divider />
            <Stack
              padding={5}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Stack alignItems={"center"}>
                <PersonAddOutlined fontSize="large" />
                <Typography
                  fontSize="small"
                  fontWeight="medium"
                  color={"green"}
                >
                  1,568,753 &#8593;
                </Typography>
                <Typography>New Clients</Typography>
              </Stack>
              <Stack alignItems={"center"}>
                <TrafficOutlined fontSize="large" />
                <Typography
                  fontSize="small"
                  fontWeight="medium"
                  color={"green"}
                >
                  1,568,753 &#8593;
                </Typography>
                <Typography>Traffic recieved</Typography>
              </Stack>
              <Stack alignItems={"center"}>
                <AttachMoneyOutlined fontSize="large" />
                <Typography
                  fontSize="small"
                  fontWeight="medium"
                  color={"green"}
                >
                  $1,568,753 &#8593;
                </Typography>
                <Typography>Sales recieved</Typography>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
        <Stack display={"flex"} flexDirection={"column"} spacing={5}>
          <Paper
            sx={{ background: "#FAF9F6", width: "800px", height: "300px" }}
          >
            <Stack marginLeft={2} marginTop={2}>
              <Typography variant="h5" gutterBottom>
                <EventNoteOutlined fontSize="medium" /> Total Session Info.
              </Typography>
            </Stack>

            <Divider />
            <Stack
              flexDirection={"row"}
              justifyContent={"space-evenly"}
              marginTop={4}
            >
              <Card
                sx={{
                  background: "#dfece0",
                  height: "11rem",
                  width: "20rem",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Stack>
                    <Typography fontSize="small" fontWeight="medium">
                      Total Session Attendant
                    </Typography>
                  </Stack>
                  <Typography variant="h1" color="#4A6771">
                    5
                  </Typography>
                  <Typography fontSize="small">
                    <b>From:</b>0/0/0000 <b>To:</b>0/0/0000
                  </Typography>
                </CardContent>
              </Card>

              <Card
                sx={{
                  background: "#e2edf9",
                  height: "11rem",
                  width: "20rem",
                  margin: "10",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Stack>
                    <Typography fontSize="small" fontWeight="medium">
                      Total Session Scheduled
                    </Typography>
                  </Stack>
                  <Typography variant="h1" color="#4A6771">
                    5
                  </Typography>
                  <Typography fontSize="small">
                    <b>From:</b>0/0/0000 <b>To:</b>0/0/0000
                  </Typography>
                </CardContent>
              </Card>
            </Stack>
          </Paper>
          <Paper
            sx={{ background: "#FAF9F6", width: "800px", height: "300px" }}
          >
            <Stack marginLeft={2} marginTop={2}>
              <Typography variant="h5" gutterBottom>
                <GroupsOutlined fontSize="medium" /> Total Users Info.
              </Typography>
            </Stack>

            <Divider />
            <Stack
              flexDirection={"row"}
              justifyContent={"space-evenly"}
              marginTop={4}
            >
              <Card
                sx={{
                  background: "#FFE3F1",
                  height: "11rem",
                  width: "20rem",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Stack>
                    <Typography fontSize="small" fontWeight="medium">
                      Total Mentore
                    </Typography>
                  </Stack>
                  <Typography variant="h1" color="#4A6771">
                    5
                  </Typography>
                </CardContent>
              </Card>

              <Card
                sx={{
                  background: "#F6C4BA",
                  height: "11rem",
                  width: "20rem",
                  margin: "10",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Stack>
                    <Typography fontSize="small" fontWeight="medium">
                      Total Mentee
                    </Typography>
                  </Stack>
                  <Typography variant="h1" color="#4A6771">
                    5
                  </Typography>
                </CardContent>
              </Card>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
      <Stack margin={2}>
        <Paper
          sx={{
            background: "#FAF9F6",
            width: "1300px",
            height: "300px",
            marginRight: "40px",
            marginLeft: "30px",
          }}
        >
          <Stack marginLeft={2} marginTop={2}>
            <Typography variant="h5" gutterBottom>
              <ConfirmationNumberOutlined fontSize="medium" /> Total Tickets.
            </Typography>
          </Stack>

          <Divider />
          <Stack>
            <Table>
              <TableHead sx={{ backgroundColor: "#2f3348" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Mentor Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Mode
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Skill Match
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Rating
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Total Complain
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    Rohit
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    Android Developer
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    WFH
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    Building Android base apps
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    Java, OOP's
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    <Rating
                      name="half-rating-read"
                      defaultValue={2.5}
                      precision={0.5}
                      readOnly
                    />
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    10
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </Paper>
      </Stack>
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "300px",
          width: "1300px",
          ml: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#FAF9F6",
            width: "100%",
            // height: "380px",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #e0e0e0",
            padding: "20px", // Optional: Adds padding for better spacing inside the box
            // ml: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Create User Accounts from Excel
          </Typography>
          <Typography variant="caption" color="textSecondary" sx={{ mb: 1 }}>
            * The Excel file should contain columns named 'first_name',
            'last_name', and 'email'.
          </Typography>
          <TextField
            type="file"
            onChange={handleFileChange}
            inputProps={{ accept: ".xlsx, .xls" }}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="secondary" onClick={handleUpload}>
            Upload
          </Button>
        </Box>
        <AlertPopup timer={3000} open={showSnackbar} onClose={handleSnackbarClose} type="success" message={"Accounts Created Successfully!!"}/>
        <AlertPopup timer={2000} open={alert} onClose={handleAlertClose} type="error" message={"Please upload file!!"}/>
        
      </Stack>
    </container>
  );
};

export default Admin;
