import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { styled } from "@mui/system";
import { useNavigate } from "react-router";
import NotificationIcon from "@mui/icons-material/Notifications";
import InfoIcon from "@mui/icons-material/Info";
import {
  deleteUserMentSession,
  addNotification,
} from "../../api/session_service";
import userSess from "../../store/user_session_control";
import useUserMentorSess from "../../store/user_mentor_session";
import { RescheduleMailResponse } from "../../api/notification_related_service";
import SessionCard from "../Common_Components/sessionCard";
import EmptyCard from "../Common_Components/emptyCard";
import Cookies from 'js-cookie';

function CardGridView({ searchTerm }) {
  const navigate = useNavigate();
  const user_token = Cookies.get("connectedMe")
  const [sessions, setSessions] = useState([]);
  const { sess_men } = useUserMentorSess();
  const [filteredSessions, setFilteredSessions] = useState([]);
  useEffect(() => {
    setSessions(sess_men);
  }, [sess_men]);

  const [selectedSession, setSelectedSession] = useState(null);
  const [open, setOpen] = useState(false);

  const [openReasonDialog, setOpenReasonDialog] = useState(false);
  const [unbookReason, setUnbookReason] = useState("");
  const [sessionToUnbook, setSessionToUnbook] = useState(null);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = useState("xs");
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const results = sessions?.filter((session) =>
      session.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSessions(results);
  }, [searchTerm, sessions]);

  const handleAccept = (meetingId) => {
    addNotification({ meeting_id: meetingId, token: user_token, is_ack: true });
    RescheduleMailResponse({
      meeting_id: meetingId,
      token: user_token,
      response_message: "accepted",
    });
    handleClose();
  };
  const handleDecline = (meetingId) => {
    addNotification({ meeting_id: meetingId, token: user_token, is_ack: true });
    RescheduleMailResponse({
      meeting_id: meetingId,
      token: user_token,
      response_message: "declined",
    });
    handleClose();
  };

  const handleCardAction = () => {
    navigate("cardD", { state: { name: "Session" } });
  };

  const handleUnbookRequest = (session) => {
    setSessionToUnbook(session);
    setOpenReasonDialog(true);
  };

  const handleUnbookConfirm = async () => {
    if (sessionToUnbook) {
      await deleteUserMentSession({
        meeting_id: sessionToUnbook.meeting_id,
        token: user_token,
        reason: unbookReason,
      });
      setSessions((prevSessions) =>
        prevSessions.filter(
          (session) => session.meeting_id !== sessionToUnbook.meeting_id
        )
      );
      setOpenReasonDialog(false);
      setUnbookReason("");
    }
  };

  const NotificationContainer = styled(Box)(({ theme }) => ({
    height: "32px",
  }));

  const convertToLocalDate = (utrDateString) => {
    const utcDate = new Date(utrDateString);
    const localDate = utcDate.toLocaleDateString();
    const localTime = utcDate.toLocaleTimeString();
    return `${localDate} ${localTime}`;
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "1400px",
          margin: "0 auto",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "16px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {filteredSessions?.length===0 &&(
            <EmptyCard
          message="No Mentor Session Available"
          icon={
            <PeopleIcon
              sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
            />
          }
        />
          )}
          {filteredSessions?.map((s) => (
            <SessionCard
              key={s.meeting_id}
              title={s.title}
              meetingId={s.meeting_id}
              description={s.Desc}
              startDate={s.startDate}
              handleBook={() => handleUnbookRequest(s)}
              author={s.Session_author}
              jobTitle={s.Session_auth_job_title}
              image={s.image}
              buttonLabel="UNBOOK"
              hasNotification={s.has_notification}
              onNotificationClick={() => {
                setSelectedSession(s);
                setOpen(true);
              }}
            />
          ))}
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography
            variant="h6"
            color="error"
          >{`Your session has been rescheduled`}</Typography>
          {selectedSession && (
            <Box textAlign="center" marginTop={2}>
              <Typography>{`Start Date: ${convertToLocalDate(
                selectedSession.updatedStartDate
              )}`}</Typography>
              <Typography>{`End Date: ${convertToLocalDate(
                selectedSession.updatedEndDate
              )}`}</Typography>
            </Box>
          )}
          <Box textAlign="center" marginTop={2}>
            {selectedSession && (
              <>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleDecline(selectedSession.meeting_id)}
                >
                  Decline
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAccept(selectedSession.meeting_id)}
                  style={{ marginLeft: "10px" }}
                >
                  Accept
                </Button>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openReasonDialog}
        onClose={() => setOpenReasonDialog(false)}
      >
        <DialogContent>
          <Typography variant="h6">Reason for Unbooking</Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Reason"
            fullWidth
            variant="standard"
            value={unbookReason}
            multiline
            maxRows={4}
            onChange={(e) => setUnbookReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReasonDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleUnbookConfirm}
            color="secondary"
            disabled={!unbookReason.trim()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CardGridView;
