import axios from "axios";
import network_conn from "../utils/network_config";

export const fetchAchievement = async (achievement) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}achievement/fetch_achievement`,
      achievement,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching course publish:", error.message);
    throw error;
  }
};

export const postAchievement = async (achievement) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}achievement/post_achievement`,
      achievement,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};