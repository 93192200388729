import { React, useEffect, useState } from "react";
import { Divider, Grid, Stack, Typography, Tooltip, } from "@mui/material";
import SearchEle from "../components/Menu/searchComp";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CourseCard from "../components/Common_Components/courseCard";
import { sendMentorBookingMail } from "../api/mail_related_service";
import RecommendedMentor from "../components/user_rec/recom_mentor";
import {
  Book_mentor,
  unbookMentors,
  fetchRecommendedMentors,
  Book_courses,
  Edit_course_status,
  fetchRecommendedCourses,
  Get_course_status,
} from "../api/recommendation_related_service";
import {
  fetchCoursePublish,
  DeleteBookedCourse,
} from "../api/course_publish_related_service";
import Cookies from 'js-cookie';

function UserRecom({ searchTerm }) {
  const user_token = Cookies.get("connectedMe")
  const [value, setValue] = useState(0);
  const [mentor, setMentor] = useState([]);
  const [course, setCourse] = useState([]);
  const [mentorUseruuid, setMentorUseruuid] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedMentor, setSelectedMentor] = useState([]);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [publishedCourses, setPublishedCourses] = useState([]);
  const [counter, setCounter] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const get_course_status = async () => {
    try {
      const response = await Get_course_status({ token: user_token });

      if (!Array.isArray(response?.status)) {
        console.error("Invalid response format: 'status' is not an array.");
        return;
      }

      setRecommendedCourses((prevCourses) => {
        const new_courses = [];
        prevCourses?.forEach((course) => {
          const matchingStatus = response?.status?.find(
            (StatusObj) => StatusObj.course_id === course.course_id
          );
          const data = { ...course, courseStatus: "Start Course" };
          if (matchingStatus) {
            data["courseStatus"] = matchingStatus.status;
          }
          new_courses.push(data);
        });
        return new_courses;
      });
    } catch (error) {
      console.error("Error getting the status:", error);
    }
  };

  useEffect(() => {
    fetchCoursesData().then(get_course_status);
  }, [counter]);
  const fetchCoursesData = async () => {
    try {
      // Fetch recommended courses and published courses separately
      const [recommended, published] = await Promise.all([
        fetchRecommendedCourses({ token: user_token }),
        fetchCoursePublish(),
      ]);

      setRecommendedCourses(recommended || []);
      setPublishedCourses(published || []);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const coursesToShow = searchTerm
    ? publishedCourses?.filter((courseData) =>
        courseData.course_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : recommendedCourses;
  const handelBook = async (courseId) => {
    if (user_token) {
      try {
        // Optimistically update the UI
        setRecommendedCourses((prevCourses) =>
          prevCourses?.map((course) =>
            course.course_id === courseId
              ? { ...course, status: "booked", courseStatus: "Start Course" }
              : course
          )
        );

        await Book_courses({ course_id: courseId, token: user_token });
        setOpenSnackbar(true);
      } catch (error) {
        console.error("Error booking the course:", error);
      }
    } else {
      console.error("User token is missing when booking the course.");
    }
  };

  const handleDelete = async (courseId) => {
    if (user_token) {
      try {
        // Optimistically update the UI
        setRecommendedCourses((prevCourses) =>
          prevCourses?.map((course) =>
            course.course_id === courseId
              ? { ...course, status: "unbooked", courseStatus: "Start Course" }
              : course
          )
        );

        await DeleteBookedCourse({ course_id: courseId, token: user_token });

        setSnackbarMessage("Course successfully removed");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error deleting booked course:", error);
        setSnackbarMessage("Failed to remove course");
        setSnackbarOpen(true);
      }
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Stack>
        <Stack>
          <Stack sx={{ display: "flex", flexDirection: "row", margin: '20px 0 0 20px' }}>
            <Typography variant="h4">
              Recommendation
            </Typography>
            <Tooltip sx={{ ml: "10px" }} title="Discover highly recommended picks of mentors and courses tailored to elevate your learning experience and accelerate your growth."><InfoOutlinedIcon fontSize="small" /></Tooltip>
          </Stack>
          <Typography
            variant="subtitle2"
            sx={{ margin: "0 0 20px 20px", color: "grey" }}
          >
            Discover Highly Recommended Picks: Expert-Approved Selections to
            Elevate Your Experience.
          </Typography>
        </Stack>
        <Stack direction={"row"}>
          <SearchEle />
        </Stack>
        <Divider />
        <Stack
          sx={{ margin: "0 0 20px 20px", color: "grey", display: "flex" }}
          direction={"row"}
        >
          <Box>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Mentors" />
              <Tab label="Courses" />
            </Tabs>
          </Box>
        </Stack>
        <Stack sx={{ margin: "20px" }}>
          {value === 0 && <RecommendedMentor />}
          {value === 1 && (
            <Box
              sx={{
                width: "100%",
                maxWidth: "1400px",
                margin: "0 auto",
                padding: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {coursesToShow.map((courseData, index) => (
                  <CourseCard
                    key={index}
                    courseData={courseData}
                    handleDelete={() => {
                      handleDelete(courseData.course_id);
                    }}
                    handelBook={() => {
                      handelBook(courseData.course_id);
                    }}
                    handleClick={() => {
                      handelBook(courseData);
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
}

export default UserRecom;
