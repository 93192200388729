import axios from "axios";
import network_conn from "../utils/network_config";

export const fetchRecommendedMentors = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}recommendation_service/mentor_recommendation`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recommended mentors:", error.message);
    throw error;
  }
};

export const fetchRecommendedCourses = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}recommendation_service/course_recommendation`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recommended courses:", error.message);
    throw error;
  }
};

export const Book_mentor = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}recommendation_service/book_mentor`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error booking mentor:", error.message);
    throw error;
  }
};

export const Book_courses = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}course_service/insert_booked_courses`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error booking courses:", error.message);
    throw error;
  }
};

export const fetchBookedMentors = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}recommendation_service/fetch_booked_mentors`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching booked mentors:", error.message);
    throw error;
  }
};

export const unbookMentors = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}recommendation_service/unbook_mentor`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error unbooking mentor:", error.message);
    throw error;
  }
};

export const Edit_course_status = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}course_service/update_course_status`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error booking courses:", error.message);
    throw error;
  }
};

export const Get_course_status = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}course_service/get_course_status`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error booking courses:", error.message);
    throw error;
  }
};
