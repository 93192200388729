import * as React from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Horizontal_slider.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useUserPrefSess from "../../store/dash_session";
import { addSession } from "../../api/session_service";
import userSess from "../../store/user_session_control";
import useUserMentorSess from "../../store/user_mentor_session";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { Opacity } from "@mui/icons-material";
import AlertPopup from "../Common_Components/AlertPopup";
import CustomCard from "../Common_Components/Card";
import EmptyCard from "../Common_Components/emptyCard";
import SessionCard from "../Common_Components/sessionCard";
import Cookies from 'js-cookie';

function HoriSlide(props) {
  const PreviousBtn = (props) => {
    const { className, onaction } = props;
    return (
      <div className={className} onClick={onaction}>
        <ArrowBackIosNewIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };
  const NextBtn = (props) => {
    const { className, onaction } = props;
    return (
      <div className={className} onClick={onaction}>
        <ArrowForwardIosIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };

  const { sess, setuseUserPrefSess } = useUserPrefSess();
  // const { user_token } = userSess()
  const user_token = Cookies.get("connectedMe")

  const isXLargeScreen = useMediaQuery("(min-width:1500px)");
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery("(max-width:1199px)");
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const slideshow = isMobileScreen
    ? 1
    : isSmallScreen
    ? 2
    : isMediumScreen
    ? 3
    : isLargeScreen
    ? 4
    : 5;
  const [open, setOpen] = React.useState(false);

  const handle_book = async (met_id) => {
    await addSession({ meeting_id: met_id, token: user_token });
    setOpen(true);
  };
  const handleCardRemove = (met_id) => {
    const updatedSess = sess.filter((s) => s.meeting_id !== met_id);
    setuseUserPrefSess(updatedSess);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideshow,
    slidesToScroll: 1,
    prevArrow: (
      <PreviousBtn
        onaction={() => {
          if (props.page === 0) {
            return;
          } else {
            props.setPage(props.page - 1);
          }
        }}
      />
    ),
    nextArrow: (
      <NextBtn
        onaction={() => {
          props.setPage(props.page + 1);
        }}
      />
    ),
    centerMode: isMobileScreen, // Enable center mode to make the next card visible on mobile
    centerPadding: isMobileScreen ? "40px" : "0px", // Add padding only for mobile to show part of the next card
  };

  return (
    <Box
      sx={{
        width: "85vw",
        marginLeft: isMobileScreen ? "0px" : "60px",
      }}
    >
      <Slider {...settings}>
        {sess.length === 0 ? (
          <EmptyCard
            message="Done for the day"
            icon={
              <CheckCircleIcon
                sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
              />
            }
          />
        ) : (
          sess.map((s) => (
            <SessionCard
              key={s.meeting_id}
              title={s.title}
              meetingId={s.meeting_id}
              description={s.Desc}
              startDate={s.startDate}
              handleBook={handle_book}
              handleCardRemove={handleCardRemove}
              refreshData={() => props.setPage(0)}
              author={s.Session_author}
              jobTitle={s.Session_auth_job_title}
              image={s.image}
              buttonLabel="Book"
            />
          ))
        )}
      </Slider>
      <AlertPopup
        timer={3000}
        open={open}
        onClose={handleClose}
        type="success"
        message={"Session Booked Successfully!!"}
      />
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Session Booked Successfully!
        </Alert>
      </Snackbar> */}
    </Box>
  );
}

export default HoriSlide;
