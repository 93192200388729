import React, { useEffect, useState } from "react";
import {
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
  TextField,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  FormControl,
  NativeSelect,
  Alert,
  Snackbar,
  Chip,
  useTheme
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Optionbar from "../Optionbar/Optionbar";
import { MoreVert } from "@mui/icons-material";
import {
  fetchCoursePublish,
  insertCoursePublish,
  updateCourseStatus,
} from "../../api/course_publish_related_service";
import Archivecourse from "./Archivecourse";
import Appliedcourse from "./Appliedcourse";
import Closedcourse from "./Closedcourse";
import Confirmation from "../Common_Components/Confirmation";
import AlertPopup from "../Common_Components/AlertPopup";
import Cookies from 'js-cookie';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Admincourse = () => {
  const user_token = Cookies.get("connectedMe")
  const [open, setOpen] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [link, setLink] = useState("");
  const [tags, setTags] = useState("");
  const [details, setDetails] = useState("");
  const [status, setStatus] = useState("Open");
  const [alert, setAlert] = useState(false);
  const [value, setValue] = useState("1");
  const [courseData, setCourseData] = useState([]);
  const [confirm, setConfirm] = useState(false); // State for confirmation dialog
  const [showSnackbar, setShowSnackbar] = useState(false); // State for Snackbar

  const handleChange = (event, newValue) => {
    setValue(newValue);
    fetchCoursePublishData();
  };

  useEffect(() => {
    fetchCoursePublishData();
  }, []);

  const fetchCoursePublishData = async () => {
    try {
      const response = await fetchCoursePublish();
      setCourseData(response);
    } catch (error) {
      console.error("Error fetching course data", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePublish = () => {
    return new Promise((resolve, reject) => {
      const payload = {
        course_name: courseName,
        course_link: link,
        course_tag: tags,
        course_details: details,
        course_status: status,
      };

      insertCoursePublish({ ...payload, token: user_token })
        .then(() => {
          handleClose();
          setCourseName("");
          setLink("");
          setTags("");
          setDetails("");
          fetchCoursePublishData().then(resolve).catch(reject);
        })
        .catch((error) => {
          console.error("Error publishing course:", error);
          reject(error);
        });
    });
  };

  const handleAlert = () => {
    setAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const handleConfirm = () => {
    setConfirm(true);
  };

  const handleConfirmYes = () => {
    handlePublish().then(() => {
      setConfirm(false);
      setShowSnackbar(true);
      return fetchCoursePublishData();
    });
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const theme = useTheme();
  const handleStatusChange = async (event, courseId) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    const payload = {
      course_id: courseId,
      course_status: newStatus,
      // token: userToken,
    };

    updateCourseStatus({ ...payload, token: user_token })
      .then(() => {
        fetchCoursePublishData();
      })
      .catch((error) => {
        console.error("Failed to insert mentee matches:", error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Stack>
          <Typography variant="h4" sx={{ margin: "20px 0 0 20px" }}>
            Courses
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ margin: "0 0 20px 20px", color: "grey" }}
          >
            Explore Our Top Courses: Expertly Crafted Learning Journeys for Your
            Success
          </Typography>
        </Stack>
        {courseData.length === 0 ? (
          <Stack
            sx={{
              height: "75vh",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h5">No Courses Available</Typography>
          </Stack>
        ) : (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Active Courses" value="1" />
                  <Tab label="Archive Courses" value="2" />
                  <Tab label="Applied Courses" value="3" />
                  <Tab label="Closed Courses" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <TableContainer sx={{ margin: "10px", borderRadius: "8px" }} component={Paper}>
                  <Table>
                    <TableHead sx={{ backgroundColor: "#2f3348" }}>
                      <TableRow>
                        {["Course Name", "Course ID", "Link", "Tag", "Details", "Rating", "Status"].map((header, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              fontWeight: "bold",
                              color: "white",
                              textAlign: "center",
                              padding: "8px",
                              border: "1px solid #444",
                              whiteSpace: "nowrap", // Prevent header text wrapping
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {courseData.map((course, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                            "&:nth-of-type(even)": { backgroundColor: "#f1f1f1" },
                            verticalAlign: "top", // Align content at the top of the cell
                          }}
                        >
                          <TableCell sx={{ textAlign: "center", padding: "8px" }}>
                            <a
                              href={course.course_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "#1e88e5", textDecoration: "none", fontWeight: "bold" }}
                            >
                              {course.course_name}
                            </a>
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", padding: "8px" }}>
                            {course.course_id}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              padding: "8px",
                              whiteSpace: "normal",
                            }}
                          >
                            {course.course_tag.map((tag, i) => (
                              <Chip
                                key={i}
                                label={tag}
                                sx={{ margin: "2px", fontSize: "12px", backgroundColor: "#dfe6e9" }}
                              />
                            ))}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "justify",
                              padding: "8px",
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                          >
                            {course.course_details}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", padding: "8px" }}>NA</TableCell>
                          <TableCell sx={{ textAlign: "center", padding: "8px" }}>
                            <FormControl fullWidth>
                              <NativeSelect
                                defaultValue={course.course_status}
                                onChange={(event) => handleStatusChange(event, course.course_id)}
                              >
                                <option value="Open">Open</option>
                                <option value="Close">Close</option>
                                <option value="Archive">Archive</option>
                              </NativeSelect>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>


                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value="2">
                <Archivecourse />
              </TabPanel>
              <TabPanel value="3">
                <Appliedcourse />
              </TabPanel>
              <TabPanel value="4">
                <Closedcourse />
              </TabPanel>
            </TabContext>
          </Box>
        )}
        <Stack onClick={handleClickOpen}>
          <Optionbar onClick={handleClickOpen} />
        </Stack>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{
            "& .MuiDialog-paper": {
              minWidth: "800px",
            },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Add Courses
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 3,
              }}
            >
              <Stack sx={{ width: "300px" }}>
                <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                  Course Name*:
                </Typography>
                <TextField
                  autoFocus
                  type="text"
                  variant="standard"
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />
              </Stack>
              <Stack sx={{ width: "300px", marginTop: "20px" }}>
                <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                  Link*:
                </Typography>
                <TextField
                  type="text"
                  variant="standard"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </Stack>
              <Stack sx={{ width: "300px" }}>
                <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                  Tags*:
                </Typography>
                <TextField
                  type="text"
                  variant="standard"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                  Details*:
                </Typography>
                <TextField
                  type="text"
                  variant="standard"
                  multiline
                  rows={4}
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </Stack>
            </Box>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 3,
              }}
            >
              <Stack
                sx={{ display: "flex", width: "100%" }}
                direction={"row"}
                p={2}
                justifyContent={"space-between"}
              >
                <Button
                  onClick={() => {
                    if (courseName && link && details && tags) {
                      handleConfirm();
                    } else {
                      handleAlert();
                    }
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Publish
                </Button>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="secondary"
                >
                  Close
                </Button>
              </Stack>
            </DialogActions>
          </DialogContent>
        </BootstrapDialog>
        {confirm && (
          <Confirmation
            open={confirm}
            onClose={handleConfirmClose}
            submit={handleConfirmYes}
          />
        )}
        <AlertPopup timer={3000} open={showSnackbar} onClose={handleSnackbarClose} type="success" message={"Course Publish Successfully!!"} />
        <AlertPopup timer={2000} open={alert} onClose={handleAlertClose} type="error" message={"Please fill the details!!"} />

      </Stack>
    </ThemeProvider>
  );
};

export default Admincourse;
