import axios from "axios";
import network_conn from "../utils/network_config";
import Cookies from "js-cookie";

// Add User
export const addUser = async (userData) => {
  try {
    await axios.post(`${network_conn.api_auth_endpoint}sign_up`, userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Error adding user:", error.message);
  }
};

// Total User
export const totalUser = async (userData) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}user/total_user`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching total user:", error.message);
    return null;
  }
};

export const userValidate = async (tk_check) => {
  console.log("USER VALIDATE TOKEN");
  const res_body = { tk: tk_check };
  const response = await axios
    .post(network_conn.api_endpoint_v1 + "user_validate", res_body)
    .then((response) => {
      const resJson = response;
      console.log("response", resJson);

      if (response.status == "active") {
        return response.token;
      } else {
        return null;
      }
    })
    .catch((error) => {
      if (error?.response.status === 401) {
        // Remove token from localStorage
        localStorage.removeItem("user_tk");

        // Create a popup
        const overlay = document.createElement("div");
        overlay.style.position = "fixed";
        overlay.style.top = "0";
        overlay.style.left = "0";
        overlay.style.width = "100%";
        overlay.style.height = "100%";
        overlay.style.backgroundColor = "rgba(0, 0, 0, 0.05)"; // Semi-transparent dark background
        overlay.style.zIndex = "999";
        overlay.style.pointerEvents = "all";
        overlay.style.overflow = "hidden";

        const popup = document.createElement("div");
        popup.style.position = "fixed";
        popup.style.top = "50%";
        popup.style.left = "50%";
        popup.style.transform = "translate(-50%, -50%)";
        popup.style.padding = "20px";
        popup.style.backgroundColor = "#fff";
        // popup.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.5)";
        popup.style.zIndex = "1000";
        popup.style.textAlign = "center";
        popup.style.pointerEvents = "all";

        // Popup message
        const message = document.createElement("p");
        message.innerText = "Your session has expired. Please log in again.";
        popup.appendChild(message);
        document.body.appendChild(overlay);
        // Redirect button
        const button = document.createElement("button");
        button.innerText = "Go to Login";
        button.style.marginTop = "10px";
        button.style.padding = "10px 20px";
        button.style.backgroundColor = "#202332";
        button.style.color = "#fff";
        button.style.border = "none";
        button.style.cursor = "pointer";
        button.style.borderRadius = "4px";
        button.addEventListener("click", () => {
          window.location.href = "/";
        });
        popup.appendChild(button);

        // Add popup to the body
        document.body.appendChild(popup);
        document.body.classList.add("no-scroll");

        return; // Exit the function
      }
      console.log("Hii", error);
      return;
    });
};

const createSessionExpiredPopup = () => {
  const overlay = document.createElement("div");
  overlay.style.position = "fixed";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.width = "100%";
  overlay.style.height = "100%";
  overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)"; // Semi-transparent dark background
  overlay.style.zIndex = "999";
  overlay.style.pointerEvents = "all";

  const popup = document.createElement("div");
  popup.style.position = "fixed";
  popup.style.top = "50%";
  popup.style.left = "50%";
  popup.style.transform = "translate(-50%, -50%)";
  popup.style.padding = "20px";
  popup.style.backgroundColor = "#fff";
  popup.style.zIndex = "1000";
  popup.style.textAlign = "center";
  popup.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.5)"; // Add shadow for better visuals
  popup.style.borderRadius = "8px";

  // Popup message
  const message = document.createElement("p");
  message.innerText = "Your session has expired. Please log in again.";
  popup.appendChild(message);

  // Redirect button
  const button = document.createElement("button");
  button.innerText = "Go to Login";
  button.style.marginTop = "10px";
  button.style.padding = "10px 20px";
  button.style.backgroundColor = "#202332";
  button.style.color = "#fff";
  button.style.border = "none";
  button.style.cursor = "pointer";
  button.style.borderRadius = "4px";
  button.addEventListener("click", () => {
    window.location.href = "/";
  });

  popup.appendChild(button);

  // Add popup and overlay to the body
  document.body.appendChild(overlay);
  document.body.appendChild(popup);

  // Prevent scrolling
  document.body.style.overflow = "hidden";

  // Optional: Clean up overlay and popup when done
  overlay.addEventListener("click", () => {
    document.body.removeChild(overlay);
    document.body.removeChild(popup);
    document.body.style.overflow = ""; // Restore scrolling
  });
};

// Login User
export const loginUser = async (loginData) => {
  try {
    const formData = new FormData();
    for (const key in loginData) {
      if (loginData.hasOwnProperty(key)) {
        formData.append(key, loginData[key]);
      }
    }

    const response = await axios.post(
      `${network_conn.api_auth_endpoint}passauth`,
      formData
    );

    const rp = response.data;
    if (rp.hasOwnProperty("access_token")) {
      localStorage.setItem("user_tk", rp.access_token);
      Cookies.set("connectedMe", rp.access_token, {
        domain: ".tussp.com",
        secure: true,
        sameSite: "None",
      });
      return rp;
    } else {
      return { login: "unsuccessful" };
    }
  } catch (error) {
    console.error("Error logging in user:", error.message);
    return { login: "unsuccessful" };
  }
};

// // User Validate
// export const userValidate = async (tk_check) => {
//   try {
//     const res_body = { tk: tk_check };
//     const response = await axios.post(
//       `${network_conn.api_endpoint_v1}user_validate`,
//       res_body,
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     const rp = response.data;
//     if (rp.status === "active") {
//       return rp.token;
//     } else {
//       return null;
//     }
//   } catch (error) {
//     console.error("Error validating user:", error.message);
//     return null;
//   }
// };

// User Logout
export const userLogout = async () => {
  try {
    await axios.post(`${network_conn.api_auth_endpoint}logout`);
  } catch (error) {
    console.error("Error logging out user:", error.message);
  }
};

// Fetch User
export const fetchUser = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}user/fetch_user`,
      fetchreq,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error.message);
    return [];
  }
};

// Verify Email
export const verifyEmail = async (req) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}validation_service/update_status`,
      req,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying email:", error.message);
    throw error;
  }
};

// Active Email
export const activeEmail = async (req) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}validation_service/verify_email`,
      req,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error activating email:", error.message);
    throw error;
  }
};

// Validate Email
export const validateEmail = async (req) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}forgot_password/create_otp`,
      req,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error validating email:", error.message);
    throw error;
  }
};

// Validate OTP
export const validateOTP = async (req) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}forgot_password/validate_otp`,
      req,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error validating OTP:", error.message);
    throw error;
  }
};

// Update Password
export const updatePassword = async (req) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}forgot_password/update_password`,
      req,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating password:", error.message);
    throw error;
  }
};
