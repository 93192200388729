import axios from "axios";
import network_conn from "../utils/network_config";

export const sendMentorBookingMail = async (mail) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}mail_service/mentor_mail`,
      mail,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending mentor booking mail:", error.message);
    throw error;
  }
};
