
export const desktopOS = [
    {
        label: 'successfull',
        value: 72.72,
    },
    
];

export const mobileOS = [
    {
        label: 'rescheduling',
        value: 30.48,
    },
    
];

export const platforms = [
    {
        label: 'Cancelling/ rescheduling rate',
        value: 30.48,
    },
    {
        label: 'successfull rate',
        value: 72.72,
    },
];

const normalize = (v: number, v2: number) => Number.parseFloat(((v * v2) / 100).toFixed(2));

export const mobileAndDesktopOS = [
    ...mobileOS.map((v) => ({
        ...v,
        label: v.label === 'Other' ? 'Other (Mobile)' : v.label,
        value: normalize(v.value, platforms[0].value),
    })),
    ...desktopOS.map((v) => ({
        ...v,
        label: v.label === 'Other' ? 'Other (Desktop)' : v.label,
        value: normalize(v.value, platforms[1].value),
    })),
];

export const valueFormatters = (item: { value: number }) => `${item.value}%`;