import axios from "axios";
import network_conn from "../utils/network_config";

export const addProfile = async (profile) => {
    try {
      const response = await axios.post(
        `${network_conn.api_endpoint_v1}profile_service/insert_profile`,
        profile
      );
  
      console.log("Data sent successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error sending data:", error);
      throw new Error("Failed to send data. Please try again later.");
    }
  };
  
// // Function to fetch all profile data
export const fetchProfile = async (fetchreq) => {
  if (!fetchreq || typeof fetchreq !== "object") {
    throw new Error("Invalid request payload");
  }

  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}profile_service/fetch_profile`,
      fetchreq
    );
    return response.data; // Return only the response body
  } catch (error) {
    console.error(
      `Error fetching profile from ${network_conn.api_endpoint_v1}profile_service/fetch_profile:`,
      error
    );
    throw new Error("Failed to fetch profile. Please try again later.");
  }
};


export const addProfileImage = async (profile) => {
  try {
    const formData = new FormData();
    formData.append("token", profile.token);
    formData.append("image", profile.image);

    const response = await axios.post(
      `${network_conn.api_endpoint_v1}profile_service/insert_profile_img`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Data sent successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};


export const fetchProfileImage = async (profile) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}profile_service/fetch_profile_img`,
      profile,
      {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }
    );

    const imageUrl = URL.createObjectURL(response.data);
    return imageUrl;
  } catch (error) {
    console.error("Error fetching profile image:", error);
    throw error;
  }
};

export const deleteProfileImg = async (deleteImg) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}profile_service/delete_profile_img`,
      deleteImg,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error deleting appointment:", error.message);
    return false;
  }
};

export const addSkill = async (profile) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}profile_service/insert_skills`,
      profile,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Data sent successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error.message);
    throw error;
  }
};

export const fetchPositions = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}profile_service/fetch_positions`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching positions:", error.message);
    return [];
  }
};