import React from 'react';
import {  Card, Box, Typography, LinearProgress, Grid } from '@mui/material';

const SkillTracker = ({ skills }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Card
      sx={{ maxWidth: 500, mx: "auto", p: 2, borderRadius: 2, boxShadow: 3 }}
    >
      <Grid container spacing={2}>
        {skills.map((skill, index) => {
          const progress = Math.min((skill.currentLevel / skill.desiredLevel) * 100, 100);
          return (
            <Grid item xs={12} md={6} key={index}>
              <Box 
                sx={{ 
                  border: '1px solid #ddd', 
                  borderRadius: '8px', 
                  p: 1, 
                  backgroundColor: '#f9f9f9' 
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  {skill.name}
                </Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={progress} 
                  sx={{ 
                    height: 8, 
                    borderRadius: 4, 
                    backgroundColor: '#ddd',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: progress === 100 ? '#82caaf' : 'yellow',
                    }
                  }}
                />
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {skill.currentLevel} / {skill.desiredLevel}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      </Card>
    </Box>
  );
};

export default SkillTracker;
