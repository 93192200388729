import { React, useEffect, useState } from "react";
import useEventDataStore from "../../store/SchedulerData";
import {Grid, Stack, Typography, Divider} from "@mui/material";
import DrawIcon from "@mui/icons-material/Draw";
import CustomCard from "../Common_Components/Card";
import EmptyCard from "../Common_Components/emptyCard";
function UserSessionMenu({ searchTerm }) {
  const { appointments } = useEventDataStore();
  const [filteredAppointments, setFilteredAppointments] = useState([]);

  useEffect(() => {
      // Filter sessions based on the search query
      const results = appointments.filter((ap) =>
        ap.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredAppointments(results);
    }, [searchTerm, appointments]);
    
  const convertToLocalDate = (utrDateString) => {
    const utcDate = new Date(utrDateString);
    const localDate = utcDate.toLocaleDateString();
    const localTime = utcDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${localDate} ${localTime}`;
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "50vh" }}
    >
      {filteredAppointments && filteredAppointments.length > 0 ? (
        filteredAppointments.map(function (appointment) {
          return (
            <Grid item key={appointment.id}>
              <CustomCard>
                <Stack sx={{ height: "100%" }}>
                  {/* Content at the Top */}
                  <Stack spacing={1} sx={{ p: 1 }}>
                    <Typography
                      variant="h3"
                      component="div"
                      noWrap
                      sx={{
                        fontWeight: "bold",
                        fontSize: "inherit",
                        lineHeight: "inherit",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {appointment.title.split(" ").slice(0, 2).join(" ") + (appointment.title.split(" ").length > 2 ? "..." : "")}
                    </Typography>
                    <Divider/>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                      }}
                    >
                      {appointment.notes}
                    </Typography>
                  </Stack>

                  {/* Bottom Stack - Pinned */}
                  <Stack
                    spacing={1}
                    sx={{
                      backgroundColor: "primary.main",
                      color:"white",
                      padding: "8px",
                      mt: "auto",
                    }}
                  >
                    <Typography variant="body2">
                      <strong>Start at:</strong>{" "}
                      {convertToLocalDate(appointment.startDate)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>End at:</strong>{" "}
                      {convertToLocalDate(appointment.endDate)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Location:</strong> {appointment.customField}
                    </Typography>
                  </Stack>
                </Stack>
              </CustomCard>
            </Grid>
          );
        })
      ) : (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "50vh",
          }}
        >
         <EmptyCard
          message="No Created Session"
          icon={
            <DrawIcon
              sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
            />
          }
        />
        </Grid>
      )}
    </Grid>
  );
}

export default UserSessionMenu;
