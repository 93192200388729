import axios from "axios";
import network_conn from "../utils/network_config";

export const post_notification = async (request) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}notification/post_notification`,
      request,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Data inserted successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error posting notification:", error.message);
    throw error;
  }
};

export const markNotificationAsRead = async (request) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}notification/post_notification_status`,
      request,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Data inserted successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error marking notification as read:", error.message);
    throw error;
  }
};

export const fetchNotification = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}notification/fetch_notification`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Fetched notifications:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching notifications:", error.message);
    throw error;
  }
};

export const fetchAchievementNotification = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}notification/fetch_achievement_notification`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Fetched Achievement notifications:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching Achievement notifications:", error.message);
    throw error;
  }
};

export const fetchSessionNotification = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/get_past_meetings`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Fetched Session notifications:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching notifications:", error.message);
    throw error;
  }
};

export const updateSessionNotification = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/update_meeting_status`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Fetched Session notifications:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching notifications:", error.message);
    throw error;
  }
};

export const clearNotification = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}notification/clear_notification`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Notification cleared:", response.data);
    return response.data;
  } catch (error) {
    console.error(`Error clearing notification for user ${fetchreq.user_uuid}:`, error.message);
    throw error;
  }
};

export const RescheduleMailResponse = async (request) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/reschedule_mail_response`,
      request,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Data inserted successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending reschedule mail response:", error.message);
    throw error;
  }
};
