import network_conn from "../utils/network_config";
import axios from "axios";

export const fetch_progress_request = async (fetchreq) => {
    try {
      const response = await axios.post(
        `${network_conn.api_endpoint_v1}progress_tracker/get_upgrade_reuest`,
        fetchreq,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching progress_request:", error.message);
      return [];
    }
  };


  export const update_progress_request = async (fetchreq) => {
    try {
      const response = await axios.post(
        `${network_conn.api_endpoint_v1}progress_tracker/post_upgrade`,
        fetchreq,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating progress_request:", error.message);
      return [];
    }
  };

  export const fetch_manager = async (fetchreq) => {
    try {
      const response = await axios.post(
        `${network_conn.api_endpoint_v1}progress_tracker/fetch_manager_details`,
        fetchreq,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching progress_request:", error.message);
      return [];
    }
  };

  export const request_level_upgrade = async (fetchreq) => {
    try {
      const response = await axios.post(
        `${network_conn.api_endpoint_v1}progress_tracker/post_upgrade_request`,
        fetchreq,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error posting level update request:", error.message);
      return [];
    }
  };