import { useState, useEffect } from "react";
import {
  Avatar,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import useUserMentorSess from "../../store/user_mentor_session";
import Cookies from 'js-cookie';

function CardListView({ searchTerm }) {
  const user_token = Cookies.get("connectedMe")
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const { sess_men } = useUserMentorSess();


  useEffect(() => {
    setSessions(sess_men);
  }, [sess_men]);

    useEffect(() => {
      // Filter sessions based on the search query
      const results = sessions.filter((session) =>
        session.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredSessions(results);
    }, [searchTerm, sessions]);
  const convertToLocalDate = (utrDateString) => {
    const utcDate = new Date(utrDateString);
    const localDate = utcDate.toLocaleDateString();
    const localTime = utcDate.toLocaleTimeString();
    return `${localDate} ${localTime}`;
  };
  return (
    <TableContainer sx={{  margin: "30px", borderRadius: "8px" , mr:"30px" }} component={Paper}>
      <Table>
        <TableHead sx={{ backgroundColor: "primary.main" }}>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: "bold",
                color: "white",
                borderRight: "1px solid #e0e0e0",
              }}
            >
              Date & Time
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                color: "white",
                borderRight: "1px solid #e0e0e0",
              }}
            >
              Session Title
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                color: "white",
                borderRight: "1px solid #e0e0e0",
              }}
            >
              Description
            </TableCell>

            <TableCell
              sx={{
                fontWeight: "bold",
                color: "white",
                borderRight: "1px solid #e0e0e0",
              }}
            >
              Mentor
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSessions?.map((s) => (
            <TableRow key={s.meeting_id}>
              <TableCell
                sx={{ borderRight: "1px solid #e0e0e0", maxWidth: "40px" }}
              >
                <Stack direction="column" spacing={1}>

                  <Typography>{convertToLocalDate(s.startDate)}</Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #e0e0e0",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  maxWidth: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {s.title}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #e0e0e0",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {s.Desc}
              </TableCell>

              <TableCell
                sx={{ borderRight: "1px solid #e0e0e0", maxWidth: "80px" }}
                component="th"
                scope="row"
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar
                    sx={{ margin: "5px" }}
                    src={s.image ? s.image : undefined}
                  />
                  <Typography>{s.Session_author}</Typography>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CardListView;
