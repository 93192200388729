import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const AlertPopup = ({
    onClose,
    open,
    message,
    type = "success" || "error" || "info",
    timer
}) => {
    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={timer}
                onClose={onClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert
                    onClose={onClose}
                    severity={type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default AlertPopup
