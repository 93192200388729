import React, { useState, useEffect } from "react";
import useStore from "../../store/sponsor_data";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import { Mantee_data } from "../../store/Mentee_data";
import {
  Button,
  Paper,
  Stack,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Avatar,
  AvatarGroup,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  insertSponsor,
  fetchMentee,
  insertMenteeMatches,
  fetchMenteeMacthes,
  removeMenteeMacthes,
} from "../../api/sponsor_related_service";
import userSess from "../../store/user_session_control";
import Cookies from 'js-cookie';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  "& .MuiPaper-root": {
    boxShadow: "none",
    minHeight: "350px",
    height: "400px",
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
}));

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "primary.main",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "primary.main",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "primary.main",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

function Sponsor() {
  const user_token = Cookies.get("connectedMe")
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMentees, setFilteredMentees] = useState([]);
  const [selectedMentees, setSelectedMentees] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const { SponsorData } = useStore();
  const [value, setValue] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [tab, setTab] = useState("mentor");
  const [appliedRoles, setAppliedRoles] = useState({});
  const handleApplyClick = (roleId) => {
    setAppliedRoles((prevAppliedRoles) => ({
      ...prevAppliedRoles,
      [roleId]: true,
    }));
  };
  // const [mentor, setMentor] = useState(true);
  const [selectedJobId, setSelectedJobId] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [selectedMenteeMatch, setSelectedMenteeMatch] = useState([]);
  // const [newMentee, setNewMentee] = useState([]);
  // const { user_token } = userSess();
  useEffect(() => {
    fetchMenteeData();
  }, []);

  const fetchMenteeData = async () => {
    try {
      const response = await fetchMentee({
        user_token: user_token,
      });
      console.log(response);
      setFilteredMentees(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchMenteeMacthess();
  }, []);

  const fetchMenteeMacthess = async () => {
    const mentee_matches_res = await fetchMenteeMacthes({
      user_token: user_token,
    });
    // setNewMentee(mentee_matches_res);
    setSelectedMentees(mentee_matches_res);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === "") {
      fetchMenteeData();
    } else {
      const filtered = filteredMentees.filter((mentee) =>
        mentee.mentee_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredMentees(filtered);
    }
  };

  const handleAddMentee = async (rowId, id, name, email) => {
    setSelectedMentees((prevSelected) => {
      const updatedSelected = {
        ...prevSelected,
        [rowId]: [...(prevSelected[rowId] || []), { id, name, email }],
      };

      const row = SponsorData[rowId];
      const selectedJobId = row.advertisement_id;
      const selectedMenteeMatch = updatedSelected[rowId];

      const payload = {
        job_id: selectedJobId,
        refered_mentee: selectedMenteeMatch
          .map(
            (mentee) =>
              `{mentee_name: ${mentee.name}, id: ${mentee.id}, email: ${mentee.email}}`
          )
          .join(", "),
      };

      insertMenteeMatches({ ...payload, token: user_token })
        .then(() => {
          fetchMenteeMacthess();
        })
        .catch((error) => {
          console.error("Failed to insert mentee matches:", error);
        });

      return updatedSelected;
    });

    setCurrentRow(rowId);
  };

  const handleRemoveMentee = (rowJobId, nameToRemove, idToRemove) => {
    // console.log(idToRemove);
    const jobId = rowJobId;
    const menteeName = nameToRemove;
    const id = idToRemove;

    const payload = {
      job_id: jobId,
      mentee_to_remove: menteeName,
      id: id,
    };
    // console.log(payload);
    removeMenteeMacthes({ token: user_token, ...payload })
      .then(() => {
        fetchMenteeMacthess();
      })
      .catch((error) => {
        console.error("Failed to insert mentee matches:", error);
      });
  };

  const handleClickOpen = (rowId) => {
    setCurrentRow(rowId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchTerm("");
    fetchMenteeData();
    fetchMenteeMacthess();
  };

  const handleSponsorData = (rowId) => {
    setCurrentRow(rowId);
    const row = SponsorData[rowId];
    setSelectedJobId(row.advertisement_id);
    setSelectedRole(row.advertisement_role);
    setSelectedSkill(row.advertisement_skill_match);
    setSelectedMenteeMatch(selectedMentees[rowId] || []);
    const payload = {
      job_id: selectedJobId,
      role: selectedRole,
      skill: selectedSkill,
      refered_mentee: selectedMenteeMatch
        .map((mentee) => mentee.name)
        .join(", "),
    };
    insertSponsor({ ...payload, token: user_token });
  };
  return (
    <>
      <Stack sx={{ margin: "0 20px" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ bgcolor: "#fff" }}>
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="ant example"
              
            >
              <AntTab label="Mentor" onClick={() => setTab("mentor")} />
              <AntTab label="Mentee" onClick={() => setTab("mentee")} />
            </AntTabs>
            <Box />
          </Box>
        </Box>
      </Stack>
      {tab === "mentor" && (
        <TableContainer sx={{ margin: "30px", borderRadius: "8px" , width:"97%"}} component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "primary.main" }}>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Published On
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Job Id
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Role
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Mode
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Skill Match
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    borderRight: "1px solid #e0e0e0",
                    width: 250,
                    minWidth: 150,
                  }}
                >
                  Mentee Matches
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  Refer
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SponsorData.map((rowData, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell
                    sx={{ borderRight: "1px solid #e0e0e0", height: 40 }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Typography>{rowData.advertisement_date}</Typography>
                      {/* <Typography>{rowData.advertisement_time}</Typography> */}
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    {rowData.advertisement_id}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    {rowData.advertisement_role}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    {rowData.advertisement_mode}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    {rowData.advertisement_description}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    {rowData.advertisement_skill_match}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                    <Stack
                      direction={"row"}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Stack>
                        <Stack direction="row" alignItems="center">
                          <AvatarGroup max={4}>
                            {(
                              selectedMentees[rowData.advertisement_id] || []
                            ).map((mentee, index) => (
                              <HtmlTooltip
                                key={index}
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit">
                                      <Stack
                                        direction={"row"}
                                        gap={1}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "5px",
                                        }}
                                      >
                                        <Avatar>{mentee.mentee_name[0]}</Avatar>
                                        {mentee.mentee_name}
                                      </Stack>
                                    </Typography>
                                    {"Email: "}
                                    {mentee.email}
                                  </React.Fragment>
                                }
                              >
                                <Avatar>{mentee.mentee_name[0]}</Avatar>
                              </HtmlTooltip>
                            ))}
                          </AvatarGroup>
                        </Stack>
                      </Stack>
                      <Stack sx={{ flexGrow: 1 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Stack textAlign="right">
                            <Button
                              variant="outlined"
                              onClick={() => handleClickOpen(rowIndex)}
                              sx={{ cursor: "pointer", padding: "auto 0" }}
                            >
                              <SearchIcon />
                            </Button>
                            <BootstrapDialog
                              onClose={handleClose}
                              aria-labelledby="customized-dialog-title"
                              open={open && currentRow === rowIndex}
                            >
                              <DialogTitle
                                sx={{ m: 0, p: 2 }}
                                id="customized-dialog-title"
                              >
                                Search Mentee
                              </DialogTitle>
                              <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                  position: "absolute",
                                  right: 8,
                                  top: 8,
                                  color: (theme) => theme.palette.grey[500],
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                              <DialogContent dividers>
                                <TextField
                                  label="Search Mentee"
                                  value={searchTerm}
                                  onChange={handleSearchChange}
                                  fullWidth
                                />
                                <List>
                                  {searchTerm &&
                                    filteredMentees
                                      .filter(
                                        (mentee) =>
                                          !(
                                            selectedMentees[
                                              rowData.advertisement_id
                                            ] || []
                                          ).some(
                                            (selectedMentee) =>
                                              selectedMentee.id === mentee.id
                                          )
                                      )
                                      .map((mentee, index) => (
                                        <ListItem key={index}>
                                          <ListItemText 
                                            primary={mentee.mentee_name}
                                          />
                                          <Button
                                            variant="contained"
                                            color='secondary'
                                            size="small"
                                            disabled={isButtonDisabled}
                                            onClick={() =>
                                              handleAddMentee(
                                                currentRow,
                                                mentee.id,
                                                mentee.mentee_name,
                                                mentee.email
                                              )
                                            }
                                          >
                                            Add
                                          </Button>
                                        </ListItem>
                                      ))}
                                  {selectedMentees[
                                    rowData.advertisement_id
                                  ]?.map((mentee, index) => (
                                    <ListItem key={index}>
                                      <ListItemText
                                        primary={mentee.mentee_name}
                                      />
                                      <Button
                                        variant="outlined"
                                        color='secondary'
                                        onClick={() =>
                                          handleRemoveMentee(
                                            rowData.advertisement_id,
                                            mentee.mentee_name,
                                            mentee.id
                                          )
                                        }
                                      >
                                        Remove
                                      </Button>
                                    </ListItem>
                                  ))}
                                  {!searchTerm && <Divider />}
                                  {!searchTerm &&
                                    filteredMentees
                                      .filter(
                                        (mentee) =>
                                          !(
                                            selectedMentees[
                                              rowData.advertisement_id
                                            ] || []
                                          ).some(
                                            (selectedMentee) =>
                                              selectedMentee.id === mentee.id
                                          )
                                      )
                                      .map((mentee, index) => (
                                        <ListItem key={index}>
                                          <ListItemText
                                            primary={mentee.mentee_name}
                                          />
                                          <Button
                                            variant="contained"
                                            color='secondary'
                                            size="small"
                                            onClick={() =>
                                              handleAddMentee(
                                                currentRow,
                                                mentee.id,
                                                mentee.mentee_name,
                                                mentee.email
                                              )
                                            }
                                          >
                                            Add
                                          </Button>
                                        </ListItem>
                                      ))}
                                </List>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose} color='secondary'>
                                  Close
                                </Button>
                              </DialogActions>
                            </BootstrapDialog>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color='secondary'
                      onClick={() => handleSponsorData(rowIndex)}
                    >
                      Sponsor
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {tab === "mentee" && (
        <>
          <TableContainer sx={{ margin: "30px", borderRadius: "8px" , width:"97%" }} component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: "primary.main" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Published On
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Job Id
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Mode
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Skill Match
                  </TableCell>
                  {/* <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    borderRight: "1px solid #e0e0e0",
                    width: 250,
                    minWidth: 150,
                  }}
                >
                  Mentee Matches
                </TableCell> */}
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SponsorData.map((rowData, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell
                      sx={{ borderRight: "1px solid #e0e0e0", height: 40 }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Typography>{rowData.advertisement_date}</Typography>
                        {/* <Typography>{rowData.advertisement_time}</Typography> */}
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                      {rowData.advertisement_id}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                      {rowData.advertisement_role}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                      {rowData.advertisement_mode}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                      {rowData.advertisement_description}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                      {rowData.advertisement_skill_match}
                    </TableCell>

                    <TableCell>
                      {appliedRoles[rowIndex] ? (
                        <Button disabled>Applied</Button>
                      ) : (
                        <Button
                          onClick={() => handleApplyClick(rowIndex)}
                          variant="contained"
                          color='secondary'
                        >
                          Apply
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

export default Sponsor;
