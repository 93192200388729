import { CheckCircleOutline } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import {
  Alert,
  Avatar,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import { validateEmail } from "../../api/user_related_service";

const Verify_Email = () => {
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const [verified, setVerified] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [open, setOpen] = useState(false);
  const [formValid, setFormValid] = useState();
  const navigate = useNavigate();

  const handleEmail = () => {
    if (!isEmail(emailInput)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  const handleBack = () => {
    setVerified(false);
    setButtonClicked(false);
    setOpen(false)
  }

  const handleClick = async () => {
    if (emailError || !emailInput) {
      setFormValid("Email is Invalid. Please Re-Enter");
      return;
    }
    console.log("emaill",emailInput)
    try {
      const response = await validateEmail({
        email: emailInput.trim()
      });
      console.log(response['message'])
      if (response['message'] == 'Invalid User') {
        setFormValid(response['message'])
        // navigate(`/forgot_pass/${emailInput}`);
      } else {
        setOpen(true);
        setVerified(true);
        setButtonClicked(true);
        setFormValid();
      }

    } catch (error) {
      console.error('Error fetching user:', error);
      setFormValid("Failed to verify user.");
      return;
    }
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Paper style={{ height: "100%", padding: "20px" }}>
            <h2>Advertisement</h2>
            <p>Ad content goes here...</p>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{ height: "100%", padding: "20px" }}>
            <Grid container alignItems="center" direction="column">
              <Avatar sx={{ bgcolor: "#2F3348" }}>
                <LockIcon />
              </Avatar>
              <Typography
                variant="h5"
                align="center"
                sx={{ marginBottom: "2rem" }}
              >
                Verify your Email
              </Typography>
            </Grid>
            <Stack display={"flex"}>

              <TextField
                variant="outlined"
                Width="100%"
                label="Your email"
                error={emailError}
                value={emailInput}
                style={{ marginBottom: "1rem" }}
                onBlur={handleEmail}
                onChange={(e) => setEmailInput(e.target.value)}
                disabled={open}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {open && <CheckCircleOutline fontSize="large" style={{ color: '#4BB543' }} />}
                    </InputAdornment>
                  ),
                  
                }}
              />
              {formValid && (<Alert severity="error" sx={{ marginBottom: "20px" }}>
                <Stack display={'flex'} direction={'row'}>
                  {formValid}
                </Stack>
              </Alert>)}
              {/* <CheckCircleOutline fontSize="large"/> */}

              {!buttonClicked && (
                <Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} >
                  <Button variant="contained" onClick={() => { navigate(-1) }} style={{ width: '100px' }}> Back</Button>
                  <Button variant="contained" onClick={handleClick} style={{ width: '100px' }}>
                    Verify
                  </Button>

                </Stack>
              )}
              {verified && (
                <Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <Button variant="contained" style={{ width: '100px' }} onClick={handleBack}> Back</Button>

                  <Link to={`/forgot_pass/${emailInput}`}>
                    <Button variant="contained" style={{ width: '100px' }}>
                      Next
                    </Button>
                  </Link>
                </Stack>
              )}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Verify_Email;
