import { React, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { fetchBookedMentors } from "../../api/recommendation_related_service";
import { unbookMentors } from "../../api/recommendation_related_service";
import EmptyCard from "../Common_Components/emptyCard";
import MentorCard from "../Common_Components/mentorCard";
import Cookies from 'js-cookie';

function BookedMentors({ searchTerm }) {
  const [mentor, setMentor] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredMentor, setFilteredMentor] = useState([]);
  const user_token = Cookies.get("connectedMe")
  useEffect(() => {
    fetchRecommendedMentorData();
  }, []);

  useEffect(() => {
    const results = mentor.filter((m) =>
      m.first_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMentor(results);
  }, [searchTerm, mentor]);
  const fetchRecommendedMentorData = async () => {
    try {
      setIsLoading(true);
      const response = await fetchBookedMentors({ token: user_token });
      setMentor(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error("Error fetching recommended mentors:", error);
      setMentor([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnbook = async (mentor_uuid) => {
    try {
      await unbookMentors({ token: user_token, mentor_uuid: mentor_uuid });
      fetchRecommendedMentorData();
    } catch (error) {
      console.error("Error unbooking mentor:", error);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1400px",
        margin: "0 auto",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "16px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <CircularProgress />
          </Box>
        )}

        {!isLoading && mentor.length === 0 && (
          <EmptyCard
          message="No Mentor Available"
          icon={
            <PeopleIcon
              sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
            />
          }
        />
        )}
        {!isLoading &&
          Array.isArray(filteredMentor) &&
          filteredMentor.map(
            (
              mentorData,
              index // Ensure mentor is an array before mapping
            ) => (
              <MentorCard
                key={index}
                mentorData={mentorData}
                buttonLabel="UNBOOK"
                handleBook={() => handleUnbook(mentorData.mentor_user_uuid)}
                session_date={mentorData.session_date}
              />
            )
          )}
      </Box>
    </Box>
  );
}

export default BookedMentors;
