import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  IconButton,
  Chip,
  Grid,
  Box,
} from "@mui/material";
import { Favorite, Comment, Share } from "@mui/icons-material";

const AchievementCard = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {
    image,
    user_name,
    title,
    description,
    date,
    tags = [],
    for_whom,
    whom_image,
  } = props;

  return (
    <>
      <Card
        onClick={() => setIsDialogOpen(true)}
        sx={{
          width: { xs: "350px", sm: "400px", md: "600px" },
          margin: "auto",
          marginBottom: 2,
          border: "1px solid #8a91b0",
          boxShadow:
            "0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardHeader
          avatar={<Avatar src={image} alt={user_name} />}
          title={user_name}
          subheader={date}
          sx={{ flex: "0 0 auto" }}
          action={
            for_whom && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Avatar
                  src={whom_image}
                  sx={{ width: 30, height: 30 }}
                  alt={for_whom}
                />
                <Typography
                  variant="body2"
                  noWrap
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  For: {for_whom}
                </Typography>
              </Box>
            )
          }
        />

        <CardContent
          sx={{
            flex: "1 1 auto",
            overflow: "auto",
            overflowY: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Typography variant="h6" gutterBottom noWrap>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
          <Grid container spacing={1} sx={{ marginTop: 2 }}>
            {tags.map((tag, index) => (
              <Grid item key={index}>
                <Chip label={tag} size="small" color="primary" />
              </Grid>
            ))}
          </Grid>
        </CardContent>

        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px",
          }}
        >
          <IconButton aria-label="like">
            <Favorite />
          </IconButton>
          <IconButton aria-label="comment">
            <Comment />
          </IconButton>
          <IconButton aria-label="share">
            <Share />
          </IconButton>
        </CardActions>
      </Card>
    </>
  );
};

export default AchievementCard;
