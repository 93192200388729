import React from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Avatar,
  Badge,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { post_notification } from "../../api/notification_related_service";
import CustomCard from "./Card";
import Cookies from 'js-cookie';

const MentorCard = ({ mentorData, buttonLabel, handleBook, session_date }) => {
  const { first_name, last_name, professional_info, image, mentorId, skills } =
    mentorData;
    const user_token = Cookies.get("connectedMe")
  const navigate = useNavigate();
  const handleMentorClick = async (mentorData) => {
    try {
      await post_notification({
        token: user_token,
        mentor_user_uuid: mentorData.mentor_user_uuid,
        read_status: false,
      });
      // Navigate to mentor profile
      navigate(`/d/dashboard/mentorProfile`, { state: { mentorData } });
    } catch (error) {
      console.error("Error posting notification:", error);
    }
  };
  const convertToLocalDate = (utrDateString) => {
    const utcDate = new Date(utrDateString);
    const localDate = utcDate.toLocaleDateString();
    const localTime = utcDate.toLocaleTimeString();
    return `${localDate} ${localTime}`;
  };

  return (
    <CustomCard>
      <Box
        sx={{
          border: "1px solid #ccc",
          padding: "0 16px 16px 16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Skills Section */}
        <Stack
          sx={{
            height: "100%",
            overflowY: "hidden",
            display: "flex",
            justifyContent: "center",
            margin: "0 -16px",
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "15px",
              background: "linear-gradient(transparent, rgba(0,0,0,0.1))",
              pointerEvents: "none",
            },
          }}
        >
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              flexWrap: "wrap",
              height: "80%",
              overflowY: "auto",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              
            }}
          >
            {skills?.map((skill, skillIndex) => (
              <Badge
                key={skillIndex}
                badgeContent={skill.skill_level}
                color="error"
                sx={{
                  "& .MuiBadge-badge": {
                    right: 7,
                    top: 7,
                    border: `2px solid white`,
                    padding: "0 2px",
                  },
                }}
              >
                <Chip
                  label={skill.skill_name}
                  sx={{
                    backgroundColor: skill.level_higher ? "#4A4D66" : undefined,
                    color: skill.level_higher ? "#F2F3F5" : undefined,
                    margin: 1,
                    fontSize: "0.75rem",
                    height: "24px",
                    "& .MuiChip-label": {
                      padding: "0 8px",
                    },
                  }}
                />
              </Badge>
            ))}
          </Stack>
        </Stack>
        {session_date && (
          <Typography
            variant="overline"
            sx={{
              color: "#f65a4c",
              textAlign: "center",
              marginBottom: "10px",
              minHeight: "24px", // Maintains space even when empty
            }}
          >
            {convertToLocalDate(session_date)}
          </Typography>
        )}
        {/* Action Button */}
        <Button
          variant="contained"
          color="secondary"
          sx={{marginTop:"5px", marginBottom: "8px" }}
          onClick={() => handleBook(mentorId)}
        >
          {buttonLabel}
        </Button>

        {/* Author Info */}
        <Stack
          direction="row"
          gap={1}
          onClick={() => handleMentorClick(mentorData)}
          sx={{
            height: "16%",
            width: "auto",
            mx: -4,
            bgcolor: "primary.main",
            alignItems: "center",
            position: "relative",
            right: "-16px",
            left: "-16px",
            px: 4,
            py: 1,
          }}
        >
          <Avatar sx={{ ml: 2 }} src={image || undefined} />
          <Stack>
            <Typography
              variant="body2"
              sx={{
                color: "#ffffff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {first_name} {last_name}
            </Typography>
            <Typography variant="caption" sx={{ color: "#ffffff" }}>
              {professional_info}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </CustomCard>
  );
};

export default MentorCard;
