import {
  Badge,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// import "react-circular-progressbar/dist/styles.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import HoriSlide_mentor from "../components/Menu/horizontal_mentor";
import HoriSlide from "../components/Menu/horizontal_slider";
import SkillCard from "../components/flash_card/flash_card";
import "./../components/Menu/HorizontalMenu.css";
// import { BarPlot } from "@mui/x-charts/BarChart";
// import { LinePlot } from "@mui/x-charts/LineChart";
// import { ChartContainer } from "@mui/x-charts/ChartContainer";
// import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
// import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { fetchProfile } from "../api/profile_related_service";
import { fetchAppointments } from "../api/scheduler_related_services";
import { fetchDashSession } from "../api/session_service";
import { fetchSkillGap, get_monthly_Course_Count, getDesiredSkillsCount } from "../api/skill_catalog";
import HoriCourseSlide from "../components/Menu/horizontal_course_slider";
import HoriMentorSlide from "../components/Menu/horizontal_mentor_slider";
import SkillSelectionPopup from "../components/skill_selection/SkillSelectionPopup";
import useUserPrefSess from "../store/dash_session";
import Cookies from 'js-cookie';

function User_analytics_dash(isSmallScreen) {
  //Drop down
  const [selectedSkill, setSelectedSkill] = useState("");
  const [professionalAmbitions, setProfessionalAmbitions] = useState([]);
  const [professionalAmbitionsLevel, setProfessionalAmbitionsLevel] = useState(
    []
  );
  const [skillGap, setSkillGap] = useState([]);
  const [totalDesirableSkills, setTotalDesirableSkills] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  // const colorPalette = ["#4CAF50", "#FF5722", "#2196F3", "#FFC107", "#9C27B0"];


  //x={skillGap} y={totalDesirableSkills}
  useEffect(() => {
    const fetchAmbition = async () => {
      const profile = await fetchProfile({ user_token: user_token });
      console.log("profile", profile);

      const skills = profile.professional_ambitions?.map(
        (ambition) => ambition.skill_name
      );
      const levels = profile.professional_ambitions?.map(
        (ambition) => ambition.skill_level
      );
      setProfessionalAmbitions(skills);
      setProfessionalAmbitionsLevel(levels);
    };
    fetchAmbition();
  }, []);
  useEffect(() => {
    if (selectedSkill && user_token) {
      const skillLevelGap = async () => {
        const gap = await fetchSkillGap({ user_token, skill: selectedSkill });
        const level_gap = gap.skill_level_gap;
        const total_desirable_skill = gap.total_desirable_skills;
        setSkillGap(level_gap);
        setTotalDesirableSkills(total_desirable_skill);
      };
      skillLevelGap();
    } else {
      setSkillGap(0);
      setTotalDesirableSkills(0);
    }
  }, [selectedSkill]);

  const handleChange = (event) => {
    setSelectedSkill(event.target.value);
    setIsCardOpen(true);
  };

  const steps = [
    "Level 1",
    "Level 2",
    "Level 3",
    "Level 4",
    "Level 5",
    "Level 6",
    "Level 7",
  ];

  const series = [
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      data: [2, 5, 3, 4, 1],
    },
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      data: [5, 6, 2, 8, 9],
    },
    {
      type: "line",
      yAxisKey: "pib",
      color: "red",
      data: [1000, 1500, 3000, 5000, 10000],
    },
  ];
  function StepperCpm({ skillGap }) {
    const completedSteps = steps?.length - skillGap;
    return (
      <Stepper activeStep={completedSteps - 1} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index} completed={index < completedSteps}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }

  function RadioCpm({ skillGap }) {
    const completedLevels = 7 - skillGap;
    return (
      <>
        {Array.from({ length: 7 }).map((_, index) => (
          <Radio
            key={index}
            checked={index < completedLevels}
            value={`level-${index + 1}`}
            name="radio-buttons"
            inputProps={{ "aria-label": `Level ${index + 1}` }}
          />
        ))}
      </>
    );
  }

  //MENTOR MENTEE BUTTONS
  const [openM, setOpenM] = React.useState(true);
  const handleHoriMenu = (toggle) => () => {
    setOpenM(toggle);
  };
  const buttonStylesMentor = {
    color: openM ? `#FFFFFF` : `#808080`,
    backgroundColor: openM ? `rgba(246,90,76,1)` : "#FFFFFF",
    borderColor: openM ? "#FFA500" : "#808080",
    "&:hover": {
      backgroundColor: openM ? `rgba(246,90,76,1)` : `rgba(246,90,76,0.2)`,
      borderColor: openM ? "#FFA500" : "#808080",
    },
  };
  const buttonStylesMentee = {
    color: openM ? `#808080` : `#FFFFFF`,
    backgroundColor: openM ? "#FFFFFF" : `rgba(246,90,76,1)`,
    borderColor: openM ? "#808080" : "#FFA500",
    "&:hover": {
      backgroundColor: openM ? `rgba(246,90,76,0.2)` : `rgba(246,90,76,1)`,
      borderColor: openM ? "#808080" : "#FFA500",
    },
  };
  const [currentChartIndex, setCurrentChartIndex] = React.useState(0);
  const [chartsData, setChartsData] = useState([]);
  useEffect(() => {
    const handleRoleCount = async () => {
      try {
        const response = await getDesiredSkillsCount({ token: user_token });
        if (!response) throw new Error("No response from getDesiredSkillsCount");

        const data = await get_monthly_Course_Count({ token: user_token });
        if (!data || !Array.isArray(data)) throw new Error("Invalid session count data");
        console.log("dataaaaaaaaaaaaaaaaa", data)

        const allMonths = [
          { full: "January", short: "Jan" },
          { full: "February", short: "Feb" },
          { full: "March", short: "Mar" },
          { full: "April", short: "Apr" },
          { full: "May", short: "May" },
          { full: "June", short: "June" },
          { full: "July", short: "July" },
          { full: "August", short: "Aug" },
          { full: "September", short: "Sept" },
          { full: "October", short: "Oct" },
          { full: "November", short: "Nov" },
          { full: "December", short: "Dec" },
        ];


        const initialDataset = allMonths.map(({ full, short }, index) => ({
          month_short: short,
          month: full,
          month_number: (index + 1).toString().padStart(2, "0"),
          courses: 0,
          session: 0
        }));


        const apiData = data.map(({ year_month, total_courses, total_sessions }) => ({
          year_month,
          courses: total_courses || 0,
          session: total_sessions || 0
        }));

        console.log("initialDataset", initialDataset)
        console.log("apiData", apiData)

        const mergedDataset = initialDataset.map((item) => {
          const match = apiData.find((apiItem) => {
            // console.log("Comparing:", apiItem.year_month, "with", item.month_number);
            return +apiItem.year_month === +item.month_number
          }
          );

          console.log(match);
          return match ? { ...item, courses: match.courses, session: match.session } : item;
        });
        // console.log("mergedDataset", mergedDataset)

        const skills = [response.skill, ...response.top_5_skills.map((item) => item.skill)];
        const counts = [response.count, ...response.top_5_skills.map((item) => item.count)];

        const skillsChartData = {
          xAxis: [
            {
              scaleType: "band",
              data: skills.map((skill) =>
                skill.length > 20 ? `${skill.slice(0, 12)}...` : skill
              ),
            },
          ],
          series: [{ data: counts }],
          about:
            "Desired Roles Vs Market Trends: it highlights the user's desired skill and its frequency, offering a personalized view of its relevance, while also presenting a bar chart of the most in-demand skills in the market. This combination helps users understand how their interests align with current trends and identify key skills to focus on for career growth.",
        };


        const sessionCountChartData = {
          xAxis: [
            { scaleType: "band", data: mergedDataset.map((item) => item.month_short) },
          ],
          series: [{ data: mergedDataset.map((item) => item.courses) }, { data: mergedDataset.map((item) => item.session) }],
          about: "Monthly Learning Progress: It shows the number of courses completed and sessions attended each month, offering a comprehensive view of the user's learning journey. It highlights consistent efforts in both course completion and active participation in sessions, enabling users to identify trends over time. This insight helps users track their progress, evaluate their engagement, and align their learning journey with their career aspirations.",
        };


        setChartsData([skillsChartData, sessionCountChartData]);
      } catch (error) {
        console.error("Error in handleRoleCount:", error.message);
      }
    };


    handleRoleCount();

  }, [currentChartIndex]);

  console.log("skills chart", chartsData);



  const handleNextChart = () => {
    setCurrentChartIndex((prevIndex) => (prevIndex + 1) % chartsData?.length);
  };

  const handlePrevChart = () => {
    setCurrentChartIndex((prevIndex) =>
      prevIndex === 0 ? chartsData?.length - 1 : prevIndex - 1
    );
  };

  // const { user_token, updateSess } = userSess()
  const [appEventLength, setAppEventLength] = React.useState(0);
  const [start, setStart] = React.useState("");
  const [end, setEnd] = React.useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [skills, setSkills] = useState([]);
  const user_token = Cookies.get("connectedMe")
  const handleUpdateSkillsClick = () => {
    setOpenSkillSelection(true);
    setOpenPopup(false);
  };

  const handleSkillSelectionClose = () => {
    setOpenSkillSelection(false);
  };

  const [openSkillSelection, setOpenSkillSelection] = useState(false);
  React.useEffect(() => {
    const handleClick_cal = async () => {
      const app_events = await fetchAppointments({ token: user_token });
      if (app_events?.length === 0) {
        setStart("0/0/0000");
        setEnd("0/0/0000");
        setAppEventLength(0);
      }
      const start =
        app_events.startDate !== undefined
          ? app_events[0].startDate
          : new Date().getTime();
      setStart(handleDate(start));
      const end =
        app_events.endDate !== undefined
          ? app_events[app_events?.length - 1].endDate
          : new Date().getTime();
      setEnd(handleDate(end));
      setAppEventLength(app_events?.length);
    };
    const fetchSkills = async () => {
      const profile = await fetchProfile({ user_token: user_token });
      setSkills(profile.skills);

      if (profile.skills?.length === 0) {
        setTimeout(() => {
          setOpenPopup(true);
        }, 2000);
      }
    };

    handleClick_cal();
    fetchSkills();
  }, []);

  const handleDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };
  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const isXsScreen = useMediaQuery("(max-width:900px)");
  const smallScreen = useMediaQuery("(max-width:1200px)");

  const [limit, setLimit] = useState(smallScreen ? 3 : 5);
  const [page, setPage] = useState(0);
  const { sess, setuseUserPrefSess } = useUserPrefSess();

  const handleClick_ana = async () => {
    const body = {
      limit: limit,
      page: page,
    };
    try {
      const app_events = await fetchDashSession({
        token: user_token,
        ...body,
      });
      setuseUserPrefSess(app_events);
    } catch (error) {
      console.error("Failed to fetch session:", error);
    }
  };
  React.useEffect(() => {
    handleClick_ana();
  }, [limit, page]);

  const refreshData = async () => {
    if (sess?.length - 1 == 0) {
      console.log("hi");
      setPage(0);
    }
    handleClick_ana();
  };
  const [showAll, setShowAll] = useState(false);
  const [open, setOpen] = useState(false);
  const handleToggleShowAll = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setShowAll((prev) => !prev);
    setOpen(true);
  };
  const [isCardOpen, setIsCardOpen] = useState(false);

  const handleOpenCard = () => {
    setIsCardOpen(true);
  };

  const handleCloseCard = () => {
    setIsCardOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Update state on input change
  };

  const theme = useTheme();
  const isSmallScreen2 = useMediaQuery(theme.breakpoints.down("sm")); // For screens smaller than 'sm'
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const chartWidth = isSmallScreen2 ? 300 : isMediumScreen ? 600 : 800; // Adjust for screen sizes
  const chartHeight = isSmallScreen2 ? 150 : isMediumScreen ? 180 : 200;
  return (
    <>
      <Grid2 container direction={"column"} sx={{ padding: "25px" }}>
        <Stack spacing={2}>
          <Stack spacing={2} direction="row">
            <FormControl variant="standard" fullWidth lg={10} md={10.6} xs={12}>
              <InputLabel id="professional-ambitions-select-label">
                Your Desirable Skills
              </InputLabel>
              <Select
                labelId="professional-ambitions-select-label"
                id="professional-ambitions-select"
                value={selectedSkill}
                onChange={handleChange}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                MenuProps={{
                  PaperProps: {
                    onClick: (e) => e.stopPropagation(),
                  },
                }}
              >
                {professionalAmbitions
                  ?.slice(0, showAll ? professionalAmbitions?.length : 4)
                  ?.map((ambition, index) => (
                    <MenuItem key={index} value={ambition}>
                      <Badge
                        color="error"
                        badgeContent={professionalAmbitionsLevel[index]}
                      >
                        {ambition}
                      </Badge>
                    </MenuItem>
                  ))}
                {professionalAmbitions?.length > 4 && (
                  <MenuItem
                    style={{ textAlign: "center" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Button
                      color="secondary"
                      fullWidth
                      onClick={handleToggleShowAll}
                    >
                      {showAll ? "Show Less" : "Show More"}
                    </Button>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Stack>
          {selectedSkill &&
            skillGap !== null &&
            totalDesirableSkills !== null && (
              <Stack>
                <SkillCard
                  x={skillGap}
                  y={totalDesirableSkills}
                  skill_name={selectedSkill}
                  open={isCardOpen}
                  onClose={handleCloseCard}
                />
              </Stack>
            )}
          <Box sx={{ flexGrow: 1 }} />
          {isSmallScreen ? (
            <StepperCpm skillGap={skillGap} />
          ) : (
            <RadioCpm skillGap={skillGap} />
          )}
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
        <Stack direction="column" spacing={2}>
          <Divider orientation="horizontal" flexItem />
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="h4"
              color="#2f3348"
              sx={{
                fontWeight: "medium",
                paddingLeft: "15px",
                fontSize: {
                  xs: "1rem", // Smaller font size for extra-small screens (mobile)
                  sm: "h4.fontSize", // Default font size for small screens (sm) and above
                },
                mr: "10px"
              }}
            >
              My Progress
            </Typography>
            <Tooltip title="A progress session tracks your growth by showing completed sessions, courses, and role alignment throughout your journey."><InfoOutlinedIcon fontSize="small" /></Tooltip>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={3}
            padding={2}

          >
            <Stack
              sx={{
                width: { xs: "100%", sm: "85%" }, height: "100%", position: "relative",
                boxShadow: "0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)",
                border: "1px solid #8a91b0",
                borderRadius: "4px"
              }}
            >
              <Card
                sx={{
                  background: "#FAF9F6",
                }}
              >
                <Stack
                  height={{ xs: 150, sm: 200 }} // Smaller height for small screens, keep original height for desktop
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* Arrow for previous chart */}
                  <ArrowBackIosIcon
                    onClick={handlePrevChart}
                    sx={{
                      color: "#f65a4c",
                      cursor: "pointer",
                      position: "absolute",
                      left: 10,
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1, // Ensure the arrow is above other elements
                    }}
                  />
                  <Stack
                    sx={{
                      height: "100vh", // Full viewport height
                      display: "flex",
                      alignItems: "center", // Align items horizontally
                      justifyContent: "center", // Center items vertically
                      width: "100%", // Full width to ensure centering
                    }}
                  >
                    {chartsData.length > 0 && chartsData[currentChartIndex] ? (
                      <BarChart
                        xAxis={chartsData[currentChartIndex]?.xAxis} // Dynamically passed chart data
                        series={chartsData[currentChartIndex]?.series}
                        width={chartWidth}
                        height={chartHeight}

                      // width={800} // Responsive width: max 800px or 90% of screen
                      // height={200} // Responsive height: max 400px or 50% of screen
                      />

                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100px"
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Stack>

                  {/* Arrow for next chart */}
                  <ArrowForwardIosIcon
                    onClick={handleNextChart}
                    sx={{
                      color: "#f65a4c",
                      cursor: "pointer",
                      position: "absolute",
                      right: 10,
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1, // Ensure the arrow is above other elements
                    }}
                  />
                </Stack>

                <Divider />
                <Stack
                  sx={{
                    backgroundColor: "primary.main",
                    height: { xs: "150px", sm: "100px" }, // Adjust height for small screens
                    overflowY: "auto", // Allow vertical scrolling
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar for WebKit browsers (e.g., Chrome, Safari)
                    },
                    msOverflowStyle: "none", // Hide scrollbar for Internet Explorer and Edge
                    scrollbarWidth: "none", // Hide scrollbar for Firefox
                    // boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)"
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      "margin-left": "10px",
                      "margin-right": "10px",
                      fontSize: { xs: "0.8rem", sm: "0.9rem" }, // Adjust font size for small screens
                    }}
                  >
                    {chartsData.length > 0 && chartsData[currentChartIndex] && (<p>{chartsData[currentChartIndex].about}</p>)}

                  </Typography>
                </Stack>
              </Card>
            </Stack>

            {/* Adjust layout of cards for small screens */}
            <Stack
              sx={{
                width: { xs: "100%", sm: "30%", md: "20%" },
                height: "100%",
                position: "relative",
                boxShadow: "0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)",
                border: "1px solid #8a91b0",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <Card
                sx={{
                  background: "#FAF9F6",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* Header Title */}
                <Typography
                  fontSize={{ xs: "1rem", sm: "1.2rem" }}
                  fontWeight="bold"
                  color="#2f3348"
                  textAlign="center"
                  mt={2}
                  mb={2}
                  sx={{
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  My Skill Quotient
                </Typography>

                {/* Score Circle */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "primary.main",
                    borderRadius: "50%",
                    width: { xs: "80px", sm: "100px", md: "100px" },
                    height: { xs: "80px", sm: "100px", md: "100px" },
                    boxShadow: "inset 0 2px 6px rgba(0, 0, 0, 0.3)",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h3"
                    fontSize={{ xs: "2rem", sm: "2.5rem", md: "3rem" }}
                    fontWeight="bold"
                    color="#FAF9F6"
                  >
                    {appEventLength}
                  </Typography>
                </Box>

                {/* Description */}
                <Stack
                  sx={{
                    backgroundColor: "primary.main",
                    padding: { xs: 1, sm: 2 },
                    width: "100%",
                    color: "#FFFFFF",
                    mt: -1,
                    height: { xs: "auto", sm: "100px" },
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      px: { xs: 1, sm: 2 },
                      fontSize: { xs: "0.8rem", sm: "0.9rem" },
                      textAlign: "left",
                    }}
                  >
                    SQ reflects an individual's digital skills and expertise, highlighting their experience with technologies and participation in skill-building activities.
                  </Typography>
                </Stack>
              </Card>
            </Stack>


          </Stack>

          <Box sx={{ flexGrow: 1 }} />
        </Stack>

        <Divider orientation="horizontal" flexItem />

        <Stack sx={{ marginBottom: "20px", marginTop: "20px" }}>
          <Stack direction="column" gap={3}>
            <Stack sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant={isXsScreen ? "h6" : "h4"} // Adjust variant based on screen size
                  color="#2f3348"
                  sx={{ fontWeight: "medium", paddingLeft: "15px", mr: "10px" }}
                >
                  Recommended Mentors
                </Typography>
                <Tooltip title="This section recommends mentors based on your desired skill set, connecting you with experts who can guide you toward achieving your career goals."><InfoOutlinedIcon fontSize="small" /></Tooltip>
              </Stack>
              <Stack
              // direction="row"
              // justifyContent="center" // Center "More items..."
              // sx={{ marginTop: "20px" }}
              >
                <Link
                  component={RouterLink}
                  to="/d/UserRecom"
                  sx={{
                    textAlign: "center",
                    fontSize: isXsScreen ? "0.875rem" : "1rem",
                  }} // Adjust font size for small screens
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginBottom: 2 }}
                  >
                    View More
                  </Button>
                </Link>
              </Stack>
            </Stack>
            <Stack>
              <HoriMentorSlide />
            </Stack>

          </Stack>

          <Divider sx={{ mt: "20px" }} />
        </Stack>

        <Stack sx={{ marginBottom: "20px" }}>
          <Stack direction="column" gap={3}>
            <Stack
              direction={isXsScreen ? "column" : "row"} // Change direction for small screens
              alignItems={isXsScreen ? "flex-start" : "center"}
              spacing={isXsScreen ? 0 : 1}
            >
              <Stack sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Stack sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant={isXsScreen ? "h6" : "h4"} // Adjust font size for small screens
                    color="#2f3348"
                    sx={{ fontWeight: "medium", paddingLeft: "15px", mr: "10px" }}
                  >
                    Recommended Courses
                  </Typography>
                  <Tooltip title="This section recommends courses tailored to your desired skill set, helping you gain the knowledge and expertise needed to advance your career."><InfoOutlinedIcon fontSize="small" /></Tooltip>
                </Stack>

                <Stack>
                  <Link
                    component={RouterLink}
                    to="/d/UserCourse"
                    sx={{
                      textAlign: "center",
                      fontSize: isXsScreen ? "0.875rem" : "1rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ marginBottom: 2 }}
                    >
                      View More
                    </Button>
                  </Link>
                </Stack>
              </Stack>
              {/* <Stack
                direction="row"
                alignItems="center"
              // sx={{ paddingTop: isXsScreen ? "10px" : "0" }} // Add spacing for small screens
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    display: "flex",

                    paddingLeft: isXsScreen ? "15px" : "10px",
                    fontSize: isXsScreen ? "0.75rem" : "1rem",
                  }}
                >
                  Powered by
                </Typography>
                <img
                  alt="Allison"
                  src="/alison.svg"
                  style={{
                    width: isXsScreen ? "40px" : "60px",
                    height: isXsScreen ? "30px" : "50px",
                  }}
                />
              </Stack> */}
            </Stack>
            <Box mt={-2} ml={2}>
              <TextField
                placeholder="Search course"
                variant="outlined"
                value={searchTerm} // Set value from state
                onChange={handleSearchChange} // Update search term on change
                sx={{
                  maxWidth: "400px",
                  marginBottom: "10px",
                  "& .MuiOutlinedInput-root": {
                    height: "35px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />{" "}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Stack>
              <HoriCourseSlide searchTerm={searchTerm} />
            </Stack>

          </Stack>

          <Divider sx={{ mt: "20px" }} />
        </Stack>

        <Stack sx={{ marginBottom: "20px" }}>
          <Stack
            direction="column"
            sx={{ marginTop: "10px" }}
          >
            <Stack direction="row" sx={{ marginBottom: "20px" }}>
              <Stack sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Stack sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant={isXsScreen ? "h6" : "h4"}
                    color="#2f3348"
                    sx={{ fontWeight: "medium", paddingLeft: "15px", mr: "10px" }}
                  >
                    Recommended Group Session
                  </Typography>
                  <Tooltip title="This section showcases all sessions and workshops organized by the mentor, available for everyone. These events cover various topics and are designed to provide learning opportunities for everyone, regardless of experience level."><InfoOutlinedIcon fontSize="small" /></Tooltip>
                </Stack>
                <Stack
                >
                  <Link
                    component={RouterLink}
                    to="/d/UserSess"
                    sx={{ textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ marginBottom: 2 }}
                    >
                      View More
                    </Button>
                  </Link>
                </Stack>
              </Stack>
              <Stack direction="row" sx={{ marginLeft: "auto" }}>
                {/* <ButtonGroup
                  variant="outlined"
                  size={isXsScreen ? "small" : "medium"}
                >
                  <Button
                    sx={{
                      ...buttonStylesMentor,
                      padding: isXsScreen ? "4px 8px" : "6px 16px",
                      fontSize: isXsScreen ? "0.75rem" : "1rem", 
                    }}
                    onClick={handleHoriMenu(true)}
                  >
                    <Typography
                      sx={{ fontSize: isXsScreen ? "0.75rem" : "1rem" }}
                    >
                      Mentee
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      ...buttonStylesMentee,
                      padding: isXsScreen ? "4px 8px" : "6px 16px", // Adjust padding
                      fontSize: isXsScreen ? "0.75rem" : "1rem", // Adjust font size
                    }}
                    onClick={handleHoriMenu(false)}
                  >
                    <Typography
                      sx={{ fontSize: isXsScreen ? "0.75rem" : "1rem" }}
                    >
                      Mentor
                    </Typography>
                  </Button>
                </ButtonGroup> */}
              </Stack>
            </Stack>
            <Stack>
              {openM ? (
                <HoriSlide
                  page={page}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  refreshData={refreshData}
                />
              ) : (
                <HoriSlide_mentor />
              )}
            </Stack>
            <Divider sx={{ mt: "20px" }} />
          </Stack>
        </Stack>
      </Grid2>
      <Dialog open={openPopup} onClose={() => { }}>
        <DialogTitle>Update Your Skills</DialogTitle>
        <DialogContent>
          <DialogContentText>
            It looks like you haven't updated your Role yet. Please update your
            role to get the most out of our platform.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUpdateSkillsClick}
            variant="contained"
            color="secondary"
          >
            Update Role
          </Button>
        </DialogActions>
      </Dialog>
      <SkillSelectionPopup
        open={openSkillSelection}
        handleClose={handleSkillSelectionClose}
      />
    </>
  );
}
export default User_analytics_dash;
