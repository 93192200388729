import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { updateSessionNotification } from '../../api/notification_related_service';
import { Rating } from '@mui/material';
import { display, Stack } from '@mui/system';
import Cookies from 'js-cookie';

const Dialogue = (props) => {

    const user_token = Cookies.get("connectedMe")
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const hadnleClick = async () => {
        const response = await updateSessionNotification({ meeting_id: props.meeting_id, token: user_token })
        props.onClose()
        props.setUnreadCount(props.unreadCount - 1)
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="responsive-dialog-title"
            >
                {/* <DialogTitle id="responsive-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}
                <DialogContent sx={{display:"flex", justifyContent:"center", flexDirection:'column'}}>
                    <DialogContentText>
                    Hey! Looks like there was a session not too long ago—did you make it? And what’s your rating for it?
                    </DialogContentText>
                    <Stack sx={{mt:"10px"}}>
                        <Rating name="half-rating" defaultValue={0} precision={0.5} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} autoFocus >
                        No
                    </Button>
                    <Button onClick={hadnleClick} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Dialogue
