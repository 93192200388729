import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Stack, Tooltip, Typography } from "@mui/material";
import MenteeList from "./MenteeList";
import MenteePost from "./MenteePost";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
function ProgressTracer() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Stack>
        <Stack sx={{ display: "flex", flexDirection: "row", margin: '20px 0 0 20px' }}>
          <Typography variant="h4" >
            ProgressTracer
          </Typography>
          <Tooltip sx={{ ml: "10px" }} title="Track your growth with detailed insights on progress and empower mentees to ask for upgradation in their desired roles."><InfoOutlinedIcon fontSize="small" /></Tooltip>
        </Stack>
        <Typography
          variant="subtitle2"
          sx={{ margin: "0 0 20px 20px", color: "grey" }}
        >
          Track your impact and monitor mentee growth, with insights on progress
          and personalized feedback.
        </Typography>
      </Stack>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{ width: "100%" }}
            >
              <Tab
                label="Mentee Level Management"
                value="1"
                sx={{ width: "50%" }}
              />
              <Tab
                label="Request Level-Up Post"
                value="2"
                sx={{ width: "50%" }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <MenteeList />
          </TabPanel>
          <TabPanel value="2">
            <MenteePost />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default ProgressTracer;
