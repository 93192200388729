import { Box, Card, CardContent, Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import React, { useEffect, useState } from 'react';
// import { dataset, valueFormatter } from '../KPI/data.ts';
import { EventNoteOutlined } from '@mui/icons-material';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { LineChart } from '@mui/x-charts';
import { PieChart } from '@mui/x-charts/PieChart';
import { getParticipantCountMonthly, getreschedulingCount, getSessionCountMonthly } from '../../api/session_service.js';
import { valueFormatters } from '../KPI/data_2.ts';
import { Satisfaction, valueFormat } from '../KPI/data_3.ts';
// import { log } from 'console';
import Cookies from 'js-cookie';

const chartSetting = {
    yAxis: [
        {
            label: 'No. of Sessions',
        },
    ],
    series: [{ dataKey: 'seoul', label: 'Total Sessions' }],
    height: 300,
    sx: {
        [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
            transform: 'translateX(-10px)',
        },
    },
};

const data = [
    { agent: 'Julie B.', averageScore: 4.9 },
    { agent: 'Aziz R.', averageScore: 4.8 },
    { agent: 'Josh L.', averageScore: 4.6 },
    { agent: 'Christina A.', averageScore: 4.6 }
];

const Mentee_KPI = () => {
    const [radius, setRadius] = useState(30);
    const [itemNb, setItemNb] = useState(5);
    const [skipAnimation, setSkipAnimation] = useState(false);
    const user_token = Cookies.get("connectedMe")
    const [totalSession, setTotalSession] = useState();
    const [totalParticipant, setTotalParticipant] = useState();
    const handleItemNbChange = (event, newValue) => {
        if (typeof newValue !== 'number') {
            return;
        }
        setItemNb(newValue);
    };
    const handleRadius = (event, newValue) => {
        if (typeof newValue !== 'number') {
            return;
        }
        setRadius(newValue);
    };

    const generatePlatformData = async () => {
        try {

            const response = await getreschedulingCount({ token: user_token });
            const { rescheduling_count: reschedulingCount, total_count: totalCount, total_participant: totalmentee } = response;

            console.log("API Response:", response);


            const validReschedulingCount = Number(reschedulingCount) || 0;
            const validTotalCount = Number(totalCount) || 0;
            const validParticipant = Number(totalmentee) || 0;
            setTotalSession(validTotalCount);
            setTotalParticipant(validParticipant)

            const successfulCount = validTotalCount - validReschedulingCount;


            const reschedulingRate =
                validTotalCount > 0 ? ((validReschedulingCount / validTotalCount) * 100).toFixed(2) : '0.00';
            const successRate =
                validTotalCount > 0 ? ((successfulCount / validTotalCount) * 100).toFixed(2) : '100.00';


            const data = [
                { label: 'Reschedule', value: parseFloat(reschedulingRate), formattedValue: `${reschedulingRate}%` },
                { label: 'Successful', value: parseFloat(successRate), formattedValue: `${successRate}%` },
            ];

            console.log("Generated Platform Data:", data);
            return data;

        } catch (error) {

            console.error('Error generating platform data:', error);

            return [
                { label: 'Rescheduling Rate', value: 0, formattedValue: '0%' },
                { label: 'Successful Rate', value: 0, formattedValue: '0%' },
            ];
        }
    };
    const [platformData, setPlatformData] = useState([]);
    const [dataset, setDataset] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await generatePlatformData(user_token);
            setPlatformData(data);
        };
        fetchData();
    }, [user_token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getSessionCountMonthly({ token: user_token });
                console.log("Data:", data);

                const allMonths = [
                    { full: "January", short: "Jan" },
                    { full: "February", short: "Feb" },
                    { full: "March", short: "Mar" },
                    { full: "April", short: "Apr" },
                    { full: "May", short: "May" },
                    { full: "June", short: "June" },
                    { full: "July", short: "July" },
                    { full: "August", short: "Aug" },
                    { full: "September", short: "Sept" },
                    { full: "October", short: "Oct" },
                    { full: "November", short: "Nov" },
                    { full: "December", short: "Dec" },

                ];
                const initialDataset = allMonths.map(({ full, short }) => ({
                    month_short: short,
                    month: full,
                    sessions: 0,
                }));

                if (data && data.total_count !== null) {

                    const apiData = Object.entries(data.total_count).map(([month, count]) => ({
                        month,
                        sessions: count || 0,
                    }));


                    const mergedDataset = initialDataset.map((item) => {
                        const match = apiData.find((apiItem) => apiItem.month === item.month);
                        return match ? { ...item, sessions: match.sessions } : item;
                    });

                    setDataset(mergedDataset);
                } else {
                    console.warn("no data or incorrect format.");
                    setDataset(initialDataset);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setDataset([]);
            }
        };

        fetchData();
    }, [user_token]);


    const [chartData, setChartData] = useState([]);

    useEffect(() => {

        const fetchParticipantData = async () => {
            try {
                const response = await getParticipantCountMonthly({ token: user_token });
                const transformedData = Array.from({ length: 12 }, (_, i) => {
                    const month = (i + 1).toString().padStart(2, "0");
                    const entry = response.total_count.find(item => item.year_month === month);
                    return entry ? entry.total : 0;
                });

                setChartData(transformedData);
            } catch (error) {
                console.error("Failed to fetch participant data:", error);
            }
        };

        fetchParticipantData();
    }, []);


    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    return (
        <>
            {dataset && dataset.length !== 0 && (
                <>
                    <Stack>
                        <Stack sx={{ display: "flex", flexDirection: "row", margin: '20px 0 0 20px' }}>
                            <Typography variant="h4" >
                                KPI Dasboard
                            </Typography>
                            <Tooltip sx={{ ml: "10px" }} title="Visit the KPI Dashboard to track your mentor progress with key performance indicators, providing insights into growth and areas of improvement."><InfoOutlinedIcon fontSize="small" /></Tooltip>
                        </Stack>
                        <Typography
                            variant="subtitle2"
                            sx={{ margin: "0 0 20px 20px", color: "grey" }}
                        >
                            Dashboard to track Mentor & Employee engagement.
                        </Typography>
                    </Stack>
                    <Stack display={"flex"} flexDirection={"column"} margin={5} justifyContent={'flex-start'} >
                        <Box sx={{ padding: 2 }}>
                            <Stack
                                direction={isSmallScreen ? "column" : "row"}
                                spacing={isSmallScreen ? 2 : 5}
                                marginRight={isSmallScreen ? 0 : 5}
                            >
                                <Stack
                                    direction="column"
                                    spacing={isSmallScreen ? 2 : 4}

                                >

                                    <Paper

                                        sx={{
                                            background: "#FAF9F6",
                                            width: isSmallScreen ? "100%" : "18.125rem",
                                            height: "220px",
                                            border: "1px solid black",
                                            marginBottom: "70px !important"
                                        }}
                                    >
                                        <Stack marginLeft={2} marginTop={2} marginRight={2} direction={"row"} justifyContent={'space-between'}>
                                            <Typography variant="h5" gutterBottom>
                                                <EventNoteOutlined fontSize="medium" /> Total Sessions
                                            </Typography>
                                            <Tooltip title="Total number of sessions Scheduled."><InfoOutlinedIcon /></Tooltip>

                                        </Stack>

                                        <Divider sx={{ borderColor: 'black' }} />
                                        <Card
                                            sx={{
                                                background: "#e2edf9",
                                                height: "74%",
                                                width: "100%",

                                            }}
                                        >
                                            <CardContent
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >

                                                <Typography variant="h1" color="#3A4856">
                                                    {totalSession}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Paper>
                                    <Paper
                                        sx={{
                                            background: "#FAF9F6",
                                            width: isSmallScreen ? "100%" : "18.125rem",
                                            height: "220px",
                                            marginTop: isSmallScreen ? "0" : "100px",
                                            border: "1px solid black",

                                        }}
                                    >
                                        <Stack marginLeft={2} marginTop={2} marginRight={2} direction={'row'} justifyContent={'space-between'}>
                                            <Typography variant="h5" gutterBottom>
                                                <PersonOutlineIcon fontSize="medium" /> Total Participants
                                            </Typography>
                                            <Tooltip title="Total number of participants attended the session.">
                                                <InfoOutlinedIcon />
                                            </Tooltip>
                                        </Stack>

                                        <Divider sx={{ borderColor: 'black' }} />
                                        <Card
                                            sx={{
                                                background: "#f8f8a7",
                                                height: "74%",
                                                width: "100%"
                                            }}
                                        >
                                            <CardContent
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >


                                                <Typography variant="h1" color="#3A4856">
                                                    {totalParticipant}
                                                </Typography>

                                            </CardContent>
                                        </Card>
                                    </Paper>

                                </Stack>
                                <Stack
                                    direction={"column"}
                                    spacing={4}
                                    marginRight={isSmallScreen ? 0 : 5}
                                >
                                    <Paper
                                        sx={{
                                            background: "#FAF9F6",
                                            width: isSmallScreen ? "100%" : "50vw",
                                            // width: "50vw",
                                            height: "290px",
                                            // marginRight: "40px",
                                            // marginLeft: "30px",
                                            border: "1px solid black"
                                        }}
                                    >
                                        <Stack marginLeft={2} marginTop={2} marginRight={2} direction={'row'} justifyContent={'space-between'}>
                                            <Typography variant="h5" gutterBottom>
                                                < EqualizerOutlinedIcon fontSize="medium" /> No. of Sessions Scheduled
                                            </Typography>
                                            <Tooltip title="Displays the total number of sessions scheduled each month throughout the year."><InfoOutlinedIcon /></Tooltip>
                                        </Stack>
                                        <Divider sx={{ borderColor: 'black' }} />
                                        <BarChart
                                            dataset={dataset}
                                            xAxis={[{ scaleType: "band", dataKey: "month_short" }]}

                                            series={[
                                                {
                                                    dataKey: "sessions",
                                                    label: "Total Sessions",
                                                },
                                            ]}
                                            height={250}
                                        />
                                    </Paper>
                                    <Paper
                                        sx={{
                                            background: "#FAF9F6",
                                            width: isSmallScreen ? "100%" : "50vw",
                                            height: "260px",
                                            border: "1px solid black"
                                        }}
                                    >
                                        <LineChart

                                            xAxis={[{
                                                data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                                                label: "Months"
                                            }]}
                                            series={[
                                                {
                                                    data: chartData,
                                                    label: "No of Participants",
                                                },
                                            ]}

                                            height={250}
                                        />
                                    </Paper>
                                </Stack>

                            </Stack>
                        </Box>

                        <Stack
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={isSmallScreen ? 2 : 4}
                            marginTop={5}
                        >
                            <Paper
                                sx={{
                                    background: "#FAF9F6",
                                    width: isSmallScreen ? "100%" : "320px",
                                    height: "325px",
                                    marginRight: isSmallScreen ? "0" : "50px",
                                    border: "1px solid black",
                                    // overflow: "hidden",
                                    // textOverflow: "ellipsis",
                                }}
                            >
                                <Stack marginLeft={2} marginTop={2} >
                                    <Typography variant="h5" gutterBottom>
                                        < InsightsOutlinedIcon fontSize="medium" />  Top Performing Mentors
                                    </Typography>

                                </Stack>
                                <Divider sx={{ borderColor: 'black' }} />
                                <Stack>
                                    <TableContainer sx={{
                                        maxHeight: "250px",
                                        // overflowY: "auto",
                                    }}>
                                        <Table >
                                            <TableHead>
                                                <TableRow sx={{ backgroundColor: '#2f3348' }}>
                                                    <TableCell sx={{ color: 'white' }}>Agent</TableCell>
                                                    <TableCell sx={{ color: 'white' }}>Average Score</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow key={row.agent}>
                                                        <TableCell component="th" scope="row">
                                                            {row.agent}
                                                        </TableCell>
                                                        <TableCell >{row.averageScore}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Stack>
                            </Paper>
                            <Paper
                                sx={{
                                    background: "#FAF9F6",
                                    width: isSmallScreen ? "100%" : "28.75vw",
                                    height: "320px",
                                    marginLeft: isSmallScreen ? "0" : "50px",
                                    border: "1px solid black",
                                }}
                            >
                                <Stack marginLeft={2} marginTop={2} marginRight={2} direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="h5" gutterBottom>
                                        < AvTimerOutlinedIcon fontSize="medium" />   Missed Sessions
                                    </Typography>
                                    <Tooltip title="Any changes in meeting timings or cancellations are reflected here in real-time."><InfoOutlinedIcon /></Tooltip>
                                </Stack>
                                <Divider sx={{ borderColor: 'black' }} />

                                <PieChart sx={{ marginTop: "20px" }}
                                    height={isSmallScreen ? 180 : 220}
                                    series={[
                                        {
                                            data: platformData,
                                            innerRadius: isSmallScreen ? "40%" : radius,
                                            arcLabelMinAngle: 20,
                                            valueFormatters,
                                        },
                                    ]}
                                    skipAnimation={skipAnimation}
                                    labelStyle={{
                                        fontSize: isSmallScreen ? "14px" : "18px",
                                        fontFamily: "Arial, sans-serif",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                />

                            </Paper>
                            <Paper
                                sx={{
                                    background: "#FAF9F6",
                                    width: isSmallScreen ? "100%" : "35.75vw",
                                    height: "320px",
                                    marginLeft: isSmallScreen ? "0" : "50px",
                                    border: "1px solid black",
                                    overflow: "hidden",

                                }}
                            >
                                <Stack marginLeft={2} marginTop={2} marginRight={2} direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant="h5" gutterBottom>
                                        < LocalActivityOutlinedIcon fontSize="medium" />   Mentee Satisfaction
                                    </Typography>
                                    <Tooltip title="Shows mentee satisfaction levels based on feedback, including raised concerns."><InfoOutlinedIcon /></Tooltip>
                                </Stack>
                                <Divider sx={{ borderColor: 'black' }} />
                                <Stack>
                                    <PieChart sx={{ marginTop: "20px" }}
                                        height={isSmallScreen ? 180 : 220}
                                        series={[
                                            {
                                                data: Satisfaction.slice(0, itemNb),
                                                innerRadius: isSmallScreen ? "40%" : radius,
                                                // arcLabel: (params) => params.label ?? '',
                                                arcLabelMinAngle: 20,
                                                valueFormat,
                                            },
                                        ]}
                                        skipAnimation={skipAnimation}
                                        labelStyle={{
                                            fontSize: isSmallScreen ? "14px" : "18px",
                                            fontFamily: "Arial, sans-serif",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    />
                                </Stack>
                            </Paper>

                        </Stack>
                    </Stack>
                </>)}

        </>
    )
}

export default Mentee_KPI
