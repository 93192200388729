import axios from "axios";
import network_conn from "../utils/network_config";

export const insertCareerPath = async (plan) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}career_path/insert`,
      plan,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Data sent successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error.message);
    throw error;
  }
};

export const fetchCareerPath = async (fetchreq) => {
  console.log(fetchreq);
  try {
      const response = await axios.post(network_conn.api_endpoint_v1+'career_path/fetch', fetchreq).catch(error => {
          console.error('Error fetching profile:', error);
          throw error;
      });

      return response;
  } catch (error) {
      console.error('Error fetching plan path:', error);
      throw error;
  }
};
export const addPlan = async (plan) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}plan_path/insert_plan`,
      plan,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Data sent successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error.message);
    throw error;
  }
};

export const addExistingPlan = async (plan) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}plan_path/insert_existing_plan`,
      plan,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Data sent successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error.message);
    throw error;
  }
};

export const fetch_plan_path = async (fetchreq) => {
    console.log(fetchreq);
    try {
        const response = await axios.post(network_conn.api_endpoint_v1+'plan_path/fetch_plan_path', fetchreq).catch(error => {
            console.error('Error fetching profile:', error);
            throw error;
        });

        return response;
    } catch (error) {
        console.error('Error fetching plan path:', error);
        throw error;
    }
};

export const deleteplanPath = async (plan) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}plan_path/delete_plan_path`,
      plan,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting plan path:", error.message);
    return false;
  }
};
