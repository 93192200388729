import { React, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Tooltip,
  useMediaQuery,
  Chip,
  ButtonBase,
  CircularProgress,
} from "@mui/material";
import DrawIcon from "@mui/icons-material/Draw";
import { Link } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  fetchBookedCourse,
  DeleteBookedCourse,
} from "../../api/course_publish_related_service";
import { MoreVert } from "@mui/icons-material";
import {
  Edit_course_status,
  Get_course_status,
} from "../../api/recommendation_related_service";
import AlertPopup from "../Common_Components/AlertPopup";
import CustomCard from "../Common_Components/Card";
import EmptyCard from "../Common_Components/emptyCard";
import Cookies from 'js-cookie';


function BookedCourses({ searchTerm }) {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [bookedCourses, setBookedCourses] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const user_token = Cookies.get("connectedMe")
  const [filteredBookedCourse, setFilteredBookedCourse] = useState([]);
  const [courseId, setCourseId] = useState();

  const handleClick = async (courseData) => {
    if (courseData?.courseStatus === "Start Course") {
      courseData.courseStatus = "In Progress";
    } else if (courseData?.courseStatus === "In Progress") {
      courseData.courseStatus = "Mark as Complete";
    } else if (courseData?.courseStatus === "Mark as Complete") {
      courseData.courseStatus = "Completed";
    }
    if (user_token) {
      try {
        setCourseId(courseData.course_id);
        // setStats()
        await Edit_course_status({
          token: user_token,
          course_id: courseData.course_id,
          status: courseData.courseStatus,
        });
        setCounter((prev) => prev + 1);
      } catch (error) {
        console.error("Error updating the status:", error);
      }
    } else {
      console.error("User token is missing when booking the course.");
    }
  };

  const get_course_status = async () => {
    try {
      const response = await Get_course_status({ token: user_token });

      if (!Array.isArray(response.status)) {
        console.error("Invalid response format: 'status' is not an array.");
        return;
      }

      setBookedCourses((prevCourses) => {
        const new_courses = [];
        prevCourses?.forEach((course) => {
          const matchingStatus = response?.status?.find(
            (StatusObj) => StatusObj?.course_id === course?.course_id
          );
          const data = { ...course, courseStatus: "Start Course" };
          if (matchingStatus) {
            data["courseStatus"] = matchingStatus?.status;
          }
          new_courses.push(data);
        });
        return new_courses;
      });
    } catch (error) {
      console.error("Error getting the status:", error);
    }
  };

  // React.useEffect(() => {
  //   handleClick();
  // }, []);

  useEffect(() => {
    fetchCourses().then(get_course_status);
  }, [counter]);

  const fetchCourses = async () => {
    const user_token = Cookies.get("connectedMe")
    try {
      setIsLoading(true);
      const response = await fetchBookedCourse({ token: user_token });
      if (response && response.data && Array.isArray(response.data)) {
        setBookedCourses(response.data);
      } else {
        setBookedCourses([]);
      }
    } catch (error) {
      console.error("Error fetching booked courses:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Filter sessions based on the search query
    const results = bookedCourses.filter((course) =>
      course.course_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBookedCourse(results);
  }, [searchTerm, bookedCourses]);
  const handleDelete = async (course_id) => {
    const user_token = Cookies.get("connectedMe")
    try {
      const response = await DeleteBookedCourse({
        token: user_token,
        course_id: course_id,
      });
      if (true) {
        setSnackbarMessage("Course successfully removed");
        setSnackbarOpen(true);

        // Re-fetch courses to update the list after successful deletion
        fetchCourses();
      } else {
        console.error("Failed to delete the booked course");
      }
    } catch (error) {
      console.error("Error deleting booked course:", error);
      setSnackbarMessage("Failed to remove course");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1400px",
        margin: "0 auto",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "16px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <CircularProgress />
          </Box>
        ) : filteredBookedCourse.length > 0 ? (
          filteredBookedCourse?.map((courseData, index) => (
            <CustomCard key={index}>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid #2f3348",
                  flexWrap: "wrap",
                  overflow: "hidden",
                  position: "relative",
                  paddingBottom: 1,
                }}
              >
                <Tooltip title={courseData.course_name}>
                  <ButtonBase
                    component={Link}
                    to={courseData.course_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    // disabled={courseData.status !== "booked"}
                    // onClick={() => handleClick(courseData)}
                    sx={{
                      display: "inline-block",
                      textAlign: "left",
                      width: "auto",
                      padding: 0, // Ensure no extra padding from ButtonBase
                      // '&:disabled': { opacity: 0.5 },
                    }}
                  >
                    <Typography
                      variant="h3"
                      component="div"
                      noWrap
                      sx={{
                        fontSize: "inherit",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        color: "primary.main",
                        textDecoration: "none",
                      }}
                    >
                      {courseData.course_name.split(" ").slice(0, 2).join(" ") +
                        (courseData.course_name.split(" ").length > 2
                          ? "..."
                          : "")}
                    </Typography>
                  </ButtonBase>
                </Tooltip>
                <Chip
                  sx={{
                    marginTop: "10px",
                    fontSize: "0.7rem", // Smaller font size
                    height: "18px", // Smaller height
                    minWidth: "35px", // Adjust width if needed
                    padding: "0 4px", // Smaller padding
                    right: 0,
                    maxWidth: "6rem",
                    // position: "absolute",
                  }}
                  label={
                    courseData?.courseStatus === "Mark as Complete"
                      ? "In Progress"
                      : courseData?.courseStatus
                  }
                  size="small"
                  color={
                    courseData?.courseStatus === "Start Course"
                      ? "info"
                      : "success"
                  }
                />
              </Box>

              <Box
                sx={{
                  flex: 1,
                  overflow: "auto",
                  p: 1.5,
                  mb: 12,
                  "&::-webkit-scrollbar": { width: "6px" },
                  "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "0.875rem" }}
                >
                  {courseData.course_details.length > 200
                    ? `${courseData.course_details.substring(0, 200)}...`
                    : courseData.course_details}
                </Typography>
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  p: 1.25,
                  borderTop: "1px solid #D3D3D3",
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  backgroundColor: "white",
                }}
              >
                <Tooltip title="UNBOOK COURSE">
                  <Button
                    variant="outlined"
                    onClick={() => handleDelete(courseData.course_id)}
                    sx={{
                      border: "1px solid #2f3348",
                      fontSize: "0.75rem",
                      padding: "6px 12px",
                    }}
                  >
                    <DeleteOutlineIcon sx={{ fontSize: "1.2rem" }} />
                  </Button>
                </Tooltip>
                <Button
                  variant="contained"
                  color="secondary"
                  component={
                    courseData?.courseStatus === "Mark as Complete" ||
                    courseData?.courseStatus === "In Progress"
                      ? Button
                      : Link
                  }
                  to={courseData.course_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleClick(courseData)}
                >
                  {courseData?.courseStatus && courseData?.courseStatus}
                </Button>
              </Box>
            </CustomCard>
          ))
        ) : (
          <EmptyCard
            message="No Courses Available"
            icon={
              <DrawIcon
                sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
              />
            }
          />
        )}
      </Box>
      {/* Snackbar for success/error messages */}
      <AlertPopup
        timer={3000}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        type="info"
        message={snackbarMessage}
      />
    </Box>
  );
}

export default BookedCourses;
