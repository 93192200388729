import axios from "axios";
import network_conn from "../utils/network_config";

export const delete_account = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}delete_account`,
      fetchreq,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Account deletion response:", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // The server responded with a status code outside of 2xx range
      const { status, statusText, data } = error.response;
      const errorMessage =
        data && data.message
          ? data.message
          : `Unable to parse error response.`;
      console.error(
        `Failed to delete account: ${status} ${statusText} - ${errorMessage}`
      );
      throw new Error(
        `Failed to delete account: ${status} ${statusText} - ${errorMessage}`
      );
    } else {
      // Network error or other unexpected errors
      console.error(
        `Error deleting account for user ${fetchreq.user_uuid}:`,
        error.message
      );
      throw error;
    }
  }
};
