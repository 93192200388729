import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
} from "@mui/material";
import {
  get_all_default_question,
  get_all_feedback,
  update_all_default_question,
  updateForm_id,
} from "../../api/session_service";
import { DialogActions } from "@mui/joy";
import AlertPopup from "../Common_Components/AlertPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Feedback_DialogueBox = (props) => {
  const [loading, setLoading] = useState(true);
  const [formdata, setFormdata] = useState([]);
  const [openForm, setOpenForm] = useState(null); // Track which modal is open
  const [selectedForm, setSelectedForm] = useState(null); // Store the selected form for modal content
  const [formid, setFormid] = useState();
  const [openalert, setOpenAlert] = React.useState(false);
  const [openDefaultDialog, setOpenDefaultDialog] = useState(false); // State to control Default Questions dialog
  const [defaultQuestions, setDefaultQuestions] = useState([]); // Store default questions
  const [selectedQuestions, setSelectedQuestions] = useState([]); // State to store selected questions

  const handleCheckboxChange = (question) => (event) => {
    if (event.target.checked) {
      // Add question to the selectedQuestions array if checked
      setSelectedQuestions((prev) => [...prev, question.question_id]);
    } else {
      // Remove question from the selectedQuestions array if unchecked
      setSelectedQuestions((prev) =>
        prev.filter((q) => q !== question.question_id)
      );
    }
  };

  const handleOnClick = async () => {
    const response = await update_all_default_question({
      question_id: selectedQuestions,
    });
    if (response) {
      handleCloseDefaultDialog();
      setOpenAlert(true);
    }
  };

  console.log("selection", selectedQuestions);
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    const getForm = async () => {
      try {
        console.log("Fetching data for form title:", props.formtitle);
        const response = await get_all_feedback({
          form_title: props.formtitle,
        });
        console.log("API Response:", response); // Log to inspect the API response structure

        if (response) {
          setFormdata(response.status); // Assuming 'status' contains the forms
        } else {
          console.warn("Unexpected response format:", response);
          setFormdata([]);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching form data:", error);
        setLoading(false); // End loading state on error
      }
    };

    getForm();
  }, [props.formtitle]);

  const fetchDefaultQuestions = async () => {
    try {
      const response = await get_all_default_question({
        form_title: props.formtitle,
      });
      console.log("default Response:", response);
      if (response && response.status) {
        const questions = response.status.flatMap(
          (form) => form.questions || []
        );
        if (questions.length > 0) {
          setDefaultQuestions(questions);
        } else {
          console.log("No questions found in the response.");
          setDefaultQuestions([]);
        }
      } else {
        console.warn("No valid response or status.");
        setDefaultQuestions([]);
      }
    } catch (error) {
      console.error("Error fetching default questions:", error);
    }
  };

  const handleOpenDefaultDialog = () => {
    fetchDefaultQuestions(); // Fetch questions before opening the dialog
    setOpenDefaultDialog(true);
    console.log("default", defaultQuestions);
  };

  const handleCloseDefaultDialog = () => setOpenDefaultDialog(false);

  const handleOpen = (form) => {
    setSelectedForm(form);
    setOpenForm(form.form_id); // Open modal for the specific form
  };

  const handleClose = () => {
    setOpenForm(null); // Close the modal
  };

  const handleSet = async (formId) => {
    try {
      const response = await updateForm_id({
        form_id: formId, // Pass the form_id directly to the API
        form_title: props.formtitle,
      });
      setOpenAlert(true);
      console.log("Response from API:", response);
    } catch (error) {
      console.error("Error while setting form ID:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <AlertPopup timer={3000} open={openalert} onClose={handleAlertClose} type="success" message={"Update successfully!!"}/>
      
      <Stack
        sx={{
          marginBottom: "15px",
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Button
          sx={{ width: "175px" }}
          variant="contained"
          color="secondary"
          onClick={handleOpenDefaultDialog}
        >
          Default Questions
        </Button>
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <Typography>Loading...</Typography> // Display loading indicator
        ) : formdata.length > 0 ? (
          formdata.map((form, index) => (
            <React.Fragment key={form.form_id || index}>
              <Card
                sx={{
                  width: 275,
                  height: 300,
                  border: "2px solid #ccc",
                  position: "relative",
                  overflow: "hidden",
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    backdropFilter: "blur(15px)",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    "& .learn-more-button": {
                      opacity: 1,
                    },
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.primary", fontSize: 20 }}
                  >
                    Feedback Form -{index + 1}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <Button
                    size="medium"
                    className="learn-more-button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      opacity: 0,
                      transition: "opacity 0.3s ease-in-out",
                      margin: "5px",
                      width: "100%",
                    }}
                    onClick={() => handleOpen(form)} // Open specific form modal
                  >
                    View
                  </Button>
                  <Button
                    size="medium"
                    className="learn-more-button"
                    variant="contained"
                    color="secondary"
                    sx={{
                      opacity: 0,
                      transition: "opacity 0.3s ease-in-out",
                      margin: "5px",
                      width: "100%",
                    }}
                    onClick={() => handleSet(form.form_id)} // Open specific form modal
                  >
                    Set
                  </Button>
                </CardActions>
                <Stack
                  width={"100%"}
                  sx={{
                    backgroundColor: "#2f3348",
                    color: "white",
                    marginBottom: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ margin: "5px", padding: "5px" }}
                  >
                    Created By - {form.username}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ margin: "5px", padding: "5px" }}
                  >
                    Created On -{" "}
                    {new Date(form.created_on).toLocaleDateString()}{" "}
                    {/* Format the date */}
                  </Typography>
                </Stack>
              </Card>

              {openForm === form.form_id && (
                <Dialog
                  open={openForm === form.form_id}
                  onClose={handleClose}
                  fullWidth
                  maxWidth="md" // Adjust size ('xs', 'sm', 'md', 'lg', 'xl')
                  scroll="paper" // Enables scrolling for long content
                >
                  <DialogTitle>Feedback Form</DialogTitle>
                  <DialogContent dividers>
                    {form.questions?.map((question, qIndex) => (
                      <Stack
                        key={qIndex}
                        sx={{
                          flexDirection: "column",
                          marginBottom: "15px",
                          padding: "10px",
                        }}
                      >
                        <Typography>
                          Ques.{qIndex + 1} <strong>{question.question}</strong>
                        </Typography>
                        {question.type === "text" && (
                          <TextField
                            multiline
                            disabled
                            rows={2}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                        {question.type === "multiple_choice" && (
                          <FormControl component="fieldset" margin="normal">
                            <RadioGroup>
                              {question.value.map((choice, idx) => (
                                <FormControlLabel
                                  key={idx}
                                  value={choice}
                                  disabled
                                  control={<Radio />}
                                  label={choice}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        )}
                      </Stack>
                    ))}
                  </DialogContent>
                  {/* <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Close
                                    </Button>
                                </DialogActions> */}
                </Dialog>
              )}
            </React.Fragment>
          ))
        ) : (
          <Typography>No forms available.</Typography>
        )}
        {openDefaultDialog && (
          <>
            <Dialog
              open={openDefaultDialog}
              onClose={handleCloseDefaultDialog}
              fullWidth
              maxWidth="md"
            >
              <DialogTitle>Default Questions</DialogTitle>
              <DialogContent dividers>
                {defaultQuestions.length > 0 ? (
                  defaultQuestions.map((question, index) => (
                    <div key={index} style={{ marginBottom: "16px" }}>
                      <Box display="flex" alignItems="center">
                        <Checkbox onChange={handleCheckboxChange(question)} />
                        <Typography sx={{ marginLeft: 1 }}>
                          Q{index + 1}: {question.question}
                        </Typography>
                      </Box>
                      {question.type === "text" && (
                        <TextField
                          multiline
                          disabled
                          rows={2}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      )}
                      {question.type === "multiple_choice" && (
                        <FormControl component="fieldset" margin="normal">
                          <RadioGroup>
                            {question.value.map((choice, idx) => (
                              <FormControlLabel
                                key={idx}
                                value={choice}
                                disabled
                                control={<Radio />}
                                label={choice}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      )}
                    </div>
                  ))
                ) : (
                  <Typography>No default questions available.</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleOnClick} color="secondary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Feedback_DialogueBox;
