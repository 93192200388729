import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Avatar,
  Autocomplete,
} from "@mui/material";
import { fetchMentee } from "../../api/sponsor_related_service";
import { postAchievement } from "../../api/achievement_service";
import Cookies from 'js-cookie';
const AchievementModal = ({ isOpen, onClose, onSubmit }) => {
  const [mentees, setMentees] = useState([]);
  const [selectedMentee, setSelectedMentee] = useState(null);
  const user_token = Cookies.get("connectedMe")

  useEffect(() => {
    fetchMenteeData();
  }, []);

  const fetchMenteeData = async () => {
    try {
      const response = await fetchMentee({ user_token });
      setMentees(response || []);
    } catch (error) {
      console.error("Failed to fetch mentees:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const achievementData = {
      user_token: user_token,
      title: formData.get("title"),
      description: formData.get("description"),
      tags: formData.get("tags").split(","),
      for_whom_uuid: selectedMentee ? selectedMentee.id : null,
    };

    try {
      const response = await postAchievement(achievementData);
      console.log("Achievement posted successfully:", response);
      onSubmit(response);
      onClose();
    } catch (error) {
      console.error("Failed to post achievement:", error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "300px", sm: "350px", md: "450px" },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Post Admiration
        </Typography>
        <Autocomplete
          options={mentees}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name}`
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <Avatar src={option.image} alt={option.first_name} />
              {option.first_name} {option.last_name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="For Whom"
              name="forWhom"
              margin="normal"
              fullWidth
            />
          )}
          value={selectedMentee}
          onChange={(event, newValue) => setSelectedMentee(newValue)}
        />
        <TextField
          label="Title"
          name="title"
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Description"
          name="description"
          fullWidth
          required
          margin="normal"
          multiline
          rows={3}
        />
        <TextField
          label="Tags (comma-separated)"
          name="tags"
          fullWidth
          required
          margin="normal"
        />
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="secondary">
              Post
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AchievementModal;
