import React from "react";
import { Typography, Stack, Box, Tooltip } from "@mui/material";
import Calendar from "../components/calendar/Scheduler";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function UserCalendar() {
  return (
    <>
      <Stack>
        <Stack sx={{ display: "flex", flexDirection: "row", margin: '20px 0 0 20px' }}>
          <Typography variant="h4" >
            Calendar
          </Typography>
          <Tooltip sx={{ ml: "10px" }} title="On the Calendar page, you can view your booked sessions with mentors and arrange new sessions at your convenience."><InfoOutlinedIcon fontSize="small" /></Tooltip>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ margin: "20px 0 0 20px", alignItems: "center" }}
        >
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "#42a5f5",
              borderRadius: "50%",
            }}
          />
          <Typography> My created session</Typography>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "#b676b1",
              borderRadius: "50%",
            }}
          />
          <Typography>Booked session</Typography>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "#82caaf",
              borderRadius: "50%",
            }}
          />
          <Typography>Personalized One-on-One Session</Typography>
        </Stack>
      </Stack>
      <Calendar />
    </>
  );
}

export default UserCalendar;
