import { React, useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Stack,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import { post_notification } from "../../api/notification_related_service";
import {
  fetchRecommendedMentors,
  Book_mentor,
  fetchBookedMentors,
  unbookMentors,
} from "../../api/recommendation_related_service";
import { sendMentorBookingMail } from "../../api/mail_related_service";
import MentorCard from "../Common_Components/mentorCard";
import EmptyCard from "../Common_Components/emptyCard";
import Cookies from 'js-cookie';

function RecommendedMentor() {
  const user_token = Cookies.get("connectedMe")
  const [value, setValue] = useState(0);
  const [mentor, setMentor] = useState([]);
  const [bookedMentors, setBookedMentors] = useState([]);
  const [mentorUseruuid, setMentorUseruuid] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedMentor, setSelectedMentor] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBookedMentorData();
    fetchRecommendedMentorData();
  }, []);

  const fetchRecommendedMentorData = async () => {
    try {
      const response = await fetchRecommendedMentors({
        token: user_token,
      });

      setMentor(response || []);
      console.log(response);
    } catch (error) {
      console.error("Error fetching recommended mentors:", error);
    }
  };
  const fetchBookedMentorData = async () => {
    try {
      const response = await fetchBookedMentors({ token: user_token });
      setBookedMentors(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error("Error fetching recommended mentors:", error);
      setBookedMentors([]);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCloseConfirm = () => {
    const payload = {
      mentor_uuid: mentorUseruuid,
      start_date: selectedSession.session_start_date,
      end_date: selectedSession.session_end_date,
    };
    sendMentorBookingMail({ ...payload, user_token: user_token });
    Book_mentor({
      mentor_uuid: mentorUseruuid,
      token: user_token,
      session_date: convertToUTC(selectedSession.session_start_date),
    })
      .then(() => {
        setBookedMentors((prevBookedMentors) => [
          ...prevBookedMentors,
          { mentor_user_uuid: mentorUseruuid },
        ]);
      })
      .catch((error) => {
        console.error("Error booking mentor:", error);
      });
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleMentorClick = async (mentorData) => {
    try {
      await post_notification({
        token: user_token,
        mentor_user_uuid: mentorData.mentor_user_uuid,
        read_status: false,
      });
      // Navigate to mentor profile
      navigate(`/d/dashboard/mentorProfile`, { state: { mentorData } });
    } catch (error) {
      console.error("Error posting notification:", error);
    }
  };
  const handleBook = (mentorData) => {
    setSelectedMentor(mentorData);
    setMentorUseruuid(mentorData.mentor_user_uuid);
    setOpen(true);
  };
  const handleUnbook = async (mentor_uuid) => {
    try {
      await unbookMentors({ token: user_token, mentor_uuid: mentor_uuid });
      // Update both the recommended mentors and booked mentors
      fetchRecommendedMentorData();
      // Remove the unbooked mentor from bookedMentors state
      setBookedMentors((prevBookedMentors) =>
        prevBookedMentors.filter(
          (mentor) => mentor.mentor_user_uuid !== mentor_uuid
        )
      );
    } catch (error) {
      console.error("Error unbooking mentor:", error);
    }
  };
  const isMentorBooked = (mentorUuid) => {
    return bookedMentors.some(
      (bookedMentor) => bookedMentor.mentor_user_uuid === mentorUuid
    );
  };
  const handleSessionSelect = (session) => {
    if (selectedSession?.session_start_date === session.session_start_date) {
      setSelectedSession(null);
    } else {
      setSelectedSession(session);
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const convertToLocalDate = (utrDateString) => {
    const utcDate = new Date(utrDateString);
    const localDate = utcDate.toLocaleDateString();
    const localTime = utcDate.toLocaleTimeString();
    return `${localDate} ${localTime}`;
  };
  function convertToUTC(localDateTime) {
    // Create a new Date object from the local date-time string
    const localDate = new Date(localDateTime);

    // Check if the date is valid
    if (isNaN(localDate)) {
      throw new Error("Invalid date format");
    }

    // Get the UTC components
    const utcYear = localDate.getUTCFullYear();
    const utcMonth = (localDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const utcDate = localDate.getUTCDate().toString().padStart(2, "0");
    const utcHours = localDate.getUTCHours().toString().padStart(2, "0");
    const utcMinutes = localDate.getUTCMinutes().toString().padStart(2, "0");
    const utcSeconds = localDate.getUTCSeconds().toString().padStart(2, "0");
    const utcMilliseconds = localDate
      .getUTCMilliseconds()
      .toString()
      .padStart(3, "0"); // Fractional seconds

    // Return the UTC date-time in ISO 8601 format with fractional seconds
    return `${utcYear}-${utcMonth}-${utcDate}T${utcHours}:${utcMinutes}:${utcSeconds}.${utcMilliseconds}Z`;
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1400px",
        margin: "0 auto",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "16px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <CircularProgress />
          </Box>
        ) : mentor.length === 0 ? (
          <EmptyCard
            message="No Mentor Available"
            icon={
              <PeopleIcon
                sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
              />
            }
          />
        ) : (
          mentor
            .filter((mentorData) => mentorData.sessions.length > 0)
            .map((mentorData, index) => (
              <MentorCard
                key={index}
                mentorData={mentorData}
                buttonLabel={
                  isMentorBooked(mentorData.mentor_user_uuid)
                    ? "UNBOOK"
                    : "BOOK"
                }
                handleBook={() => {
                  if (isMentorBooked(mentorData.mentor_user_uuid)) {
                    handleUnbook(mentorData.mentor_user_uuid);
                  } else {
                    handleBook(mentorData);
                  }
                }}
              />
            ))
        )}
        <Box>
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <Typography variant="h6">Confirm Your Booking</Typography>
              <Typography sx={{ mt: 2, mb: 2 }}>
                Select the meeting date
              </Typography>
              <Stack
                spacing={1}
                direction="row"
                sx={{ flexWrap: "wrap", overflowX: "auto" }}
              >
                {selectedMentor?.sessions &&
                selectedMentor.sessions.length > 0 ? (
                  selectedMentor.sessions.map((session, sessionIndex) => (
                    <Chip
                      key={sessionIndex}
                      label={convertToLocalDate(session.session_start_date)}
                      clickable
                      onClick={() => handleSessionSelect(session)}
                      sx={{
                        backgroundColor:
                          selectedSession?.session_start_date ===
                          session.session_start_date
                            ? "#2f3348"
                            : "default",
                        color:
                          selectedSession?.session_start_date ===
                          session.session_start_date
                            ? "#ffffff"
                            : "default",
                      }}
                    />
                  ))
                ) : (
                  <Typography>No available sessions</Typography>
                )}
              </Stack>

              <Stack sx={{ marginTop: "20px" }} direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCloseConfirm}
                  disabled={!selectedSession}
                >
                  Confirm
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
}
export default RecommendedMentor;
