import React from 'react';
import { Typography, Stack, Tooltip } from '@mui/material';
import Sponsor from '../components/Sponsor/Sponsor';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function UserSponsor() {
    return (
        <>
            <Stack>
                <Stack sx={{ display: "flex", flexDirection: "row", margin: '20px 0 0 20px' }}>
                    <Typography variant="h4" >
                        Sponsor
                    </Typography>
                    <Tooltip sx={{ ml: "10px" }} title="On the sponsor page, mentors can sponsor mentees to upgrade their skills and explore job opportunities tailored to your enhanced capabilities and career goals."><InfoOutlinedIcon fontSize="small" /></Tooltip>
                </Stack>
                <Typography variant="subtitle2" sx={{ margin: '0 0 20px 20px', color: 'grey' }}>
                    Meet Our Valued Sponsors: Powering Success Through Collaborative Partnerships
                </Typography>
            </Stack>
            <Sponsor />
        </>
    )
}

export default UserSponsor;