import axios from "axios";
import network_conn from "../utils/network_config";

export const insertCustomizeTheme = async (theme) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}theme/customize`,
      theme,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Data inserted successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error inserting course:", error.message);
    throw error;
  }
};

export const fetchtheme = async (theme) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}theme/fetch_theme`,
      theme,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching course publish:", error.message);
    throw error;
  }
};