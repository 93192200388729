import axios from "axios";
import network_conn from "../utils/network_config";
// // Function to fetch all appointments

export const fetchDashSession = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}session_service/fetch_session_dash`,
      fetchreq,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const zus_event1 = await process_events(response.data);
    return zus_event1;
  } catch (error) {
    console.error("Error fetching appointments:", error.message);
    return [];
  }
};
export const BookedMentors = async (fetchreq) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}recommendation_service/fetch_booked_mentors`,
      fetchreq,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    const zus_event1 = await process_events(response);
    return zus_event1;
  } catch (error) {
    console.error("Error fetching booked mentors:", error.message);
    throw error;
  }
};
export const addSession = async (newSession) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}session_service/insert_user_sub_seesion`,
      newSession,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding appointment:", error.message);
    return null;
  }
};

export const addNotification = async (notification) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/notification_ack`,
      notification,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding notification:", error.message);
    return null;
  }
};

export const fetchUserMentSession = async (updatedAppointment) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}session_service/fetch_user_sub_session`,
      updatedAppointment,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const zus_event = await process_events(response.data);
    return zus_event;
  } catch (error) {
    console.error("Error fetching session:", error.message);
    return null;
  }
};

export const deleteUserMentSession = async (deleteSession) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}session_service/delete_user_sub_session`,
      deleteSession,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting session:", error.message);
    return false;
  }
};

export const postFeedback = async (feedback) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}feedback/insert_feedback`,
      feedback,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching feedback:", error.message);
    return null;
  }
};

export const checkFeedback = async (feedback) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}feedback/check_feedback`,
      feedback,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error checking feedback status:", error.message);
    return null;
  }
};

export const fetchUrl = async (url) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}feedback/batch_processing`,
      url,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching URL:", error.message);
    return null;
  }
};

export const getreschedulingCount = async (count) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/reschedule_count`,
      count,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count:", error.message);
    return null;
  }
};

export const fetchSession = async (url) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/fetch_all_events`,
      url,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching session:", error.message);
    return null;
  }
};

const process_events = async (api_events) => {
  console.log("api_events", api_events);
  let zus_event = [];
  for (let i = 0; i < api_events.data.length; i++) {
    const utcDate = new Date(
      api_events.data[i].meeting_info.session_start_date
    );
    const offsetMinutes = utcDate.getTimezoneOffset();
    const localTimestamp = new Date(utcDate.getTime() - offsetMinutes * 60000);
    const localDateString = localTimestamp.toISOString();

    const utcDate1 = new Date(api_events.data[i].meeting_info.session_end_date);
    const offsetMinutese = utcDate1.getTimezoneOffset();
    const localTimestamp1 = new Date(
      utcDate1.getTime() - offsetMinutese * 60000
    );
    const localDateString1 = localTimestamp1.toISOString();

    let localDateString3, localDateString4;

    if (
      api_events.data[i].updated_session_start_date &&
      api_events.data[i].updated_session_end_date
    ) {
      const utcDate3 = new Date(api_events.data[i].updated_session_start_date);
      const offsetMinutes3 = utcDate3.getTimezoneOffset();
      const localTimestamp3 = new Date(
        utcDate3.getTime() - offsetMinutes3 * 60000
      );
      localDateString3 = localTimestamp3.toISOString();

      const utcDate4 = new Date(api_events.data[i].updated_session_end_date);
      const offsetMinutese4 = utcDate4.getTimezoneOffset();
      const localTimestamp4 = new Date(
        utcDate4.getTime() - offsetMinutese4 * 60000
      );
      localDateString4 = localTimestamp4.toISOString();
    }

    zus_event.push({
      meeting_id: api_events.data[i].meeting_data.meeting_id,
      title: api_events.data[i].meeting_info.session_title,
      startDate: localDateString,
      endDate: localDateString1,
      location_name: api_events.data[i].Location_data.location_name,
      Desc: api_events.data[i].meeting_info.session_desc,
      Session_author: api_events.data[i].mentor_info.full_name,
      Session_auth_job_title: api_events.data[i].mentor_info.current_pos,
      has_notification: api_events.data[i].has_notification,
      updatedStartDate: localDateString3,
      updatedEndDate: localDateString4,
      image: api_events.data[i].image,
    });
  }
  return zus_event;
};

// export const postFeedback_Form = async (url) => {
//     try {
//         const response = await fetch(network_conn.api_endpoint_v1 + 'feedback/form_id_info', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(url),
//         });
//         if (!response.ok) {
//             throw new Error('Failed to fetch url');
//         }
//         const fetch_url = await response.json();
//         // const zus_event = await process_events(fetch_url);
//         return fetch_url;
//     } catch (error) {
//         console.error('Error fetching ticket:', error.message);
//         return null;
//     }
// };
export const updateForm_id = async (url) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "calender_service/update_form_id_feedback",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(url),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to update form id");
    }
    const fetch_url = await response.json();
    // const zus_event = await process_events(fetch_url);
    return fetch_url;
  } catch (error) {
    console.error("Error fetching ticket:", error.message);
    return null;
  }
};

export const postFeedback_question = async (url) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}feedback/post_form_questions`,
      url,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching ticket:", error.message);
    return null;
  }
};

export const get_all_feedback = async (url) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "feedback/get_all_feedback_form",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(url),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch feedback");
    }
    const fetch_url = await response.json();
    // const zus_event = await process_events(fetch_url);
    return fetch_url;
  } catch (error) {
    console.error("Error fetching ticket:", error.message);
    return null;
  }
};

export const get_all_default_question = async (url) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "feedback/get_all_default_question",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(url),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch feedback");
    }
    const fetch_url = await response.json();
    // const zus_event = await process_events(fetch_url);
    return fetch_url;
  } catch (error) {
    console.error("Error fetching ticket:", error.message);
    return null;
  }
};

export const update_all_default_question = async (url) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "feedback/update_default_question",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(url),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch feedback");
    }
    const fetch_url = await response.json();
    // const zus_event = await process_events(fetch_url);
    return fetch_url;
  } catch (error) {
    console.error("Error fetching ticket:", error.message);
    return null;
  }
};

export const getSessionCountMonthly = async (count) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/total_meetings_month`,
      count,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count:", error.message);
    return null;
  }
};

export const getParticipantCountMonthly = async (count) => {
  try {
    const response = await axios.post(
      `${network_conn.api_endpoint_v1}calender_service/total_participants_monthly`,
      count,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count:", error.message);
    return null;
  }
};
