import React from "react";
import { Box, Typography } from "@mui/material";
import CustomCard from "../Common_Components/Card";

const EmptyCard = ({ message, icon }) => {
  return (
    <CustomCard>
      <Box
        sx={{
          textAlign: "center",
          position: "relative",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        {icon}
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "#333", marginBottom: "10px" }}
        >
          {message}
        </Typography>
      </Box>
    </CustomCard>
  );
};

export default EmptyCard;